import { storeDispatcher } from "../store";
import { SET_LOGGED_IN_USER } from "./actionType";

/**
 * Set Loader
 * @param {object} Payload
 *
 */

export const setLoggedInUserAction = (payload) =>
  storeDispatcher.dispatch({
    type: SET_LOGGED_IN_USER,
    payload,
  });
