import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Select, Button, DatePicker, Input } from 'antd';
import axios from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getExcelData } from '../../services/auth';

const { Option } = Select;
const XLSX = require('xlsx');

const DeactivatedLabourReport = () => {
  const { userConfig } = useAuth();
  const orgId = userConfig?.orgId;
  const [users, setUsers] = React.useState({});
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [selectedGate, setSelectedGate] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedWorkerType, setSelectedWorkerType] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [form] = Form.useForm();
  const SPACING = ['', '', '', ''];
  const [masterData, setMasterData] = React.useState([]);
  const [gateNumber, setGateNumber] = React.useState([]);
  const [workerType, setWorkerType] = React.useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [allGateNumbers, setAllGateNumbers] = useState([]);
  const [filteredGateNumbers, setFilteredGateNumbers] = useState([]);
  const [allWorkerTypes, setAllWorkerTypes] = useState([]);
  const [filteredWorkerTypes, setFilteredWorkerTypes] = useState([]);

  const reportDate = new Date().toLocaleDateString();
  const reportTime = new Date().toLocaleTimeString();
  const fromDate = new Date().toLocaleDateString();
  const toDate = new Date().toLocaleDateString();

  const onFinish = async (values) => {
    console.log('onfinish', values);
    const payload = {
      id: values.id,
      fileType: values.fileType,
      gateNumber: values.gateNumber,
      state: values.state,
      designation: values.designation,
    };

    axios
      .post('/pass/deactivatedLabourReport', payload, { responseType: 'blob' })
      .then((response) => {
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });

        if (values.fileType === 'PDF') {
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'Deactivated Labour Report.pdf');
          // Append the anchor to the body and trigger the click event
          document.body.appendChild(link);
          link.click();
          // Clean up
          document.body.removeChild(link);
        } else if (values.fileType === 'Excel') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            // Modify this part based on your Excel data structure
            const sheetName = workbook.SheetNames[0];
            const excelData = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName],
              { header: 1 }
            );

            // Modify the Excel data to include additional details like Company Name, Division Name, Print Date, etc.
            const modifiedExcelData = [
              ['', '', 'Deactivated Labour Report'],
              [],
              [
                'Company Name:',
                userConfig.orgId,
                ...SPACING,
                'From Date:' + selectedFromDate,
                'Report Date:' + reportDate,
              ],
              [
                'Location Name:',
                values.divisionName,
                ...SPACING,
                'To Date:' + selectedToDate,
                'Report Time:' + reportTime,
              ],
              [values.id, ...SPACING, 'Gate:' + values.gateNumber],
              [],
              ...excelData,
            ];

            // Convert modified data back to Excel
            const ws = XLSX.utils.aoa_to_sheet(modifiedExcelData);
            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              newWorkbook,
              ws,
              'Deactivated Labour Report'
            );
            const excelBuffer = XLSX.write(newWorkbook, {
              type: 'buffer',
              bookType: 'xlsx',
            });

            // Trigger file download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
              new Blob([excelBuffer], { type: 'application/octet-stream' })
            );
            link.setAttribute('download', 'Deactivated Labour Report.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
          reader.readAsArrayBuffer(blob);
        }
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  useEffect(() => {
    getExcel();
  }, []);

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res.data.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Excel data:', error);
      });
  };

  useEffect(() => {
    EmployeeList();
    gateAreaInput();
    workerTypeInput();
  }, [masterData, selectedContractor]);

  // Updated gateAreaInput function...
  function gateAreaInput() {
    const sheetName = 'Gate_Number';
    const dataFromArray = masterData.find(
      (item) => item?.sheetName === sheetName
    );

    if (dataFromArray && dataFromArray.dataParsed) {
      const gateInputAreas = dataFromArray.dataParsed.map(
        (item) => item.Gate_Number
      );
      setAllGateNumbers(gateInputAreas);
      setFilteredGateNumbers(gateInputAreas); // Initialize filtered gate numbers with all gate numbers
    } else {
      console.error(
        `Object with sheetName '${sheetName}' not found or dataParsed is undefined in masterData.`
      );
    }
  }

  function workerTypeInput() {
    const sheetName = 'Worker_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );

    if (dataFromArray && dataFromArray.dataParsed) {
      const workerTypeInputAreas = dataFromArray.dataParsed.map(
        (item) => item.WORKER_TYPE
      );
      setAllWorkerTypes(workerTypeInputAreas);
      setFilteredWorkerTypes(workerTypeInputAreas); // Initialize filtered worker types with all worker types
    } else {
      console.error(
        `Object with sheetName '${sheetName}' not found or dataParsed is undefined in masterData.`
      );
    }
  }

  async function EmployeeList() {
    console.log('checking data', EmployeeList);
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    console.log('Response Data:', Response1);
    setUsers({ Response1 });
  }

  // Updated handleContractorChange function...
  const handleContractorChange = (value) => {
    setSelectedContractor(value);
    if (value === 'All') {
      // If "All" is selected, reset filtered options to all options
      setFilteredGateNumbers(allGateNumbers);
      setFilteredWorkerTypes(allWorkerTypes);
      console.log('Downloading for all contractors');
    } else {
      // If a specific contractor is selected, filter options based on that contractor
      const contractorData = users.Response1.find(
        (contractor) => contractor.id === value
      );
      if (contractorData) {
        setFilteredGateNumbers(contractorData.gateNumbers);
        setFilteredWorkerTypes(contractorData.workerTypes);
      }
      console.log('Downloading for contractor:', value);
    }
  };

  const handleGateChange = (value) => {
    setSelectedGate(value);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  const handleWorkerTypeChange = (value) => {
    setSelectedWorkerType(value);
  };

  const onValuesChange = () => {
    form
      .validateFields()
      .then(() => {
        setFormCompleted(true);
      })
      .catch(() => {
        setFormCompleted(false);
      });
  };

  return (
    <div>
      <h1>Deactivated Labour Report</h1>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label='Company Name'
              name='companyName'
              initialValue={userConfig.orgId}
              required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Contractor'
              name='id'
              rules={[
                { required: true, message: 'Please select a contractor' },
              ]}>
              <Select
                placeholder='Select Contractor'
                onChange={(value) => handleContractorChange(value)}>
                <Option value='All'>All</Option>
                {users.Response1?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='divisionName'
              initialValue={userConfig.locationId}
              label='Location Name'
              labelAlign='top'
              rules={[
                { required: true, message: 'Please select a division name' },
              ]}>
              {/* <Select>
                {users.Response1?.map((option) => (
                  <Option key={option.id} value={option.location}>
                    {option.location}
                  </Option>
                ))}
              </Select> */}
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align='middle'>
          <Col span={8}>
            <Form.Item
              label='From Date'
              name='fromDate'
              rules={[{ required: true, message: 'Please select a date' }]}>
              <DatePicker
                onChange={(date) =>
                  setSelectedFromDate(
                    date ? new Date(date).toLocaleDateString() : ''
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='To Date'
              name='toDate'
              rules={[{ required: true, message: 'Please select a date' }]}>
              <DatePicker
                onChange={(date) =>
                  setSelectedToDate(
                    date ? new Date(date).toLocaleDateString() : ''
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='Gate'
              name='gateNumber'
              rules={[{ required: true, message: 'Please select a gate' }]}>
              <Select
                placeholder='Select Gate'
                onChange={(value) => handleGateChange(value)}>
                <Option value='All'>All</Option>
                {filteredGateNumbers.map((gate) => (
                  <Option key={gate} value={gate}>
                    {gate}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align='middle'>
          <Col span={8}>
            <Form.Item
              label='Worker Type'
              name='workerType'
              rules={[
                { required: true, message: 'Please select a worker type' },
              ]}>
              <Select
                placeholder='Select Worker Type'
                onChange={(value) => handleWorkerTypeChange(value)}>
                {filteredWorkerTypes &&
                  filteredWorkerTypes.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='State'
              name='state'
              rules={[{ required: true, message: 'Please select a state' }]}>
              <Select
                placeholder='Select State'
                onChange={(value) => handleStateChange(value)}>
                <Option value='All'>All</Option>
                {users.Response1?.map((option) => (
                  <Option key={option.state} value={option.state}>
                    {option.state}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='fileType'
              label='File Type'
              labelAlign='top'
              rules={[
                { required: true, message: 'Please select a file type' },
              ]}>
              <Select>
                <Option value='PDF'>PDF</Option>
                <Option value='Excel'>Excel</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align='middle' justify='center'>
          <Col span={6}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginTop: 7 }}
                enable={!formCompleted}>
                Download Report
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DeactivatedLabourReport;
