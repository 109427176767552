
export const inputType = [
  {
    key: '1',
    name: 'TextArea',
    value: 'textArea',
  },
  {
    key: '2',
    name: 'Input',
    value: 'input',
  },
  {
    key: '3',
    name: 'Dropdown',
    value: 'dropdown',
  },
  {
    key: '4',
    name: 'Date',
    value: 'date',
  },
];
 
export const Area = [
  {
    key: '1',
    name: 'Personal Details',
    value: 'personalDetails',
  },
  {
    key: '2',
    name: 'Professional Details',
    value: 'professionalDetails',
  },
  {
    key: '3',
    name: 'Job Details',
    value: 'jobDetails',
  },
  {
    key: '4',
    name: 'Wage Details',
    value: 'wageDetails',
  },
];
 
export const validator = [
  {
    key: '1',
    name: 'Only Alphabetic',
    value: '/^[A-Za-z]+[A-Za-zs]*$/',
    message: 'Only Alphabetic Allowed',
  },
  {
    key: '2',
    name: 'Mobile Number Validation',
    value: '/^[0-9]{10}$/',
    message: 'Enter Valid Mobile No.',
  },
  {
    key: '3',
    name: 'Only Numeric',
    value: '^[0-9]*$',
    message: 'Only Numeric Values Allowed',
  },
];
 