import { storeDispatcher } from "../store";
import { LOADER } from "./actionType";

/**
 * Set Loader
 * @param {object} Payload
 *
 */

export const setLoader = (payload) =>
  storeDispatcher.dispatch({
    type: LOADER,
    payload,
  });
