import React from 'react';
import {
  Button,
  Collapse,
  Form,
  notification,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import {
  WarningOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import OrganisationLocationPanel from './OrganisationLocationPanel';
import { useNavigate } from 'react-router-dom';
import { Email } from '../../services/auth';

const { Panel } = Collapse;

export default function OrganisationLocationForm({
  initialValues,
  onPrev,
  onNext,
  orgInfoData,
  orgAdminData,
}) {
  const formRef = React.useRef();
  const navigate = useNavigate();
  // const [data, setData] = React.useState(orgAdminData);
  const [data1, setData1] = React.useState(orgInfoData);
  const [button, setbutton] = React.useState(false);

  const [activeKeys, setActiveKeys] = React.useState([]);

  const onFinish = (values) => {
    onNext(values);
    // navigate("/home/sa");
    // Email({
    //   userid:orgAdminData[0].userid,
    //   role:orgAdminData[0].role,
    //   workmenName:orgAdminData[0].firstName+" "+orgAdminData[0].lastName,
    //   templateId:"CREATE_ADMIN",
    //   // text: "Hi, Welcome to the OLO application. Please find below the following credentials for your login:Link:http://localhost:3000/api/user/login UserName:{{userid}} OrganizationId:{{orgId}} Password:{{password}} Kindly note that the password is system generated and can be used for the first-time login only. You should change your password immediately upon gaining access to the application by clicking on ‘Change password’.",
    //   password:orgAdminData[0].password,
    //   orgId:data1.id,

    // })
    //   .then((res) => {
    //     // notification.success({ message: res.data.message });
    //   })
    //   .catch((err) => {
    //     notification.error({ message: err.response.data.message });
    //   });
  };

  const getErrorIndex = (errors, name) => {
    let errorIndexes = new Set();

    if (errors) {
      errors
        .filter((e) => e.errors.length > 0 && e.name.includes(name))
        .forEach((e) => errorIndexes.add(e.name[1]));
    }

    return [...errorIndexes];
  };

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 10px', display: 'flex' }}>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Form.List
          name='locations'
          rules={[
            {
              validator: async (_, value) => {
                if (value.length === 0) {
                  notification.error({ message: 'Add atleast one location' });
                  throw Error();
                }
              },
            },
          ]}>
          {(fields, actions) => {
            const errorIndexes = getErrorIndex(
              formRef.current?.getFieldsError(),
              'locations'
            );

            return (
              <Space direction='vertical' style={{ display: 'flex' }}>
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => {
                      setbutton(true);
                      setActiveKeys((a) => [...a, fields.length]);
                      actions.add({
                        name: '',
                        aadharCheck: orgInfoData.aadharCheck,
                        secureCheck: orgInfoData.secureCheck,
                        users: [],
                        documents: [],
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                    disabled={button}>
                    Add India Head Office Location
                  </Button>
                </Form.Item>
                {fields.length > 0 && (
                  <Collapse
                    activeKey={activeKeys}
                    onChange={setActiveKeys}
                    expandIconPosition='end'>
                    {fields.map((field, index) => (
                      <Panel
                        header={
                          <Form.Item shouldUpdate noStyle>
                            {() => {
                              return (
                                <Typography.Text strong>
                                  Location #{index + 1}{' '}
                                  {formRef.current?.getFieldValue([
                                    'locations',
                                    field.name,
                                    'name',
                                  ])}
                                </Typography.Text>
                              );
                            }}
                          </Form.Item>
                        }
                        key={index}
                        forceRender
                        extra={
                          <Space>
                            <Popconfirm
                              title='Delete'
                              onConfirm={(e) => {
                                e.stopPropagation();
                                actions.remove(index);
                              }}
                              onCancel={(e) => e.stopPropagation()}>
                              <Button
                                shape='circle'
                                onClick={(e) => e.stopPropagation()}
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                            {errorIndexes.includes(index) && (
                              <WarningOutlined style={{ color: 'red' }} />
                            )}
                          </Space>
                        }>
                        <OrganisationLocationPanel
                          field={field}
                          index={index}
                          formRef={formRef}
                          orgInfoData={orgInfoData}
                        />
                      </Panel>
                    ))}
                  </Collapse>
                )}
              </Space>
            );
          }}
        </Form.List>

        <Space
          style={{ width: '100%', justifyContent: 'end', marginTop: '24px' }}>
          <Button onClick={() => onPrev(formRef.current?.getFieldsValue())}>
            Prev
          </Button>
          <Form.Item noStyle>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
}
