import React from 'react';
import {
  Button,
  Collapse,
  Form,
  notification,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import {
  WarningOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import OrganisationLocationPanel from './OrganisationLocationPanel';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

export default function OrganisationLocationForm({
  initialValues = [],
  onPrev,
  onNext,
  orgInfoData,
}) {
  const formRef = React.useRef();
  const [activeKeys, setActiveKeys] = React.useState([]);
  const navigate = useNavigate();

  const onFinish = (values) => {
    navigate('/home/sa');

    onNext(values);
  };

  const getErrorIndex = (errors, name) => {
    let errorIndexes = new Set();

    if (errors) {
      errors
        .filter((e) => e.errors.length > 0 && e.name.includes(name))
        .forEach((e) => errorIndexes.add(e.name[1]));
    }

    return [...errorIndexes];
  };

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 10px', display: 'flex' }}>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Form.List
          name='locations'
          rules={[
            {
              validator: async (_, value) => {
                if (value.length === 0) {
                  notification.error({ message: 'Add atleast one location' });
                  throw Error();
                }
              },
            },
          ]}>
          {(fields, actions) => {
            const errorIndexes = getErrorIndex(
              formRef.current?.getFieldsError(),
              'locations'
            );

            return (
              <Space direction='vertical' style={{ display: 'flex' }}>
                <Form.Item>
                  <Button
                    disabled={true}
                    type='dashed'
                    onClick={() => {
                      setActiveKeys((a) => [...a, fields.length]);
                      actions.add({
                        name: '',
                        aadharCheck: orgInfoData.aadharCheck,
                        secureCheck: orgInfoData.secureCheck,
                        users: [],
                        documents: [],
                      });
                    }}
                    block
                    icon={<PlusOutlined />}>
                    Add India Head Office Location
                  </Button>
                </Form.Item>
                {fields.length > 0 && (
                  <Collapse
                    activeKey={activeKeys}
                    onChange={setActiveKeys}
                    expandIconPosition='end'>
                    {fields.map((field, index) => (
                      <Panel
                        header={
                          <Form.Item shouldUpdate noStyle>
                            {() => {
                              return (
                                <Typography.Text strong>
                                  Location #{index + 1}{' '}
                                  {formRef.current?.getFieldValue([
                                    'locations',
                                    field.name,
                                    'name',
                                  ])}
                                </Typography.Text>
                              );
                            }}
                          </Form.Item>
                        }
                        key={index}
                        forceRender
                        extra={
                          <Space>
                            <Popconfirm
                              title='Delete'
                              onConfirm={(e) => {
                                e.stopPropagation();
                                actions.remove(index);
                              }}
                              onCancel={(e) => e.stopPropagation()}
                              // disabled
                            >
                              <Button
                                shape='circle'
                                onClick={(e) => e.stopPropagation()}
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                            {errorIndexes.includes(index) && (
                              <WarningOutlined style={{ color: 'red' }} />
                            )}
                          </Space>
                        }>
                        <OrganisationLocationPanel
                          field={field}
                          index={index}
                          formRef={formRef}
                          orgInfoData={orgInfoData}
                        />
                      </Panel>
                    ))}
                  </Collapse>
                )}
              </Space>
            );
          }}
        </Form.List>

        <Space
          style={{ width: '100%', justifyContent: 'end', marginTop: '24px' }}>
          <Button onClick={() => onPrev(formRef.current?.getFieldsValue())}>
            Prev
          </Button>
          <Form.Item noStyle>
            <Button type='primary' htmlType='submit'>
              Update
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
}
