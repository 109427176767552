import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';

import Login from './routes/Login';
import OrganisationList from './routes/OrganisationList';
import OrganisationOnboard from './routes/OrganisationOnboard';
import ContractorList from './routes/ContractorList';
import EmployeeList from './routes/EmployeeList';
import EmployeeOnboard from './routes/EmployeeOnboard';
import ContractorOnboard from './routes/ContractorOnboard';
import HomeLayout from './layouts/HomeLayout';
import ProtectedRoute from './layouts/ProtectedRoute';
import GlobalContextLayout from './layouts/GlobalContextLayout';
import Approver1 from './routes/Approver1';
import EmployeeEdit from './routes/EmployeeEdit';
import OrganisationEdit from './routes/OrganisationEdit';
import Medical from './components/Approver2/Medical';
import './style.css';
import useAuth from './hooks/useAuth';
import Safety from './components/Approver2/Safety';
import Pfreport from './compliance/Pfreport';
import Esireport from './compliance/Esireport';
import Quickonboarding from './quickonboarding/quickonboarding';
import Togglequickonboarding from './Setting/Togglequickonboarding';
import Webcamera from './routes/Webcamera';
import Dashboard from './compliance/Dashboard';
import PermanentEmployeeList from './routes/PermanentEmployeeList';
import Reportsafety from './components/IncidentReport/Reportsafety';
import Ermanage from './components/IncidentReport/Ermanage';
import Safetymanage from './components/IncidentReport/Safetymanage';
import ChangePasswordPage from './views/AuthPage/changePassword';
import ResetPasswordPage from './views/AuthPage/resetPassword';
import CWFMPAGE from './views/AuthPage/cwfm';
import Permission from './permission/permission';
import Contractor from './routes/Contractor';
import DesciplinaryActions from './historicalReview/DesciplinaryActions';
import EmployeeGrowth from './historicalReview/EmployeeGrowth';
import EmployeeMaster from './historicalReview/EmployeeMaster';
import LongService from './historicalReview/LongService';
import YearlyLeaves from './historicalReview/YearlyLeaves';
import YearlyPoints from './historicalReview/YearlyPoints';
import PermanentEmployeeOnboard from './routes/PermanentEmployeeOnboard';
import PermanentEmployeeEdit from './routes/PermanentEmployeeEdit';
import BulkUpload from './components/PermanentEmployeeOnboard/BulkUpload';
import Audit from './audit/audit';
import LicenceManagement from './LicenceManagement';
import ContractorReport from './components/Reports/ContractorReport';
import Regular from './components/Reports/Regular';
import ContractorSummary from './components/ContractorOnboard/ContractorSummary';
import ContractorApprovalPage from './routes/ContractorApprovalPage';
import SamlResponse from './routes/SamlResponse';
import IrDesignationForm from '../src/components/PermanentEmployeeOnboard/IrDesignationForm';
import OrganizationSupervisorForm from './components/Contractor/OrganizationSupervisorForm';
const RedirectToNav = () => {
  const { user, userConfig, tokenInfo } = useAuth();
  const navigate = useNavigate();

  if (!user.isAuthenticated) {
    navigate('/login');
  }

  return <Navigate to={userConfig.nav} />;
};

const router = createBrowserRouter([
  {
    path: '/saml/response/:token?',
    element: <SamlResponse />,
  },

  {
    element: <GlobalContextLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          // {
          //   path: '',
          //   element: <RedirectToNav />,
          // },
          {
            path: 'home',
            element: <HomeLayout />,
            children: [
              //organization Onboard
              {
                path: 'sa',
                element: <OrganisationList />,
              },
              {
                path: 'sa/onboard',
                element: <OrganisationOnboard />,
              },
              {
                path: 'sa/licence-management',
                element: <LicenceManagement />,
              },
              {
                path: 'sa/org/:id',
                element: <OrganisationEdit />,
              },

              // Supervisor Onboard
              {
                path: 'admin',
                element: <Contractor />,
                // element: {ContractorComponent},
              },
              {
                path: 'change-password',
                element: <ChangePasswordPage />,
              },
              {
                path: 'admin/organisation',
                element: <ContractorOnboard />,
                // element: PrincipalEmployerComponent,
              },

              {
                path: 'admin/compliance/pf-report',
                element: <Pfreport />,
              },
              {
                path: 'admin/compliance/esi-report',
                element: <Esireport />,
              },
              {
                path: 'admin/compliance/dashboard',
                element: <Dashboard />,
              },
              {
                path: 'admin/setting',
                element: <Togglequickonboarding />,
              },
              {
                path: 'admin/audit',
                element: <Audit />,
              },
              {
                path: 'admin/audit',
                element: <ContractorSummary />,
              },
              {
                path: 'admin/permission',
                element: <Permission />,
              },
              //Employee Onboard
              {
                path: 'supervisor/employee',
                element: <EmployeeList />,
              },
              // {
              //   path: "/supervisor/employee/qeo",
              //   element: <Quickonboarding />,
              // },
              {
                path: 'supervisor',
                element: <EmployeeOnboard />,
              },
              // {
              //   path: 'supervisor',
              //   element: <PermanentEmployeeOnboard />,
              // },
              {
                path: 'supervisor/qeo',
                element: <Quickonboarding />,
              },
              {
                path: 'supervisor/bulk',
                element: <Webcamera />,
              },
              // {
              //   path: 'supervisor/bulk',
              //   element: <BulkUpload />,
              // },
              {
                path: 'supervisor/employee/:id',
                element: <EmployeeEdit />,
              },
              // {
              //   path: 'supervisor/employee/:id',
              //   element: <PermanentEmployeeEdit />,
              // },
              {
                path: 'supervisor/incidentreport',
                element: <Reportsafety />,
              },
              //Approver
              {
                path: 'approver/list',
                element: <Approver1 />,
              },
              {
                path: 'approver/employee/:id',
                element: <EmployeeEdit />,
              },
              {
                path: 'approver/employee/:id/medical',
                element: <Medical />,
              },
              {
                path: 'approver/employee/:id/safety',
                element: <Safety />,
              },
              //INCIDENT REPORT
              {
                path: 'approver/incidentreport',
                element: <Reportsafety />,
              },
              {
                path: 'approver/incidentreport/:id',
                element: <Ermanage />,
              },
              {
                path: 'supervisor/incidentreport/:id',
                element: <Ermanage />,
              },
              {
                path: 'approver/incidentreport/:id/safety',
                element: <Safetymanage />,
              },
              // {
              //   path: 'approver/contractorApproval',
              //   element: <Contractor />,
              // },
              {
                path: 'approver/contractorApproval',
                element: <ContractorApprovalPage />,
              },
              {
                path: 'approver/report/contractor',
                element: <ContractorReport />,
              },

              //Historical Review
              {
                path: 'approver/historical/longService',
                element: <LongService />,
              },
              {
                path: 'approver/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'approver/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'approver/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'approver/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'approver/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              //shop
              {
                path: 'shop/historical/longService',
                element: <LongService />,
              },
              {
                path: 'shop/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'shop/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'shop/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'shop/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'shop/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              //IR
              {
                path: 'ir/employee',
                element: <PermanentEmployeeList />,
              },

              {
                path: 'ir',
                element: <PermanentEmployeeOnboard />,
              },
              // {
              //   path: 'ir/qeo',
              //   element: <Quickonboarding />,
              // },
              {
                path: 'ir/bulk',
                element: <BulkUpload />,
              },
              {
                path: 'ir/dashBoard',
                element: <IrDesignationForm />,
              },

              {
                path: 'ir/employee/:id',
                element: <PermanentEmployeeEdit />,
              },

              {
                path: 'ir/historical/longService',
                element: <LongService />,
              },
              {
                path: 'ir/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'ir/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'ir/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'ir/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'ir/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              {
                path: 'ir/report/regular',
                element: <Regular />,
              },

              //contractor
              {
                path: 'contractor',
                element: <Contractor />,
              },
              {
                path: 'managesupervisor',
                element: <OrganizationSupervisorForm />,
              },
            ],
          },
        ],
      },
      {
        path: '/cwfm',
        element: <CWFMPAGE />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      { path: '/reset-password', element: <ResetPasswordPage /> },
    ],
  },
]);

function App() {
  const { user, userConfig, tokenInfo } = useAuth();

  return (
    <ConfigProvider locale={enUS}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;

//Channna Code

// import React from 'react';
// import {
//   createBrowserRouter,
//   Navigate,
//   RouterProvider,
//   useNavigate,
// } from 'react-router-dom';
// import { ConfigProvider } from 'antd';
// import enUS from 'antd/locale/en_US';

// import Login from './routes/Login';
// import OrganisationList from './routes/OrganisationList';
// import OrganisationOnboard from './routes/OrganisationOnboard';
// import ContractorList from './routes/ContractorList';
// import EmployeeList from './routes/EmployeeList';
// import EmployeeOnboard from './routes/EmployeeOnboard';
// import ContractorOnboard from './routes/ContractorOnboard';
// import HomeLayout from './layouts/HomeLayout';
// import ProtectedRoute from './layouts/ProtectedRoute';
// import GlobalContextLayout from './layouts/GlobalContextLayout';
// import Approver1 from './routes/Approver1';
// import EmployeeEdit from './routes/EmployeeEdit';
// import OrganisationEdit from './routes/OrganisationEdit';
// import Medical from './components/Approver2/Medical';
// import './style.css';
// import useAuth from './hooks/useAuth';
// import Safety from './components/Approver2/Safety';
// import Pfreport from './compliance/Pfreport';
// import Esireport from './compliance/Esireport';
// import Quickonboarding from './quickonboarding/quickonboarding';
// import Togglequickonboarding from './Setting/Togglequickonboarding';
// import Webcamera from './routes/Webcamera';
// import Dashboard from './compliance/Dashboard';
// import PermanentEmployeeList from './routes/PermanentEmployeeList';
// import Reportsafety from './components/IncidentReport/Reportsafety';
// import Ermanage from './components/IncidentReport/Ermanage';
// import Safetymanage from './components/IncidentReport/Safetymanage';
// import ChangePasswordPage from './views/AuthPage/changePassword';
// import ResetPasswordPage from './views/AuthPage/resetPassword';
// import CWFMPAGE from './views/AuthPage/cwfm';
// import Permission from './permission/permission';
// import Contractor from './routes/Contractor';
// import DesciplinaryActions from './historicalReview/DesciplinaryActions';
// import EmployeeGrowth from './historicalReview/EmployeeGrowth';
// import EmployeeMaster from './historicalReview/EmployeeMaster';
// import LongService from './historicalReview/LongService';
// import YearlyLeaves from './historicalReview/YearlyLeaves';
// import YearlyPoints from './historicalReview/YearlyPoints';
// import PermanentEmployeeOnboard from './routes/PermanentEmployeeOnboard';
// import PermanentEmployeeEdit from './routes/PermanentEmployeeEdit';
// import BulkUpload from './components/PermanentEmployeeOnboard/BulkUpload';
// import Audit from './audit/audit';
// import LicenceManagement from './LicenceManagement';
// import ContractorReport from './components/Reports/ContractorReport';
// import Regular from './components/Reports/Regular';
// import ContractorSummary from './components/ContractorOnboard/ContractorSummary';
// import ContractorApprovalPage from './routes/ContractorApprovalPage';
// import SamlResponse from './routes/SamlResponse';
// import Authorization from './utils/authorisation';
// const RedirectToNav = () => {
//   const { user, userConfig, tokenInfo } = useAuth();
//   const navigate = useNavigate();

//   if (!user.isAuthenticated) {
//     navigate('/login');
//   }

//   return <Navigate to={userConfig.nav} />;
// };

// const router = createBrowserRouter([
//   {
//     path: '/saml/response/:token?',
//     element: <SamlResponse />,
//   },

//   {
//     element: <GlobalContextLayout />,
//     children: [
//       {
//         path: '/',
//         element: <ProtectedRoute />,
//         children: [
//           // {
//           //   path: '',
//           //   element: <RedirectToNav />,
//           // },
//           {
//             path: 'home',
//             element: <HomeLayout />,
//             children: [
//               //organization Onboard
//               {
//                 path: 'sa',
//                 element: <OrganisationList />,
//                 element: (
//                   <Authorization requiredRoles={['super_admin']}>
//                     <OrganisationList />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'sa/onboard',
//                 element: <OrganisationOnboard />,
//                 element: (
//                   <Authorization requiredRoles={['super_admin']}>
//                     <OrganisationOnboard />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'sa/licence-management',
//                 //element: <LicenceManagement />,
//                 element: (
//                   <Authorization requiredRoles={['super_admin']}>
//                     <LicenceManagement />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'sa/org/:id',
//                 // element: <OrganisationEdit />,
//                 element: (
//                   <Authorization requiredRoles={['super_admin']}>
//                     <OrganisationEdit />
//                   </Authorization>
//                 ),
//               },

//               // Supervisor Onboard
//               // {
//               //   path: 'admin',
//               //   element: <Contractor />,
//               //   // element: {ContractorComponent},
//               // },
//               {
//                 path: 'admin',
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Contractor />
//                   </Authorization>
//                 ),
//                 // element: {ContractorComponent},
//               },
//               // {
//               //   path: 'change-password',

//               //   element: (
//               //     <Authorization requiredRoles={['admin']}>
//               //       <ChangePasswordPage />
//               //     </Authorization>
//               //   ),
//               // },
//               {
//                 path: 'change-password',
//                 // element: <ChangePasswordPage/>,
//                 element: (
//                   <Authorization
//                     requiredRoles={[
//                       'admin',
//                       'contractor',
//                       'supervisor',
//                       'approver',
//                       'shop',
//                       'ir',
//                     ]}>
//                     <ChangePasswordPage />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/organisation',
//                 //   element: <ContractorOnboard />,
//                 // element: PrincipalEmployerComponent,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <ContractorOnboard />
//                   </Authorization>
//                 ),
//               },

//               {
//                 path: 'admin/compliance/pf-report',
//                 // element: <Pfreport />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Pfreport />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/compliance/esi-report',
//                 // element: <Esireport />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Esireport />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/compliance/dashboard',
//                 //  element: <Dashboard />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Dashboard />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/setting',
//                 //  element: <Togglequickonboarding />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Togglequickonboarding />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/audit',
//                 //  element: <Audit />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Audit />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/audit',
//                 //  element: <ContractorSummary />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <ContractorSummary />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'admin/permission',
//                 //  element: <Permission />,
//                 element: (
//                   <Authorization requiredRoles={['admin']}>
//                     <Permission />
//                   </Authorization>
//                 ),
//               },
//               //Employee Onboard
//               {
//                 path: 'supervisor/employee',
//                 //  element: <EmployeeList />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <EmployeeList />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: "/supervisor/employee/qeo",
//               //   element: <Quickonboarding />,
//               // },
//               {
//                 path: 'supervisor',
//                 //  element: <EmployeeOnboard />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <EmployeeOnboard />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: 'supervisor',
//               //   element: <PermanentEmployeeOnboard />,
//               // },
//               {
//                 path: 'supervisor/qeo',
//                 //  element: <Quickonboarding />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <Quickonboarding />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'supervisor/bulk',
//                 //  element: <Webcamera />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <Webcamera />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: 'supervisor/bulk',
//               //   element: <BulkUpload />,
//               // },
//               {
//                 path: 'supervisor/employee/:id',
//                 // element: <EmployeeEdit />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <EmployeeEdit />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: 'supervisor/employee/:id',
//               //   element: <PermanentEmployeeEdit />,
//               // },
//               {
//                 path: 'supervisor/incidentreport',
//                 //  element: <Reportsafety />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <Reportsafety />
//                   </Authorization>
//                 ),
//               },
//               //Approver
//               {
//                 path: 'approver/list',
//                 //  element: <Approver1 />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Approver1 />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/employee/:id',
//                 //  element: <EmployeeEdit />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <EmployeeEdit />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/employee/:id/medical',
//                 //  element: <Medical />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Medical />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/employee/:id/safety',
//                 //  element: <Safety />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Safety />
//                   </Authorization>
//                 ),
//               },
//               //INCIDENT REPORT
//               {
//                 path: 'approver/incidentreport',
//                 //  element: <Reportsafety />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Reportsafety />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/incidentreport/:id',
//                 //  element: <Ermanage />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Ermanage />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'supervisor/incidentreport/:id',
//                 //  element: <Ermanage />,
//                 element: (
//                   <Authorization requiredRoles={['supervisor']}>
//                     <Ermanage />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/incidentreport/:id/safety',
//                 //  element: <Safetymanage />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <Safetymanage />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: 'approver/contractorApproval',
//               //   element: <Contractor />,
//               // },
//               {
//                 path: 'approver/contractorApproval',
//                 //  element: <ContractorApprovalPage />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <ContractorApprovalPage />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/report/contractor',
//                 //  element: <ContractorReport />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <ContractorReport />
//                   </Authorization>
//                 ),
//               },

//               //Historical Review
//               {
//                 path: 'approver/historical/longService',
//                 //  element: <LongService />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <LongService />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/historical/employeeMaster',
//                 //  element: <EmployeeMaster />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <EmployeeMaster />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/historical/yearlyPoints',
//                 // element: <YearlyPoints />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <YearlyPoints />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/historical/desciplinaryActions',
//                 //  element: <DesciplinaryActions />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <DesciplinaryActions />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/historical/yearlyLeaves',
//                 //  element: <YearlyLeaves />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <YearlyLeaves />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'approver/historical/employeeGrowth',
//                 //  element: <EmployeeGrowth />,
//                 element: (
//                   <Authorization requiredRoles={['approver']}>
//                     <EmployeeGrowth />
//                   </Authorization>
//                 ),
//               },
//               //shop
//               {
//                 path: 'shop/historical/longService',
//                 // element: <LongService />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <LongService />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'shop/historical/employeeMaster',
//                 // element: <EmployeeMaster />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <EmployeeMaster />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'shop/historical/yearlyPoints',
//                 // element: <YearlyPoints />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <YearlyPoints />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'shop/historical/desciplinaryActions',
//                 // element: <DesciplinaryActions />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <DesciplinaryActions />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'shop/historical/yearlyLeaves',
//                 // element: <YearlyLeaves />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <YearlyLeaves />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'shop/historical/employeeGrowth',
//                 // element: <EmployeeGrowth />,
//                 element: (
//                   <Authorization requiredRoles={['shop']}>
//                     <EmployeeGrowth />
//                   </Authorization>
//                 ),
//               },
//               //IR
//               {
//                 path: 'ir/employee',
//                 // element: <PermanentEmployeeList />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <PermanentEmployeeList />
//                   </Authorization>
//                 ),
//               },

//               {
//                 path: 'ir',
//                 // element: <PermanentEmployeeOnboard />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <PermanentEmployeeOnboard />
//                   </Authorization>
//                 ),
//               },
//               // {
//               //   path: 'ir/qeo',
//               //   element: <Quickonboarding />,
//               // },
//               {
//                 path: 'ir/bulk',
//                 // element: <BulkUpload />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <BulkUpload />
//                   </Authorization>
//                 ),
//               },

//               {
//                 path: 'ir/employee/:id',
//                 // element: <PermanentEmployeeEdit />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <PermanentEmployeeEdit />
//                   </Authorization>
//                 ),
//               },

//               {
//                 path: 'ir/historical/longService',
//                 //element: <LongService />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <LongService />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/historical/employeeMaster',
//                 // element: <EmployeeMaster />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <EmployeeMaster />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/historical/yearlyPoints',
//                 //  element: <YearlyPoints />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <YearlyPoints />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/historical/desciplinaryActions',
//                 // element: <DesciplinaryActions />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <DesciplinaryActions />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/historical/yearlyLeaves',
//                 // element: <YearlyLeaves />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <YearlyLeaves />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/historical/employeeGrowth',
//                 // element: <EmployeeGrowth />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <EmployeeGrowth />
//                   </Authorization>
//                 ),
//               },
//               {
//                 path: 'ir/report/regular',
//                 // element: <Regular />,
//                 element: (
//                   <Authorization requiredRoles={['ir']}>
//                     <Regular />
//                   </Authorization>
//                 ),
//               },

//               //contractor
//               {
//                 path: 'contractor',
//                 //element: <Contractor />,
//                 element: (
//                   <Authorization requiredRoles={['admin', 'contractor']}>
//                     <Contractor />
//                   </Authorization>
//                 ),
//               },
//             ],
//           },
//         ],
//       },
//       {
//         path: '/cwfm',
//         element: <CWFMPAGE />,
//       },
//       {
//         path: '/login',
//         element: <Login />,
//       },
//       { path: '/reset-password', element: <ResetPasswordPage /> },
//     ],
//   },
// ]);

// function App() {
//   const { user, userConfig, tokenInfo } = useAuth();

//   return (
//     <ConfigProvider locale={enUS}>
//       <RouterProvider router={router} />
//     </ConfigProvider>
//   );
// }

// export default App;
