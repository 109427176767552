import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import {
  Button,
  Divider,
  Select,
  Table,
  Row,
  Col,
  Form,
  Collapse,
  Space,
  Typography,
  Upload,
  notification,
  DatePicker,
  List,
} from 'antd';
import { Modal, Steps } from 'antd';
import dayjs from 'dayjs';
import { getContractorOnboard, getCloudInfo } from '../services/auth';

import { EyeFilled, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { esi } from '../services/auth';
import axios from '../services/axiosInstance';
import useAuth from '../hooks/useAuth';
import { encrypt } from '../utils/crypto';

const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;
const { Step } = Steps;

function Esireport() {
  const [modalVisible, setModalVisible] = useState(false);
  const [Employee, setEmployee] = useState([]);
  const [Employee1, setEmployee1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = React.useState({});
  const { userConfig } = useAuth();
  const [form] = Form.useForm();
  const [Request, setRequest] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [selectedDropdown1, setSelectedDropdown1] = useState('PDF');
  const [selectedDropdown2, setSelectedDropdown2] = useState(null);
  const [exceptionRecords, setExceptionRecords] = useState(false);
  const [excepEmployeeRecords, setExcepEmployeeRecords] = useState([]);
  const [nomatchRecords, setNoMatchRecords] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [fileExplorerVisible, setFileExplorerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const roleName = userConfig.roleName;

  const onKronosFileUpload = (file) => {
    form.setFieldsValue({ kronosFile: file });
  };

  const onPortalFileUpload = (file) => {
    form.setFieldsValue({ portalFile: file });
  };
  const { Item } = Form;
  React.useEffect(() => {
    if (selectedDropdown2 !== null) {
      EmployeeData();
    }
  }, [selectedDropdown2]);

  const onFinish = (value) => {
    const { kronosFile, portalFile, supplierId } = value;
    const [supplierIdValue, supplierName] = supplierId.split(',');

    console.log(
      'value payment date',
      dayjs(value.paymentDate).format('YYYY-MM-DD')
    );

    const formData = new FormData();
    formData.append('esiKronos', kronosFile.file);
    formData.append('esiPortal', portalFile.file);
    formData.append('orgId', userConfig.orgId);
    formData.append('supplierId', supplierIdValue);
    formData.append('supplierName', supplierName);
    formData.append(
      'paymentDate',
      dayjs(value.paymentDate).format('YYYY-MM-DD')
    );

    esi(formData)
      .then((res) => {
        const transactionId1 = res.data.transactionId;
        setRequest({ transactionId1 });
        notification.success({ message: 'ESI Downloaded Succesfully' });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    setModalVisible(true);
  };
  useEffect(() => {
    if (Request.transactionId1) {
      axios
        .get(`/report/transactions/${Request.transactionId1}`)
        .then((res) => {
          setReportData(res.data);

          setEmployee(
            res.data.data.report?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              esiumber: row['ESI No'],
              Egwr: row['Emp Gross Pay as per Wage Reg'],
              Egec: row['Emp Gross Pay as per MC Challan'],
              Diffamount: row['Diff amt in Wages'],
              Esiwr: row['ESI per Wage Reg'],
              Esic: row['ESI per MC Challan'],
              Diffamountcontribution: row['Diff Amount in Contribution'],
              dueDate: res?.data?.data?.dueDate,
              isCompliant: res?.data?.data?.isCompliant,
            }))
          );
          const data = res.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((row) => ({
                empName1: row['Emp Name'],
                empCode1: row['Emp Code'],
                esiumber1: row['ESI No'],
                Egwr1: row['Emp Gross Pay as per Wage Reg'],
                Egec1: row['Emp Gross Pay as per MC Challan'],
                Diffamount1: row['Diff amt in Wages'],
                Esiwr1: row['ESI per Wage Reg'],
                Esic1: row['ESI per MC Challan'],
                Diffamountcontribution1: row['Diff Amount in Contribution'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
              }))
          );

          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((row) => ({
                empName2: row['Emp Name'],
                empCode2: row['Emp Code'],
                esiumber2: row['ESI No'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
                // Egwr1: row["Emp Gross Pay as per Wage Reg"],
                // Egec1: row["Emp Gross Pay as per MC Challan"],
                // Diffamount1: row["Diff amt in Wages"],
                // Esiwr1: row["ESI per Wage Reg"],
                // Esic1: row["ESI per MC Challan"],
                // Diffamountcontribution1: row["Diff Amount in Contribution"],
              }))
          );

          setTransactionId(res.data.transactionId);
          setModalVisible(true);
        })
        .catch((err) => {});
    }
  }, [Request.transactionId1]);

  React.useEffect(() => {
    EmployeeList();
    ExceptionReport();
    OrganizationInfo();
    CloudInfo();
  }, []);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }
  async function ExceptionReport() {}
  const PfClick = () => {
    window.open('https://esic.gov.in/', '_blank');
  };
  const handleModalCancel = () => {
    // Hide the modal
    setModalVisible(false);
  };
  function handleDropdown1Change(value) {
    setSelectedDropdown1(value);
  }
  const downloadFile = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'all',
      transactionId: Request.transactionId1 || transactionId,
      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const downloadFile1 = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'exception',
      // transactionId: Request.transactionId1,
      transactionId: Request.transactionId1 || transactionId,

      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleDropdown2Change(value) {
    const [supplierIdValue, supplierName] = value.split(',');

    const supplierId = value.split(' ')[1];
    setSelectedSupplier(supplierIdValue);
    setSelectedDropdown2(supplierIdValue);
    // ExceptionReport();
    // if (value !== users.Response1[0].id) {
    //   EmployeeData();
    // }
  }

  const FileExplorer = ({ files, onFileSelect, onClose }) => {
    const handleFileChange = (file) => {
      setSelectedFile(file);
    };

    const handleUpload = () => {
      onFileSelect(selectedFile);
      onClose();
    };
    return (
      <Modal
        title='Cloud File Explorer'
        visible={true}
        onCancel={onClose}
        footer={[
          <Button key='back' onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!selectedFile}
            onClick={handleUpload}>
            Upload
          </Button>,
        ]}>
        {/* Display files from prop using Ant Design List */}
        <List
          header={<div>Cloud Files</div>}
          bordered
          dataSource={files}
          renderItem={(file) => (
            <List.Item>
              <a href={file.link} target='_blank' rel='noopener noreferrer'>
                {file.name}
              </a>
            </List.Item>
          )}
        />
        {/* <Upload
          type="file"
          beforeUpload={() => false}
          onChange={(info) => handleFileChange(info.file)}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload> */}
      </Modal>
    );
  };

  const EmployeeData = async () => {
    const querydata = {
      orgId: userConfig.orgId,
      supplierId: selectedDropdown2,
      type: 'ESI',
    };
    const decryptPayload = await encrypt(JSON.stringify(querydata));

    const Response = axios
      .get(`report/transactions?payload=${decryptPayload}`)
      .then((response) => {
        setEmployee1(
          response.data.data.map((row) => ({
            transactionId: row.transactionId,
            // timestamp:row.timestamp,
            timestamp: dayjs(row.timestamp).format('DD-MM-YYYY'),

            kronosFileName: row.kronosFileName,
            portalFileName: row.portalFileName,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleView(id) {
    setTransactionId(id);
    setModalVisible(true);
    setVisible(true);
    setLoading(true);
    axios
      .get(`/report/transactions/${id}`)
      .then((response) => {
        setReportData(response.data);

        setEmployee(
          response.data.data.report?.map((row) => ({
            empName: row['Emp Name'],
            empCode: row['Emp Code'],
            esiumber: row['ESI No'],
            Egwr: row['Emp Gross Pay as per Wage Reg'],
            Egec: row['Emp Gross Pay as per MC Challan'],
            Diffamount: row['Diff amt in Wages'],
            Esiwr: row['ESI per Wage Reg'],
            Esic: row['ESI per MC Challan'],
            Diffamountcontribution: row['Diff Amount in Contribution'],
          }))
        );
        const data = response.data;
        setExceptionRecords(data.data.exceptionCount > 0);
        setExcepEmployeeRecords(
          data.data.report
            ?.filter((record) => record.isExceptionRecord)
            .map((row) => ({
              empName1: row['Emp Name'],
              empCode1: row['Emp Code'],
              esiumber1: row['ESI No'],
              Egwr1: row['Emp Gross Pay as per Wage Reg'],
              Egec1: row['Emp Gross Pay as per MC Challan'],
              Diffamount1: row['Diff amt in Wages'],
              Esiwr1: row['ESI per Wage Reg'],
              Esic1: row['ESI per MC Challan'],
              Diffamountcontribution1: row['Diff Amount in Contribution'],
            }))
        );
        setNoMatchRecords(
          data.data.report
            ?.filter((record) => record.isNoMatch)
            .map((row) => ({
              empName2: row['Emp Name'],
              empCode2: row['Emp Code'],
              esiumber2: row['ESI No'],
              // Egwr1: row["Emp Gross Pay as per Wage Reg"],
              // Egec1: row["Emp Gross Pay as per MC Challan"],
              // Diffamount1: row["Diff amt in Wages"],
              // Esiwr1: row["ESI per Wage Reg"],
              // Esic1: row["ESI per MC Challan"],
              // Diffamountcontribution1: row["Diff Amount in Contribution"],
            }))
        );
        // setData(response.data);
        setLoading(false);
        // setLoading(false);
        // EmployeeData();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  const handleDownload = (download) => {
    // download.preventDefault();
    axios
      .post(
        `/report/download/PDF`,
        {
          reportType: 'exception',
          transactionId: download,
          supplierId: selectedSupplier,
        },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      });
  };

  const columns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName',
      key: 'empName',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode',
      key: 'empCode',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber',
      key: 'esiumber',
    },
    {
      title: 'Emp Gross Pay as per Wage Reg',
      dataIndex: 'Egwr',
      key: 'Egwr',
    },
    {
      title: "Emp Gross Pay as per MC Challan'",
      dataIndex: 'Egec',
      key: 'Egec',
    },
    {
      title: 'Diff amt in Wages',
      dataIndex: 'Diffamount',
      key: 'Diffamount',
    },
    {
      title: 'ESI per Wage Reg',
      dataIndex: 'Esiwr',
      key: 'Esiwr',
    },
    {
      title: 'ESI per MC Challan',
      dataIndex: 'Esic',
      key: 'Esic',
    },
    {
      title: 'Diff Amount in Contribution',
      dataIndex: 'Diffamountcontribution',
      key: 'Diffamountcontribution',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  const exceptioncolumns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName1',
      key: 'empName1',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode',
      key: 'empCode1',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber1',
      key: 'esiumber1',
    },
    {
      title: 'Emp Gross Pay as per Wage Reg',
      dataIndex: 'Egwr1',
      key: 'Egwr1',
    },
    {
      title: "Emp Gross Pay as per MC Challan'",
      dataIndex: 'Egec1',
      key: 'Egec1',
    },
    {
      title: 'Diff amt in Wages',
      dataIndex: 'Diffamount1',
      key: 'Diffamount1',
    },
    {
      title: 'ESI per Wage Reg',
      dataIndex: 'Esiwr1',
      key: 'Esiwr1',
    },
    {
      title: 'ESI per MC Challan',
      dataIndex: 'Esic1',
      key: 'Esic1',
    },
    {
      title: 'Diff Amount in Contribution',
      dataIndex: 'Diffamountcontribution1',
      key: 'Diffamountcontribution1',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  const nomatchRecord = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName2',
      key: 'empName2',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode2',
      key: 'empCode2',
    },
    {
      title: 'ESI Number',
      dataIndex: 'esiumber2',
      key: 'esiumber2',
    },
  ];

  const column = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      editable: true,
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'Wage Register',
      dataIndex: 'kronosFileName',
      key: 'kronosFileName',
    },
    {
      title: 'Portal File',
      dataIndex: 'portalFileName',
      key: 'portalFileName',
    },
    {
      title: 'Actions',
      dataIndex: 'transactionId',
      render: (_, record) => (
        <Space>
          <Typography onClick={() => handleDownload(record.transactionId)}>
            <DownloadOutlined />
          </Typography>
          <Typography onClick={() => handleView(record.transactionId)}>
            <EyeFilled />
          </Typography>
        </Space>
      ),
    },
  ];
  const [principalEmployername, setprincipalEmployername] = useState(null);

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const name = res.data.org.name;
      setprincipalEmployername(res.data.org.name);
    });
  };

  const CloudInfo = async () => {
    getCloudInfo().then((res) => {
      const data = res.data;
      setFiles(data);
    });
  };

  const openGoogleCloudStorageBucket = async () => {
    // Wait for the file upload to finish asynchronously
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Replace 'your_bucket_name' with the actual name of your Google Cloud Storage bucket
    const bucketName = process.env.BUCKET_NAME;

    // Open the bucket in the Google Cloud Storage Browser
    window.open(
      `https://console.cloud.google.com/storage/browser/${bucketName}`,
      '_blank'
    );
    // window.location.href = `https://console.cloud.google.com/storage/browser/${bucketName}`;
  };

  const openFileExplorer = () => {
    setFileExplorerVisible(true);
  };

  const closeFileExplorer = () => {
    setFileExplorerVisible(false);
  };

  const handleFileSelect = (file) => {
    // Handle the selected file (e.g., update state, perform actions)
  };

  //
  const sampleFiles = [
    { id: 1, name: 'File 1.txt' },
    { id: 2, name: 'File 2.jpg' },
    // Add more files as needed
  ];

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Title level={4} style={{ color: 'green' }}>
          {principalEmployername}
        </Title>

        <Collapse style={{ fontWeight: 'bolder' }}>
          <Panel
            disabled={
              userConfig.roleName && !roleName?.permissions?.Compliance?.create
            }
            header='Validate ESI'
            key='2'>
            <Row>
              <Col span={6}>
                <Form.Item
                  label='Contractor Name'
                  name='supplierId'
                  rules={[
                    {
                      required: true,
                      message: 'Select Contractor',
                    },
                  ]}>
                  <Select
                    style={{ width: '200px' }}
                    onSelect={(value, option) =>
                      handleDropdown2Change(value, option.label)
                    }>
                    {users.Response1?.map((option) => (
                      <Option
                        key={option.id}
                        label={option.name}
                        value={`${option._id},${option.name}`}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label='ESI Portal Link'>
                  <Button onClick={PfClick}>Go to ESI Portal</Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label='Upload Wage Register'
                  name='kronosFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Upload
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    beforeUpload={() => false}
                    // fileList={kronosFile ? [kronosFile] : []}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status === 'done') {
                        onKronosFileUpload(info.file);
                      }
                    }}>
                    <Button icon={<UploadOutlined />}>
                      Upload Wage Register
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label='Cloud Upload'
                  name='kronosFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                    Upload Wage Register
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label='Upload ESI-MC file'
                  name='portalFile'
                  // valuePropName="fileList"
                  // getValueFromEvent={normFile1}
                  // type="file"
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  {/* <CSVReader onFileLoaded={handleCsv2FileLoaded} /> */}
                  <Upload
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    beforeUpload={() => false}
                    // fileList={portalFile ? [portalFile] : []}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status === 'done') {
                        onPortalFileUpload(info.file);
                      }
                    }}>
                    <Button icon={<UploadOutlined />}>
                      Upload ESI-MC file
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label=' Cloud Upload PF-ECR file'
                  name='portalFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                    Upload PF-ECR file
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='paymentDate'
                  label='Payment Date'
                  rules={[{ required: true, message: 'Enter Payment Date' }]}
                  hasFeedback
                  // valuePropName="date"
                  // getValueFromEvent={normfile}
                >
                  <DatePicker
                    style={{ width: '67%', height: '40px' }}
                    format='DD-MM-YYYY'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                // onClick={handleCompareButtonClick}
                type='primary'
                style={{ marginRight: '100px' }}
                htmlType='submit'>
                Validate Data
              </Button>
            </Row>
          </Panel>
        </Collapse>

        {fileExplorerVisible && (
          <FileExplorer
            files={files}
            onFileSelect={handleFileSelect}
            onClose={closeFileExplorer}
          />
        )}

        <Modal
          visible={modalVisible}
          onCancel={handleModalCancel}
          centered
          width='90%'
          footer={null}>
          <Steps current={currentStep}>
            <Step
              title={`All Employees ${reportData?.data?.allCount}`}
              onClick={() => setCurrentStep(0)}
            />
            <Step
              title={`Mismatched Data ${reportData?.data?.exceptionCount}`}
              onClick={() => setCurrentStep(1)}
            />
            <Step
              title={`No Match Found ${reportData?.data?.noMatchCount}`}
              onClick={() => setCurrentStep(2)}
            />
          </Steps>
          <Divider></Divider>
          {currentStep === 0 && (
            <div>
              <div>
                <Table
                  columns={columns}
                  dataSource={Employee}
                  pagination={{ pageSize: 5 }}
                />
                <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
                  <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                    <Select
                      onChange={handleDropdown1Change}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        width: '100px',
                        // height: "50px",
                      }}
                      placeholder='PDF'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{ float: 'right', marginLeft: '10px' }}
                      type='primary'
                      onClick={(evt) => downloadFile(evt)}>
                      Download
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {currentStep === 1 && exceptionRecords && (
            <div>
              <Table
                dataSource={excepEmployeeRecords}
                columns={exceptioncolumns}
                pagination={{ pageSize: 5 }}
              />
              <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
                <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                  <Select
                    onChange={handleDropdown1Change}
                    placeholder='PDF'
                    style={{
                      fontWeight: 'bolder',
                      color: 'black',
                      width: '100px',
                      // height: "50px",
                    }}>
                    <Option value='PDF'>PDF</Option>
                    <Option value='Excel'>Excel</Option>
                  </Select>
                  <Button
                    style={{ float: 'right', marginLeft: '10px' }}
                    type='primary'
                    onClick={(evt) => downloadFile1(evt)}>
                    Download
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <Table
                dataSource={nomatchRecords}
                columns={nomatchRecord}
                pagination={{ pageSize: 5 }}
              />
            </div>
          )}
        </Modal>
      </Form>
      <Divider></Divider>

      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        <Table
          bordered
          dataSource={Employee1}
          columns={column}
          // title={Title}
          rowKey='userid'
        />
      </Space>
    </>
  );
}

export default Esireport;
