import React from 'react';
import {
  Button,
  Form,
  Input,
  DatePicker,
  Col,
  Row,
  Typography,
  Select,
} from 'antd';
import useAuth from '../../hooks/useAuth';

const { Title } = Typography;

const Wagedetails = ({ initialValues, onNext, onPrev, isViewOnly }) => {
  const formRef = React.useRef();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // values.confirmationBasic = values?.confirmationBasic;
    onNext(values);
  };
  /* eslint-disable */

  const [data, setData] = React.useState(initialValues);
  // const isDisabled = data?.approvalStage > 0;
  const { user, userConfig } = useAuth();

  const isDisabled = userConfig.role === 'approver';

  React.useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
  });

  const validateInput = (_, value) => {
    const regex = /^[0-9]+$/; // Regular expression to match only numbers

    if (value && !regex.test(value)) {
      return Promise.reject('Only numbers are allowed');
    }

    if (value && (value.length < 1 || value.length > 6)) {
      return Promise.reject('Length must be between 1 and 6 characters');
    }

    return Promise.resolve();
  };

  React.useEffect(() => {
    if (
      ['', ' ', undefined, 'undefined'].includes(
        initialValues?.confirmationBasic
      )
    ) {
      formRef.current?.setFieldsValue({
        ...initialValues,
        confirmationBasic: 0,
      });
    }
  }, [initialValues]);

  return (
    <>
      <Title level={5}>Wage Details(Daily)</Title>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        ref={formRef}
        form={form}>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='dailywages'
              label='Daily/Monthly Wages'
              rules={[
                { required: true, message: 'Select Daily/Monthly Wages' },
              ]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='Daily'>Daily </Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='joiningBasic'
              label='Joining Basic'
              rules={[
                { required: true, message: 'Enter BASIC' },

                { validator: validateInput },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                // defaultValue={form.getFieldValue('currentBasic')} onChange={(e)=>{
                //   setInputData(e.target.value)
                // }}
                // onChange={handleJoiningBasicChange}
                // onChange={(e) => handleJoiningBasicChange(e.target.value)}
                onChange={(e) => {
                  const joiningBasic = e.target.value;
                  const currentBasic = `${joiningBasic}`;
                  form.setFieldsValue({ currentBasic });
                }}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='confirmationBasic'
              label='Confirmation Basic'
              rules={[
                //  { required: true, message: "Enter DA" },
                { validator: validateInput },
              ]}
              hasFeedback>
              <Input size='large' disabled={isDisabled} defaultValue={'0'} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='currentBasic'
              label='Current Basic'
              rules={
                [
                  // { required: true, message: 'Enter BASIC' },
                  // { validator: validateInput },
                ]
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={(e) => {
                  const basicValue = e.target.value;

                  // if (basicValue) {
                  //   form.setFieldsValue({ currentBasic: basicValue });
                  // }
                }}
              />
            </Form.Item>
          </Col>

          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='effectiveDate'
              label='Wage Effective Date'
              rules={[{ required: true, message: 'Select Effective Date' }]}
              hasFeedback>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col> */}
        </Row>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Wagedetails;
