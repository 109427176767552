import React, { useContext } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  notification,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordbyuserid, Email } from '../services/auth';
import './index.css';
import validatePassword from '../utils/passwordValidator';
import { AuthContext } from '../contexts/AuthProvider';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;

function Changepassword1({ open, onCancel, isEdit = false, useridvalue }) {
  const formRef = React.useRef();
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };
  const onFinish = (value) => {
    const data = {
      userid: useridvalue,
      oldpassword: value.oldpassword,
      password: value.password,
      confirmPassword: value.confirmPassword,
      userType: 'Active',
    };
    const token = sessionStorage.getItem('token');

    ChangePasswordbyuserid(data)
      .then((res) => {
        const decodedToken = jwtDecode(token);
        notification.success({ message: res.data.message });
        Email({
          templateId: 'PASSWORD_CHANGED_SUCCESSFULLY',
          // VYNApplication: 'https://demo.wikiworks.in/login',
          VYNApplication: process.env.REACT_APP_URL,
          userid: decodedToken.userid,
          orgId: decodedToken.orgId,
          level: decodedToken.level,
          role: decodedToken.role,
          locationId: decodedToken.locationId,
          correctRecipientEmail: decodedToken.emailId,
        })
          .then((res) => {
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });

        logout();
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };
  const validatePassword = (rule, value, callback) => {
    // Add your password validation logic here
    // You can use the 'value' parameter to check if it's the same as the old password
    callback();
  };

  const handleNewPasswordPaste = (e) => {
    // Prevent pasting into the New Password field
    e.preventDefault();
  };

  return (
    <>
      <div className='change-password'>
        <Form layout='vertical' onFinish={onFinish} autoComplete='off'>
          <Title level={3}>Change Password</Title>
          <Form.Item
            label='Old Password'
            name='oldpassword'
            rules={[{ required: true, message: 'Enter Password' }]}>
            <Input.Password
              autoComplete='new-password'
              onPaste={handleNewPasswordPaste}
            />
          </Form.Item>
          <Form.Item
            label='New Password'
            name='password'
            rules={[
              // { required: true },
              { validator: validatePassword },
            ]}>
            <Input.Password
              autoComplete='new-password'
              onPaste={handleNewPasswordPaste}
            />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            rules={[
              // { required: true, message: "Enter Confirm Password" },
              { validator: validatePassword },
            ]}>
            <Input.Password
              autoComplete='new-password'
              onPaste={handleNewPasswordPaste}
            />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default Changepassword1;
