// import {
//   Form,
//   Input,
//   Row,
//   Col,
//   Checkbox,
//   Table,
//   Button,
//   Select,
//   Popconfirm,
//   Typography,
//   Divider,
// } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import { orgEmailValidator } from "../../validators";
// import useAuth from "../../hooks/useAuth";
// import DocumentFormTable from "./DocumentFormTable";

// const { Option } = Select;

// export default function OrganisationLocationPanel({
//   field,
//   index,
//   formRef,
//   orgInfoData,
// }) {
//   const { userConfig } = useAuth();

//   /** @type {import("antd/es/table").ColumnsType} */
//   // const columns = [
//   //   {
//   //     title: "First Name *",
//   //     dataIndex: "firstName",
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "firstName"]}
//   //           // name="firstName"
//   //           // name="firstname"
//   //           rules={[{ required: true, message: "Enter First Name" }]}
//   //         >
//   //           <Input />
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Last Name *",
//   //     dataIndex: "lastName",
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "lastName"]}
//   //           // name="lastname"
//   //           rules={[{ required: true, message: "Enter Last Name" }]}
//   //         >
//   //           <Input />
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Role",
//   //     dataIndex: "role",
//   //     width: 150,
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "role"]}
//   //           // name="role"
//   //           rules={[{ required: true, message: "Select Role" }]}
//   //         >
//   //           <Select>
//   //             <Option value="approver">Approver</Option>
//   //           </Select>
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Level *",
//   //     dataIndex: "level",
//   //     width: 150,
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "level"]}
//   //           // name="level"
//   //           rules={[{ required: true, message: "Select Level" }]}
//   //         >
//   //           <Select>
//   //             <Option value={1}>Level 1</Option>
//   //             <Option value={2}>Level 2</Option>
//   //             <Option value={3}>Level 3</Option>
//   //           </Select>
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "User ID *",
//   //     dataIndex: "userid",
//   //     width: 250,
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "userid"]}
//   //           // name="userid"
//   //           rules={[
//   //             { required: true, message: "Enter User ID" },
//   //             {
//   //               pattern: /^[A-Za-z0-9]+$/,
//   //               message: "No Special Characters Allowed",
//   //             },
//   //           ]}
//   //           // getValueFromEvent={(e) => e.target.value.toUpperCase()}
//   //         >
//   //           <Input />
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Password *",
//   //     dataIndex: "password",
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "password"]}
//   //           // name="password"
//   //           rules={[
//   //             { required: true, message: "Enter Password" },
//   //             { min: 8, message: "Enter minimum 8 characters" },
//   //             { max: 20, message: "Maximum 20 characters allowed" },
//   //           ]}
//   //         >
//   //           <Input.Password maxLength={20} />
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Email *",
//   //     dataIndex: "emailId",
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Form.Item
//   //           name={[record.name, "emailId"]}
//   //           // name="emailId"
//   //           rules={[
//   //             { required: true, message: "Enter Email" },
//   //             { type: "email", message: "Enter valid Email" },
//   //             {
//   //               validator: (_, value) =>
//   //                 orgEmailValidator(value, userConfig.orgId),
//   //             },
//   //           ]}
//   //         >
//   //           <Input />
//   //         </Form.Item>
//   //       );
//   //     },
//   //   },
//   //   {
//   //     title: "Actions",
//   //     dataIndex: "actions",
//   //     render: (_, record, i) => {
//   //       return (
//   //         <Popconfirm title="Delete" onConfirm={() => record.remove(i)}>
//   //           <Form.Item>
//   //             <Typography.Link>Delete</Typography.Link>
//   //           </Form.Item>
//   //         </Popconfirm>
//   //       );
//   //     },
//   //   },
//   // ];

//   // const renderTitle = () => {
//   //   return <Typography.Title level={5}>Approvers</Typography.Title>;
//   // };

//   // const renderFooter = (handleAdd) => {
//   //   return (
//   //     <Button
//   //       type="dashed"
//   //       htmlType="button"
//   //       onClick={() => handleAdd()}
//   //       block
//   //       icon={<PlusOutlined />}
//   //     >
//   //       Add Approvers
//   //     </Button>
//   //   );
//   // };

//   return (
//     <>
//       <Row gutter={12}>
//         <Col xs={24} lg={12}>
//           <Form.Item
//             {...field}
//             label='Loc Name'
//             name={[field.name, "name"]}
//             rules={[
//               { required: true, message: "Enter Name" },
//               { pattern: /^[A-Za-z\s]+$/, message: "Only Alphabets Allowed" },
//             ]}
//           >
//             <Input disabled />
//           </Form.Item>
//         </Col>

//         <Col xs={24} lg={12}>
//           <Form.Item
//             {...field}
//             label=' Location ID'
//             name={[field.name, "id"]}
//             rules={[
//               { required: true, message: "Enter ID" },
//               {
//                 // pattern: /^[A-Za-z0-9]+$/,
//                 // message: "No Special Characters Allowed",
//               },
//             ]}
//           >
//             <Input addonBefore={`${orgInfoData.id}_`} disabled />
//           </Form.Item>
//         </Col>

//         {/* <Col>
//           <Form.Item
//             {...field}
//             name={[field.name, "aadharCheck"]}
//             valuePropName="checked"
//           >
//             <Checkbox>Aadhar Check</Checkbox>
//           </Form.Item>
//         </Col>

//         <Col>
//           <Form.Item
//             {...field}
//             name={[field.name, "secureCheck"]}
//             valuePropName="checked"
//           >
//             <Checkbox>Secure QR</Checkbox>
//           </Form.Item>
//         </Col> */}
//       </Row>

//       <Form.List name={[field.name, "users"]}>
//         {(fields, actions) => {
//           return (
//             <Table
//               // title={renderTitle}
//               // columns={columns}
//               dataSource={fields.map((f) => ({ ...f, remove: actions.remove }))}
//               scroll={{ x: 1300 }}
//               // footer={() => renderFooter(actions.add)}
//             />
//           );
//         }}
//       </Form.List>
//       <Divider />
//       {/* <DocumentFormTable field={field} /> */}
//     </>
//   );
// }

import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Table,
  Button,
  Select,
  Popconfirm,
  Typography,
  Divider,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import DocumentFormTable from './DocumentFormTable';
import { State } from '../../JSON/data';

const { Option } = Select;

export default function OrganisationLocationPanel({
  field,
  index,
  formRef,
  orgInfoData,
}) {
  const { userConfig } = useAuth();

  const states = State;

  /** @type {import("antd/es/table").ColumnsType} */

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            {...field}
            label='Loc Name'
            name={[field.name, 'name']}
            // name="name"
            rules={[
              { required: true, message: 'Enter  Name' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              {
                pattern: /^[A-Za-z\s-]*$/,
                message: 'Only Alphabetic Allowed',
              },
            ]}>
            <Input id='locationName' />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            {...field}
            label='Ho Location ID'
            name={[field.name, 'id']}
            rules={[
              { required: true, message: 'Enter Ho Location ID' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              // {
              //   pattern: /^[a-zA-Z0-9]+$/,
              //   message: 'Enter Alpha Numeric Only',
              // },
            ]}>
            {/* <Input addonBefore={`${orgInfoData.id}_ `} /> */}
            <Input  />

          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item name={[field.name, 'address']} label='Address'>
            <Input></Input>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name={[field.name, 'city']} label='City'>
            <Input></Input>
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6} id='state'>
          <Form.Item
            name={[field.name, 'state']}
            label='State'
            // rules={[{ required: true, message: 'Enter State' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className='ant-select-selector'
              placeholder='Select State'>
              {states.map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name={[field.name, 'zipCode']} label='Zip Code'>
            <Input></Input>
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={[field.name, 'users']}>
        {(fields, actions) => {
          return (
            <Table
              // title={renderTitle}
              // columns={columns}
              dataSource={fields.map((f) => ({ ...f, remove: actions.remove }))}
              scroll={{ x: 1300 }}
              // footer={() => renderFooter(actions.add)}
            />
          );
        }}
      </Form.List>
      <Divider />
      {/* <DocumentFormTable field={field} /> */}
    </>
  );
}
