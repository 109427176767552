import React from 'react';
import {
  Button,
  Form,
  Input,
  DatePicker,
  Col,
  Row,
  Typography,
  Select,
} from 'antd';
import useAuth from '../../hooks/useAuth';

const { Title } = Typography;

const Wagedetails = ({
  initialValues,
  onNext,
  onPrev,
  isViewOnly,
  onUpdateFields,
}) => {
  const onFinish = (values) => {
    onNext(values);
  };
  /* eslint-disable */

  const [data, setData] = React.useState(initialValues);
  // const isDisabled = data?.approvalStage > 0;
  const { user, userConfig } = useAuth();

  const isDisabled = userConfig.role === 'approver';

  const validateInput = (_, value) => {
    const regex = /^[0-9]+$/; // Regular expression to match only numbers

    if (value && !regex.test(value)) {
      return Promise.reject('Only numbers are allowed');
    }

    if (value && (value.length < 1 || value.length > 6)) {
      return Promise.reject('Length must be between 1 and 6 characters');
    }

    return Promise.resolve();
  };

  const handleFieldChange = (fieldName, value) => {
    // Notify the parent component about the updated field

    // if (typeof onUpdateFields === 'function') {
    //   onUpdateFields(fieldName, value);
    // } else {
    //   console.error('onUpdateFields is not a function');
    // }
    onUpdateFields?.(fieldName, value);
  };

  const onChange = (e) => {
    //
    const { name, value } = e.target;
    if (value.trim() !== '') {
      handleFieldChange(name, value);
    }
  };

  return (
    <>
      <Title level={5}>Wage Details(Daily)</Title>
      <Form layout='vertical' initialValues={initialValues} onFinish={onFinish}>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='basic'
              label='Basic'
              rules={[
                // { required: true, message: 'Enter BASIC' },//mandatory

                { validator: validateInput },
              ]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='basic'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='da'
              label='DA'
              rules={[
                //  { required: true, message: "Enter DA" },
                { validator: validateInput },
              ]}
              hasFeedback>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='da'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='allowance1'
              label='Allowance-1'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='allowance1'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='allowance2'
              label='Allowance-2'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='allowance2'
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='hra'
              label='HRA'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='hra'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='conveyance'
              label='Conveyance'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='conveyance'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='specialAllowance'
              label='Special Allowance'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='specialAllowance'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='medicalAllowance'
              label='Medical Allowance'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='medicalAllowance'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='eduAllowance'
              label='Education Allowance'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='eduAllowance'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='travelAllowance'
              label='Leave/Travel Allowance'
              rules={[{ validator: validateInput }]}>
              <Input
                size='large'
                // disabled={isDisabled}
                disabled={true}
                onChange={onChange}
                name='travelAllowance'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='ot' label='OT Applicable'>
              <Select size='large' placeholder='OT' disabled={true}>
                <Option value=''></Option>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='effectiveDate'
              label='Wage Effective Date'
              // rules={[{ required: true, message: 'Select Effective Date' }]}//Mandatory
              hasFeedback>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                // disabled={isDisabled}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Wagedetails;
