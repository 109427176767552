import React, { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditFilled } from '@ant-design/icons';

import { LongServicecolumns } from './Constant';
import LongServiceModal from './FormModal/LongServiceModal';
import { getLongService, updateLongservice } from '../services/auth';
import useAuth from '../hooks/useAuth';
import { longService } from '../services/auth';
import { setLoader } from '../actions';
import { useParams } from 'react-router-dom';

import {
  downloadHistoricalData,
  downloadLongServiceTemplate,
} from '../services/auth';
import { useNavigate } from 'react-router-dom';

import {
  Form,
  Space,
  Row,
  Modal,
  DatePicker,
  notification,
  Col,
  Button,
  Divider,
  Table,
  Typography,
  Tooltip,
  Upload,
  Input,
} from 'antd';

const LongService = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { _id } = useParams();
  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const [id, setId] = useState();
  const [record, setRecord] = useState();

  const edit = (record) => {
    console.log(
      'record?.dateof15yearsawardgiven',
      record?.dateof15yearsawardgiven
    );
    // form.setFieldValue(
    //   'dateof15yearsawardgiven',
    //   record?.dateof15yearsawardgiven
    // );

    setId(record._id);
    setRecord(record);
    setIsEditModalVisible(true);
  };

  const LongServicecolumns = [
    {
      title: 'SI.NO',
      dataIndex: 'srno',
      key: 'srno',
    },

    {
      title: 'PS No.',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),
    },
    {
      title: 'Name',
      dataIndex: 'workmenName',
      key: 'workmenName',
      ...getColumnSearchProps('workmenName'),
    },
    {
      title: 'Cadre',
      dataIndex: 'cadre',
      key: 'cadre',
    },
    {
      title: 'Dept Code',
      dataIndex: 'deptCode',
      key: 'deptCode',
    },
    {
      title: 'Dept Name',
      dataIndex: 'deptName',
      key: 'deptName',
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      // width: '20%',
    },
    {
      title: 'DOJ',
      dataIndex: 'doj',
      key: 'doj',
    },
    {
      title: 'DOR',
      dataIndex: 'dor',
      key: 'dor',
    },
    {
      title: 'LSA-15 Years',
      dataIndex: 'lsa15',
      key: 'lsa15',
    },
    {
      title: 'Date of 15 Years Award Given',
      dataIndex: 'dateof15yearsawardgiven',
      key: 'dateof15yearsawardgiven',
    },
    {
      title: 'LSA-20 Years',
      dataIndex: 'lsa20',
      key: 'lsa20',
    },
    {
      title: 'Date of 20 Years Award Given',
      dataIndex: 'dateof20yearsawardgiven',
      key: 'dateof20yearsawardgiven',
    },
    {
      title: 'LSA-25 Years',
      dataIndex: 'lsa25',
      key: 'lsa25',
    },
    {
      title: 'Date of 25 Years Award Given',
      dataIndex: 'dateof25yearsawardgiven',
      key: 'dateof25yearsawardgiven',
    },
    {
      title: 'LSA-30 Years',
      dataIndex: 'lsa30',
      key: 'lsa30',
    },
    {
      title: 'Date of 30 Years Award Given',
      dataIndex: 'dateof30yearsawardgiven',
      key: 'dateof30yearsawardgiven',
    },
    {
      title: 'LSA-35 Years',
      dataIndex: 'lsa35',
      key: 'lsa35',
    },
    {
      title: 'Date of 35 Years Award Given',
      dataIndex: 'dateof35yearsawardgiven',
      key: 'dateof35yearsawardgiven',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, _id) => {
        return (
          <Space>
            <Typography.Link onClick={() => edit(record)}>
              <EditFilled />
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const [LongService, setLongService] = React.useState([]);

  const { userConfig } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    GetLongserviceList();
  }, []);
  const GetLongserviceList = () => {
    setLoader(true);

    getLongService()
      .then((res) => {
        setLoader(false);

        setLongService(
          res.data.longServicesList.map((row) => ({
            _id: row._id,
            srno: row.srno,
            workmenName: row.name,
            psNumber: row.psno,
            cadre: row.cadre,
            deptCode: row.deptcode,
            deptName: row.deptname,
            // dob: row.dob,
            // dob: row.dob ? dayjs(new Date(row.dob)) : '',
            dob: row.dob,
            dor: row.dor,
            doj: row.doj,
            // lsa15: row['lsa-15years'],
            lsa15: dayjs(
              new Date((row['lsa-15years'] - 25569) * 86400 * 1000)
            ).format('DD-MM-YYYY'),

            dateof15yearsawardgiven: row.dateof15yearsawardgiven,
            // lsa20: row['lsa-20years'],
            lsa20: dayjs(
              new Date((row['lsa-20years'] - 25569) * 86400 * 1000)
            ).format('DD-MM-YYYY'),
            dateof20yearsawardgiven: row.dateof20yearsawardgiven,
            // lsa25: row['lsa-25years'],
            lsa25: dayjs(
              new Date((row['lsa-25years'] - 25569) * 86400 * 1000)
            ).format('DD-MM-YYYY'),
            dateof25yearsawardgiven: row.dateof25yearsawardgiven,
            // lsa30: row['lsa-30years'],
            lsa30: dayjs(
              new Date((row['lsa-30years'] - 25569) * 86400 * 1000)
            ).format('DD-MM-YYYY'),
            dateof30yearsawardgiven: row.dateof30yearsawardgiven,
            // lsa35: row['lsa-35years'],
            lsa35: dayjs(
              new Date((row['lsa-35years'] - 25569) * 86400 * 1000)
            ).format('DD-MM-YYYY'),
            dateof35yearsawardgiven: row.dateof35yearsawardgiven,
            remarks: row.remarks,
            // lsa40: row['lsa-40years'],
            // remarks40: row['remarks_5'],
            // lsa45: row['lsa-45years'],
            // remarks45: row['remarks_6'],
            // lsa50: row['lsa-50years'],
            // remarks50: row['remarks_7'],
          }))
        );
      })
      .catch((error) => {});
  };
  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (value) => {
    updateLongservice()
      .then((res) => {
        notification.success({ message: 'Record updated Successfully' });
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
    setIsEditModalVisible(false);
  };

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
    setIsEditModalVisible(false);
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the second sheet (index 1) by its name
      const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(secondSheet);

      const allData = [];

      sheetData.forEach((item) => {
        item.orgId = userConfig.orgId;
        allData.push(item);
      });

      // Now you can send allData to the longService function
      longService(allData)
        .then((res) => {
          notification.success({ message: res.data.message });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });
      navigate('/home/approver/historical/longService');
    };
    // window.location.reload();

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };
  // const onFinish = (value) => {
  //   setLoader(true);

  //
  //   value.orgId = userConfig.orgId;

  //   longService(value)
  //     .then((res) => {
  //       setLoader(false);

  //       notification.success({ message: res.data.message });
  //     })
  //     .catch((err) => {
  //       notification.error({ message: err.response.data.message });
  //       navigate('/home/approver/historical/longService');
  //     });
  //   window.location.reload();

  //   setIsModalVisible(false);
  // };

  const downloadExcel = () => {
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadHistoricalData(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `Long Service`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/downloadlongservice-excel',
      //   {
      // const response = await fetch(
      //   'https://vyn.wikiworks.in:443/api/download-excel',
      //   {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/downloadlongservice-excel',
        `${process.env.REACT_APP_API_URL}/downloadlongservice-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Long_Service_Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };
  const onFinish = (value) => {
    updateLongservice(id, value)
      .then((res) => {
        notification.success({ message: 'Record updated Successfully' });
      })
      .catch((err) => {
        notification.success({ message: 'Record updated Successfully' });
      });
    setIsEditModalVisible(false);
  };
  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Long Service
      </Title>
      <Form layout='vertical' onFinish={onFinish}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Tooltip title='Download Sample Template '>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              onClick={handleDownload}
              disabled={userConfig.role === 'shop'}>
              Download Template
            </Button>
          </Tooltip>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}
                disabled={userConfig.role === 'shop'}>
                Import
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              onClick={downloadExcel}
              disabled={userConfig.role === 'shop'}>
              Export
            </Button>
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              type='primary'
              hidden={true}
              onClick={showModal}
              disabled={userConfig.role === 'shop'}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            open={isEditModalVisible}
            // onOk={handleOk}
            okText={'Update'}
            // onCancel={handleCancel}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}>
            <Form layout='vertical' onFinish={onFinish} form={form}>
              <Row gutter={24}>
                <Col
                  span={6}
                  hidden={record?.dateof15yearsawardgiven == 'undefined'}>
                  <Form.Item
                    label='Date of 15 Years Award Given	'
                    name='dateof15yearsawardgiven'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Date of 20 Years Award Given	'
                    name='dateof20yearsawardgiven'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Date of 25 Years Award Given	'
                    name='dateof25yearsawardgiven'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item
                    label='Date of 30 Years Award Given	'
                    name='dateof30yearsawardgiven'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item
                    label='Date of 35 Years Award Given	'
                    name='dateof35yearsawardgiven'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks'>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          {/* <Modal
            title='Long Service'
            open={isModalVisible}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            >
            <Form onFinish={onFinish} layout='vertical'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label='Name' name='workmanname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PS Number' name='psnumber'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Cadre' name='cadre'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Dept Code' name='deptcode'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Dept Name' name='deptname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='DOB' name='dob'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='DOJ' name='doj'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='LSA-15 Years' name='lsa-15years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='LSA-20 Years' name='lsa-20years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_1'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='LSA-25 Years' name='lsa-25years'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_2'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='LSA-30 Years' name='lsa-30years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_3'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='	LSA-35 Years' name='lsa-35years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_4'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='	LSA-40 Years' name='lsa-40years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_5'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='	LSA-45 Years' name='lsa-45years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_6'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='	LSA-50 Years' name='lsa-50years'>
                    <Input></Input>
                  </Form.Item>
                </Col>{' '}
                <Col span={6}>
                  <Form.Item label='Remarks' name='remarks_7'>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal> */}
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          width='50%'
          // bordered
          columns={LongServicecolumns}
          dataSource={LongService}
          scroll={{ x: 1500 }}
        />
      </Form>
    </>
  );
};

export default LongService;
