import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { longService } from '../services/auth';
import { notification } from 'antd';
// import useAuth from '../hooks/useAuth';
//  const [jsonData, setJsonData] = useState([]);
//  const [tableData, setTableData] = useState([]);

// const { userConfig } = useAuth();

//

export const LongServicecolumns = [
  {
    title: 'Workman Name',
    dataIndex: 'workmenName',
    key: 'workmenName',
  },
  {
    title: 'PS Number',
    dataIndex: 'psNumber',
    key: 'psNumber',
  },
  {
    title: 'Cadre',
    dataIndex: 'cadre',
    key: 'cadre',
  },
  {
    title: 'Dept Code',
    dataIndex: 'deptCode',
    key: 'deptCode',
  },
  {
    title: 'Dept Name',
    dataIndex: 'deptName',
    key: 'deptName',
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    key: 'dob',
  },
  {
    title: 'DOJ',
    dataIndex: 'doj',
    key: 'doj',
  },
  {
    title: 'LSA-15 Years',
    dataIndex: 'lsa15',
    key: 'lsa15',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks15',
    key: 'remarks15',
  },
  {
    title: 'LSA-20 Years',
    dataIndex: 'lsa20',
    key: 'lsa20',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks20',
    key: 'remarks20',
  },
  {
    title: 'LSA-25 Years',
    dataIndex: 'lsa25',
    key: 'lsa25',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks25',
    key: 'remarks25',
  },
  {
    title: 'LSA-30 Years',
    dataIndex: 'lsa30',
    key: 'lsa30',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks30',
    key: 'remarks30',
  },
  {
    title: 'LSA-35 Years',
    dataIndex: 'lsa35',
    key: 'lsa35',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks35',
    key: 'remarks35',
  },
];
export const EmployeeGrowthColumns = [
  {
    title: 'Workman Name',
    dataIndex: 'workmenName',
    key: 'workmenName',
  },
  {
    title: 'PS Number',
    dataIndex: 'psNumber',
    key: 'psNumber',
  },
  {
    title: 'Inc 93',
    dataIndex: 'inc93',
    key: 'inc93',
  },
  {
    title: 'Inc 95',
    dataIndex: 'inc95',
    key: 'inc95',
  },
  {
    title: 'Inc 96',
    dataIndex: 'inc96',
    key: 'inc96',
  },
  {
    title: 'Inc 97',
    dataIndex: 'inc97',
    key: 'inc97',
  },
  {
    title: 'Inc 98',
    dataIndex: 'inc98',
    key: 'inc98',
  },
  {
    title: 'Inc 99',
    dataIndex: 'inc99',
    key: 'inc99',
  },
  {
    title: 'Inc 00',
    dataIndex: 'inc00',
    key: 'inc00',
  },
  {
    title: 'Inc 01',
    dataIndex: 'inc01',
    key: 'inc01',
  },
  {
    title: 'Inc 02',
    dataIndex: 'inc02',
    key: 'inc02',
  },
  {
    title: 'Inc 03',
    dataIndex: 'inc03',
    key: 'inc03',
  },
  {
    title: 'Inc 04',
    dataIndex: 'inc04',
    key: 'inc04',
  },
  {
    title: 'Inc 05',
    dataIndex: 'inc05',
    key: 'inc05',
  },
  {
    title: 'Inc 06',
    dataIndex: 'inc06',
    key: 'inc06',
  },
  {
    title: 'Inc 07',
    dataIndex: 'inc07',
    key: 'inc07',
  },
  {
    title: 'Inc 08',
    dataIndex: 'inc08',
    key: 'inc08',
  },
  {
    title: 'Inc 09',
    dataIndex: 'inc09',
    key: 'inc09',
  },
  {
    title: 'Inc 10',
    dataIndex: 'inc10',
    key: 'inc10',
  },
  {
    title: 'Inc 11',
    dataIndex: 'inc11',
    key: 'inc11',
  },
  {
    title: 'Inc 12',
    dataIndex: 'inc12',
    key: 'inc12',
  },
  {
    title: 'Inc 13',
    dataIndex: 'inc13',
    key: 'inc13',
  },
  {
    title: 'Inc 14',
    dataIndex: 'inc14',
    key: 'inc14',
  },
  {
    title: 'Inc 15',
    dataIndex: 'inc15',
    key: 'inc15',
  },
  {
    title: 'Inc 16',
    dataIndex: 'inc16',
    key: 'inc16',
  },
  {
    title: 'Inc 17',
    dataIndex: 'inc17',
    key: 'inc17',
  },
  {
    title: 'Inc 18',
    dataIndex: 'inc18',
    key: 'inc18',
  },
  {
    title: 'Inc 19',
    dataIndex: 'inc19',
    key: 'inc19',
  },
  {
    title: 'Inc 20',
    dataIndex: 'inc20',
    key: 'inc20',
  },
  {
    title: 'Inc 21',
    dataIndex: 'inc21',
    key: 'inc21',
  },
  {
    title: 'Inc 22',
    dataIndex: 'inc22',
    key: 'inc22',
  },
  {
    title: 'Inc 23',
    dataIndex: 'inc23',
    key: 'inc22',
  },
  {
    title: 'Inc 24',
    dataIndex: 'inc24',
    key: 'inc22',
  },
];
export const YearlyPointsColumns = [
  {
    title: 'Workman Name',
    dataIndex: 'workmenName',
    key: 'workmenName',
  },
  {
    title: 'PS Number',
    dataIndex: 'psNumber',
    key: 'psNumber',
  },
  {
    title: 'PTS 23',
    dataIndex: 'pts23',
    key: 'pts23',
  },
  {
    title: 'PTS 24',
    dataIndex: 'pts24',
    key: 'pts24',
  },
];
export const EmployeeMasterColumns = [
  {
    title: 'Disable',
    dataIndex: 'disable',
    key: 'disable',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    key: 'photo',
  },
  {
    title: 'Short Name',
    dataIndex: 'shortName',
    key: 'shortName',
  },
  {
    title: 'Dept',
    dataIndex: 'dept',
    key: 'dept',
  },
  {
    title: 'Dept Name',
    dataIndex: 'deptName',
    key: 'deptName',
  },
  {
    title: 'Area',
    dataIndex: 'area',
    key: 'area',
  },
  {
    title: 'INC 23',
    dataIndex: 'inc23',
    key: 'inc23',
  },
  {
    title: 'Present Cadre',
    dataIndex: 'presentCadre',
    key: 'presentCadre',
  },
  {
    title: 'Previous Cadre',
    dataIndex: 'previousCadre',
    key: 'previousCadre',
  },
  {
    title: 'ATWM',
    dataIndex: 'atwm',
    key: 'atwm',
  },
  {
    title: 'HE/DIC',
    dataIndex: 'he/dic',
    key: 'he/dic',
  },
  {
    title: 'Birth Date',
    dataIndex: 'birthDate',
    key: 'birthDate',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'DOJ',
    dataIndex: 'doj',
    key: 'doj',
  },
  {
    title: 'Exp',
    dataIndex: 'exp',
    key: 'exp',
  },
  {
    title: 'Date Of Retirement',
    dataIndex: 'dateOfRetirement',
    key: 'dateOfRetirement',
  },
  {
    title: 'Mobile No.',
    dataIndex: 'mobileNo',
    key: 'mobileNo',
  },
  {
    title: 'Email Id',
    dataIndex: 'emailId',
    key: 'emailId',
  },
  {
    title: 'Sport Group',
    dataIndex: 'sportGroup',
    key: 'sportGroup',
  },
  {
    title: 'House Number',
    dataIndex: 'houseNumber',
    key: 'houseNumber',
  },
  {
    title: '2nd Adress Line',
    dataIndex: 'adressLine',
    key: 'adressLine',
  },
  {
    title: '2nd Adress Line',
    dataIndex: 'adressLine',
    key: 'adressLine',
  },
];
export const DesciplinaryActionsColumns = [
  {
    title: 'Workman Name',
    dataIndex: 'workmenName',
    key: 'workmenName',
  },
  {
    title: 'PS Number',
    dataIndex: 'psNumber',
    key: 'psNumber',
  },
  {
    title: 'Details Of Misconduct',
    dataIndex: 'detailsofMisconduct',
    key: 'detailsofMisconduct',
  },
  {
    title: 'Incident Date',
    dataIndex: 'incidentDate',
    key: 'incidentDate',
  },
  {
    title: 'Report Received',
    dataIndex: 'reportReceived',
    key: 'reportReceived',
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Desciplinary Actions',
    dataIndex: 'disciplinaryactions',
    key: 'aryActions',
  },
  {
    title: 'Actions Taken',
    dataIndex: 'dateofactionstaken',
    key: 'actionTaken',
  },
  {
    title: 'Issued date',
    dataIndex: 'issuedDate',
    key: 'issuedDate',
  },
  {
    title: 'Remark Note',
    dataIndex: 'remarks',
    key: 'remarks20',
  },
];
export const YearlyLeavesColumns = [
  {
    title: 'Workman Name',
    dataIndex: 'workmenName',
    key: 'workmenName',
  },
  {
    title: 'PS Number',
    dataIndex: 'psNumber',
    key: 'psNumber',
  },
  {
    title: 'A',
    dataIndex: 'a',
    key: 'a',
  },
  {
    title: 'CL 22',
    dataIndex: 'cl22',
    key: 'cl22',
  },
  {
    title: 'SL 22',
    dataIndex: 'sl22',
    key: 'sl22',
  },
  {
    title: 'LOP 22',
    dataIndex: 'lop22',
    key: 'lop22',
  },
  {
    title: 'UA 22',
    dataIndex: 'ua22',
    key: 'ua22',
  },
  {
    title: 'PL 22',
    dataIndex: 'pl22',
    key: 'pl22',
  },
  {
    title: 'ACL 22',
    dataIndex: 'alc22',
    key: 'acl22',
  },
];

export const beforeUpload = (file) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });

    // Access the second sheet (index 1) by its name
    const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
    const sheetData = XLSX.utils.sheet_to_json(secondSheet);

    const allData = [];

    sheetData.forEach((item) => {
      allData.push(item);
    });

    // Now you can send allData to the longService function
    longService(allData)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  reader.readAsArrayBuffer(file);
  return false; // Prevent default Upload behavior
};

//filter
