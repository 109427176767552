export const State = [
  'Andhra Pradesh',
  // 'Andaman and Nicobar Islands',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Chandigarh',
  // 'Dadra ,Nagar Haveli,Diu and Daman',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  // 'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Puducherry',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'UttarPradesh',
  'West Bengal',
];
export const AndhraPradesh = [
  'Anantapur',
  'Chittoor',
  'East Godavari',
  'Guntur',
  'Krishna',
  'Kurnool',
  'Nellore',
  'Prakasam',
  'Srikakulam',
  'Visakhapatnam',
  'Vizianagaram',
  'West Godavari',
  'YSR Kadapa',
];
export const ArunachalPradesh = [
  'Tawang',
  'West Kameng',
  'East Kameng',
  'Papum Pare',
  'Kurung Kumey',
  'Kra Daadi',
  'Lower Subansiri',
  'Upper Subansiri',
  'West Siang',
  'East Siang',
  'Siang',
  'Upper Siang',
  'Lower Siang',
  'Lower Dibang Valley',
  'Dibang Valley',
  'Anjaw',
  'Lohit',
  'Namsai',
  'Changlang',
  'Tirap',
  'Longding',
];
export const Assam = [
  'Baksa',
  'Barpeta',
  'Biswanath',
  'Bongaigaon',
  'Cachar',
  'Charaideo',
  'Chirang',
  'Darrang',
  'Dhemaji',
  'Dhubri',
  'Dibrugarh',
  'Goalpara',
  'Golaghat',
  'Hailakandi',
  'Hojai',
  'Jorhat',
  'Kamrup Metropolitan',
  'Kamrup',
  'Karbi Anglong',
  'Karimganj',
  'Kokrajhar',
  'Lakhimpur',
  'Majuli',
  'Morigaon',
  'Nagaon',
  'Nalbari',
  'Dima Hasao',
  'Sivasagar',
  'Sonitpur',
  'South Salmara-Mankachar',
  'Tinsukia',
  'Udalguri',
  'West Karbi Anglong',
];
export const Bihar = [
  'Araria',
  'Arwal',
  'Aurangabad',
  'Banka',
  'Begusarai',
  'Bhagalpur',
  'Bhojpur',
  'Buxar',
  'Darbhanga',
  'East Champaran (Motihari)',
  'Gaya',
  'Gopalganj',
  'Jamui',
  'Jehanabad',
  'Kaimur (Bhabua)',
  'Katihar',
  'Khagaria',
  'Kishanganj',
  'Lakhisarai',
  'Madhepura',
  'Madhubani',
  'Munger (Monghyr)',
  'Muzaffarpur',
  'Nalanda',
  'Nawada',
  'Patna',
  'Purnia (Purnea)',
  'Rohtas',
  'Saharsa',
  'Samastipur',
  'Saran',
  'Sheikhpura',
  'Sheohar',
  'Sitamarhi',
  'Siwan',
  'Supaul',
  'Vaishali',
  'West Champaran',
];
export const Chandigarh = ['Chandigarh'];
export const Chhattisgarh = [
  'Balod',
  'Baloda Bazar',
  'Balrampur',
  'Bastar',
  'Bemetara',
  'Bijapur',
  'Bilaspur',
  'Dantewada (South Bastar)',
  'Dhamtari',
  'Durg',
  'Gariyaband',
  'Janjgir-Champa',
  'Jashpur',
  'Kabirdham (Kawardha)',
  'Kanker (North Bastar)',
  'Kondagaon',
  'Korba',
  'Korea (Koriya)',
  'Mahasamund',
  'Mungeli',
  'Narayanpur',
  'Raigarh',
  'Raipur',
  'Rajnandgaon',
  'Sukma',
  'Surajpur  ',
  'Surguja',
];
export const Dadra = ['Dadra & Nagar Haveli'];
export const Daman = ['Daman', 'Diu'];
export const Delhi = [
  'Central Delhi',
  'East Delhi',
  'New Delhi',
  'North Delhi',
  'North East  Delhi',
  'North West  Delhi',
  'Shahdara',
  'South Delhi',
  'South East Delhi',
  'South West  Delhi',
  'West Delhi',
];
export const Goa = ['North Goa', 'South Goa'];
export const Gujarat = [
  'Ahmedabad',
  'Amreli',
  'Anand',
  'Aravalli',
  'Banaskantha (Palanpur)',
  'Bharuch',
  'Bhavnagar',
  'Botad',
  'Chhota Udepur',
  'Dahod',
  'Dangs (Ahwa)',
  'Devbhoomi Dwarka',
  'Gandhinagar',
  'Gir Somnath',
  'Jamnagar',
  'Junagadh',
  'Kachchh',
  'Kheda (Nadiad)',
  'Mahisagar',
  'Mehsana',
  'Morbi',
  'Narmada (Rajpipla)',
  'Navsari',
  'Panchmahal (Godhra)',
  'Patan',
  'Porbandar',
  'Rajkot',
  'Sabarkantha (Himmatnagar)',
  'Surat',
  'Surendranagar',
  'Tapi (Vyara)',
  'Vadodara',
  'Valsad',
];
export const Haryana = [
  'Ambala',
  'Bhiwani',
  'Charkhi Dadri',
  'Faridabad',
  'Fatehabad',
  'Gurgaon',
  'Hisar',
  'Jhajjar',
  'Jind',
  'Kaithal',
  'Karnal',
  'Kurukshetra',
  'Mahendragarh',
  'Mewat',
  'Palwal',
  'Panchkula',
  'Panipat',
  'Rewari',
  'Rohtak',
  'Sirsa',
  'Sonipat',
  'Yamunanagar',
];
export const HimachalPradesh = [
  'Bilaspur',
  'Chamba',
  'Hamirpur',
  'Kangra',
  'Kinnaur',
  'Kullu',
  'Lahaul &amp; Spiti',
  'Mandi',
  'Shimla',
  'Sirmaur (Sirmour)',
  'Solan',
  'Una',
];
export const Jammu = [
  'Anantnag',
  'Bandipore',
  'Baramulla',
  'Budgam',
  'Doda',
  'Ganderbal',
  'Jammu',
  'Kargil',
  'Kathua',
  'Kishtwar',
  'Kulgam',
  'Kupwara',
  'Leh',
  'Poonch',
  'Pulwama',
  'Rajouri',
  'Ramban',
  'Reasi',
  'Samba',
  'Shopian',
  'Srinagar',
  'Udhampur',
];
export const Jharkhand = [
  'Bokaro',
  'Chatra',
  'Deoghar',
  'Dhanbad',
  'Dumka',
  'East Singhbhum',
  'Garhwa',
  'Giridih',
  'Godda',
  'Gumla',
  'Hazaribag',
  'Jamtara',
  'Khunti',
  'Koderma',
  'Latehar',
  'Lohardaga',
  'Pakur',
  'Palamu',
  'Ramgarh',
  'Ranchi',
  'Sahibganj',
  'Seraikela-Kharsawan',
  'Simdega',
  'West Singhbhum',
];
export const Karnataka = [
  'Bagalkot',
  'Ballari (Bellary)',
  'Belagavi (Belgaum)',
  'Bengaluru (Bangalore) Rural',
  'Bengaluru (Bangalore) Urban',
  'Bidar',
  'Chamarajanagar',
  'Chikballapur',
  'Chikkamagaluru (Chikmagalur)',
  'Chitradurga',
  'Dakshina Kannada',
  'Davangere',
  'Dharwad',
  'Gadag',
  'Hassan',
  'Haveri',
  'Kalaburagi (Gulbarga)',
  'Kodagu',
  'Kolar',
  'Koppal',
  'Mandya',
  'Mysuru (Mysore)',
  'Raichur',
  'Ramanagara',
  'Shivamogga (Shimoga)',
  'Tumakuru (Tumkur)',
  'Udupi',
  'Uttara Kannada (Karwar)',
  'Vijayapura (Bijapur)',
  'Yadgir',
];
export const Kerala = [
  'Alappuzha',
  'Ernakulam',
  'Idukki',
  'Kannur',
  'Kasaragod',
  'Kollam',
  'Kottayam',
  'Kozhikode',
  'Malappuram',
  'Palakkad',
  'Pathanamthitta',
  'Thiruvananthapuram',
  'Thrissur',
  'Wayanad',
];
export const Lakshadweep = [
  'Agatti',
  'Amini',
  'Androth',
  'Bithra',
  'Chethlath',
  'Kavaratti',
  'Kadmath',
  'Kalpeni',
  'Kilthan',
  'Minicoy',
];
//   export const Ladakh=[

//   ];
export const MadhyaPradesh = [
  'Agar Malwa',
  'Alirajpur',
  'Anuppur',
  'Ashoknagar',
  'Balaghat',
  'Barwani',
  'Betul',
  'Bhind',
  'Bhopal',
  'Burhanpur',
  'Chhatarpur',
  'Chhindwara',
  'Damoh',
  'Datia',
  'Dewas',
  'Dhar',
  'Dindori',
  'Guna',
  'Gwalior',
  'Harda',
  'Hoshangabad',
  'Indore',
  'Jabalpur',
  'Jhabua',
  'Katni',
  'Khandwa',
  'Khargone',
  'Mandla',
  'Mandsaur',
  'Morena',
  'Narsinghpur',
  'Neemuch',
  'Panna',
  'Raisen',
  'Rajgarh',
  'Ratlam',
  'Rewa',
  'Sagar',
  'Satna',
  'Sehore',
  'Seoni',
  'Shahdol',
  'Shajapur',
  'Sheopur',
  'Shivpuri',
  'Sidhi',
  'Singrauli',
  'Tikamgarh',
  'Ujjain',
  'Umaria',
  'Vidisha',
];
export const Maharashtra = [
  'Ahmednagar',
  'Akola',
  'Amravati',
  'Aurangabad',
  'Beed',
  'Bhandara',
  'Buldhana',
  'Chandrapur',
  'Dhule',
  'Gadchiroli',
  'Gondia',
  'Hingoli',
  'Jalgaon',
  'Jalna',
  'Kolhapur',
  'Latur',
  'Mumbai City',
  'Mumbai Suburban',
  'Nagpur',
  'Nanded',
  'Nandurbar',
  'Nashik',
  'Osmanabad',
  'Palghar',
  'Parbhani',
  'Pune',
  'Raigad',
  'Ratnagiri',
  'Sangli',
  'Satara',
  'Sindhudurg',
  'Solapur',
  'Thane',
  'Wardha',
  'Washim',
  'Yavatmal',
];
export const Manipur = [
  'Bishnupur',
  'Chandel',
  'Churachandpur',
  'Imphal East',
  'Imphal West',
  'Jiribam',
  'Kakching',
  'Kamjong',
  'Kangpokpi',
  'Noney',
  'Pherzawl',
  'Senapati',
  'Tamenglong',
  'Tengnoupal',
  'Thoubal',
  'Ukhrul',
];
export const Meghalaya = [
  'East Garo Hills',
  'East Jaintia Hills',
  'East Khasi Hills',
  'North Garo Hills',
  'Ri Bhoi',
  'South Garo Hills',
  'South West Garo Hills ',
  'South West Khasi Hills',
  'West Garo Hills',
  'West Jaintia Hills',
  'West Khasi Hills',
];
export const Mizoram = [
  'Aizawl',
  'Champhai',
  'Kolasib',
  'Lawngtlai',
  'Lunglei',
  'Mamit',
  'Saiha',
  'Serchhip',
];
export const Nagaland = [
  'Dimapur',
  'Kiphire',
  'Kohima',
  'Longleng',
  'Mokokchung',
  'Mon',
  'Peren',
  'Phek',
  'Tuensang',
  'Wokha',
  'Zunheboto',
];
export const Odisha = [
  'Angul',
  'Balangir',
  'Balasore',
  'Bargarh',
  'Bhadrak',
  'Boudh',
  'Cuttack',
  'Deogarh',
  'Dhenkanal',
  'Gajapati',
  'Ganjam',
  'Jagatsinghapur',
  'Jajpur',
  'Jharsuguda',
  'Kalahandi',
  'Kandhamal',
  'Kendrapara',
  'Kendujhar (Keonjhar)',
  'Khordha',
  'Koraput',
  'Malkangiri',
  'Mayurbhanj',
  'Nabarangpur',
  'Nayagarh',
  'Nuapada',
  'Puri',
  'Rayagada',
  'Sambalpur',
  'Sonepur',
  'Sundargarh',
];
export const Puducherry = ['Karaikal', 'Mahe', 'Pondicherry', 'Yanam'];
export const Punjab = [
  'Amritsar',
  'Barnala',
  'Bathinda',
  'Faridkot',
  'Fatehgarh Sahib',
  'Fazilka',
  'Ferozepur',
  'Gurdaspur',
  'Hoshiarpur',
  'Jalandhar',
  'Kapurthala',
  'Ludhiana',
  'Mansa',
  'Moga',
  'Muktsar',
  'Nawanshahr (Shahid Bhagat Singh Nagar)',
  'Pathankot',
  'Patiala',
  'Rupnagar',
  'Sahibzada Ajit Singh Nagar (Mohali)',
  'Sangrur',
  'Tarn Taran',
];
export const Rajasthan = [
  'Ajmer',
  'Alwar',
  'Banswara',
  'Baran',
  'Barmer',
  'Bharatpur',
  'Bhilwara',
  'Bikaner',
  'Bundi',
  'Chittorgarh',
  'Churu',
  'Dausa',
  'Dholpur',
  'Dungarpur',
  'Hanumangarh',
  'Jaipur',
  'Jaisalmer',
  'Jalore',
  'Jhalawar',
  'Jhunjhunu',
  'Jodhpur',
  'Karauli',
  'Kota',
  'Nagaur',
  'Pali',
  'Pratapgarh',
  'Rajsamand',
  'Sawai Madhopur',
  'Sikar',
  'Sirohi',
  'Sri Ganganagar',
  'Tonk',
  'Udaipur',
];

export const Sikkim = [
  'Gangtok',
  'Mangan',
  'Namchi',
  'Gyalshing',
  'Pakyong',
  'Soreng',
];
export const TamilNadu = [
  'Ariyalur',
  'Chennai',
  'Coimbatore',
  'Cuddalore',
  'Dharmapuri',
  'Dindigul',
  'Erode',
  'Kanchipuram',
  'Kanyakumari',
  'Karur',
  'Krishnagiri',
  'Madurai',
  'Nagapattinam',
  'Namakkal',
  'Nilgiris',
  'Perambalur',
  'Pudukkottai',
  'Ramanathapuram',
  'Salem',
  'Sivaganga',
  'Thanjavur',
  'Theni',
  'Thoothukudi (Tuticorin)',
  'Tiruchirappalli',
  'Tirunelveli',
  'Tiruppur',
  'Tiruvallur',
  'Tiruvannamalai',
  'Tiruvarur',
  'Vellore',
  'Viluppuram',
  'Virudhunagar',
];
export const Telangana = [
  'Adilabad',
  'Bhadradri Kothagudem',
  'Hyderabad',
  'Jagtial',
  'Jangaon',
  'Jayashankar Bhoopalpally',
  'Jogulamba Gadwal',
  'Kamareddy',
  'Karimnagar',
  'Khammam',
  'Komaram Bheem Asifabad',
  'Mahabubabad',
  'Mahabubnagar',
  'Mancherial',
  'Medak',
  'Medchal',
  'Nagarkurnool',
  'Nalgonda',
  'Nirmal',
  'Nizamabad',
  'Peddapalli',
  'Rajanna Sircilla',
  'Rangareddy',
  'Sangareddy',
  'Siddipet',
  'Suryapet',
  'Vikarabad',
  'Wanaparthy',
  'Warangal (Rural)',
  'Warangal (Urban)',
  'Yadadri Bhuvanagiri',
];
export const Tripura = [
  'Dhalai',
  'Gomati',
  'Khowai',
  'North Tripura',
  'Sepahijala',
  'South Tripura',
  'Unakoti',
  'West Tripura',
];
export const Uttarakhand = [
  'Almora',
  'Bageshwar',
  'Chamoli',
  'Champawat',
  'Dehradun',
  'Haridwar',
  'Nainital',
  'Pauri Garhwal',
  'Pithoragarh',
  'Rudraprayag',
  'Tehri Garhwal',
  'Udham Singh Nagar',
  'Uttarkashi',
];
export const UttarPradesh = [
  'Agra',
  'Aligarh',
  'Allahabad',
  'Ambedkar Nagar',
  'Amethi (Chatrapati Sahuji Mahraj Nagar)',
  'Amroha (J.P. Nagar)',
  'Auraiya',
  'Azamgarh',
  'Baghpat',
  'Bahraich',
  'Ballia',
  'Balrampur',
  'Banda',
  'Barabanki',
  'Bareilly',
  'Basti',
  'Bhadohi',
  'Bijnor',
  'Budaun',
  'Bulandshahr',
  'Chandauli',
  'Chitrakoot',
  'Deoria',
  'Etah',
  'Etawah',
  'Faizabad',
  'Farrukhabad',
  'Fatehpur',
  'Firozabad',
  'Gautam Buddha Nagar',
  'Ghaziabad',
  'Ghazipur',
  'Gonda',
  'Gorakhpur',
  'Hamirpur',
  'Hapur (Panchsheel Nagar)',
  'Hardoi',
  'Hathras',
  'Jalaun',
  'Jaunpur',
  'Jhansi',
  'Kannauj',
  'Kanpur Dehat',
  'Kanpur Nagar',
  'Kanshiram Nagar (Kasganj)',
  'Kaushambi',
  'Kushinagar (Padrauna)',
  'Lakhimpur - Kheri',
  'Lalitpur',
  'Lucknow',
  'Maharajganj',
  'Mahoba',
  'Mainpuri',
  'Mathura',
  'Mau',
  'Meerut',
  'Mirzapur',
  'Moradabad',
  'Muzaffarnagar',
  'Pilibhit',
  'Pratapgarh',
  'RaeBareli',
  'Rampur',
  'Saharanpur',
  'Sambhal (Bhim Nagar)',
  'Sant Kabir Nagar',
  'Shahjahanpur',
  'Shamali (Prabuddh Nagar)',
  'Shravasti',
  'Siddharth Nagar',
  'Sitapur',
  'Sonbhadra',
  'Sultanpur',
  'Unnao',
  'Varanasi',
];
export const WestBengal = [
  'Alipurduar',
  'Bankura',
  'Birbhum',
  'Burdwan (Bardhaman)',
  'Cooch Behar',
  'Dakshin Dinajpur (South Dinajpur)',
  'Darjeeling',
  'Hooghly',
  'Howrah',
  'Jalpaiguri',
  'Kalimpong',
  'Kolkata',
  'Malda',
  'Murshidabad',
  'Nadia',
  'North 24 Parganas',
  'Paschim Medinipur (West Medinipur)',
  'Purba Medinipur (East Medinipur)',
  'Purulia',
  'South 24 Parganas',
  'Uttar Dinajpur (North Dinajpur)',
];
