import React, { useContext, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  notification,
  Typography,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword, Email } from '../../services/auth';
import './index.css';
import validatePassword from '../../utils/passwordValidator';
import { AuthContext } from '../../contexts/AuthProvider';
import { setLoggedInUserAction } from '../../actions/authAction';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;

function ResetPassword({ onCancel }) {
  const formRef = React.useRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoggedInUserAction({ token: searchParams.get('token') });
  }, []);

  const onFinish = (value) => {
    const getTokenFromUrl = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get('token');
    };
    const token = getTokenFromUrl();

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        const data = {
          orgId: decodedToken.orgId,
          userid: decodedToken.userid,
          password: value.password,
          confirmPassword: value.confirmPassword,
        };

        resetPassword(data)
          .then((res) => {
            notification.success({ message: res.data.message });
            navigate('/login');
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });

        // Email({
        //   templateId: 'PASSWORD_CHANGED_SUCCESSFULLY',
        //   // orgId: userConfig.orgId,
        //   // level: userConfig.level,
        //   // role: userConfig.role,
        // })
        //   .then((res) => {
        //     notification.success({ message: res.data.message });
        //   })
        //   .catch((err) => {
        //     notification.error({ message: err.response.data.message });
        //   });
      } catch (error) {
        console.error('Error decoding token:', error);
        // Handle error, maybe redirect to an error page
      }
    } else {
      // Token not found in URL, handle this case
      console.error('Token not found in URL');
      // Redirect to an appropriate page, maybe a login page
      // history.push('/login');
    }
  };
  return (
    <>
      <div className='change-password'>
        <Form layout='vertical' onFinish={onFinish} autoComplete='off'>
          <Title level={3}>Reset Password</Title>

          <Form.Item
            label='New Password'
            name='password'
            rules={[
              // { required: true },
              { validator: validatePassword },
            ]}>
            <Input.Password autoComplete='new-password' />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            rules={[
              // { required: true, message: "Enter Confirm Password" },
              { validator: validatePassword },
            ]}>
            <Input.Password autoComplete='new-password' />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default ResetPassword;
