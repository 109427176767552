import { Button, Col, Form, Input, Row, Space, DatePicker, Select } from 'antd';
import React, { useState } from 'react';

// import axios from "../../services/axiosInstance";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const { Option } = Select;

export default function OrganisationInfoForm({ initialValues, onNext }) {
  const onFinish = (values) => {
    onNext(values);
  };
  const formRef = React.useRef();

  React.useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
  }, [initialValues]);

  //on preview Usestate
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  //on Preview
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <Space
        direction='vertical'
        style={{ margin: '22px 10px', display: 'flex' }}>
        <Form
          name='orgOnboard'
          layout='vertical'
          initialValues={initialValues}
          onFinish={onFinish}
          ref={formRef}>
          <Row gutter={12}>
            <Col xs={24} lg={6}>
              <Form.Item
                label='Company Name'
                name='name'
                // rules={[
                //   { required: true, message: 'Enter Company Name' },
                //   {
                //     min: 3,
                //     message: 'Minimum length must be 3 characters',
                //   },
                //   {
                //     max: 90,
                //     message: 'Maximum length allowed is 90 characters',
                //   },
                //   {
                //     pattern: /^[A-Za-z\s-]*$/,
                //     message: 'Only Alphabetic Allowed',
                //   },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='Company Code'
                name='id'
                // rules={[
                //   { required: true, message: 'Enter Company Code' },
                //   {
                //     min: 3,
                //     message: 'Minimum length must be 3 characters',
                //   },
                //   {
                //     max: 90,
                //     message: 'Maximum length allowed is 90 characters',
                //   },
                //   {
                //     pattern: /^[a-zA-Z0-9]+$/,
                //     message: 'Enter Alpha Numeric Only',
                //   },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='Factory Licence Number'
                name='licenceNumber'
                // rules={[
                //   { required: true, message: 'Enter Factory Licence Number' },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} lg={6}>
              <Form.Item label='Validity Date' name='validity'>
                <DatePicker
                  style={{
                    width: '100%',
                    fontWeight: 'bolder',
                    color: 'darkgray',
                  }}
                  format='DD-MM-YYYY'
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='PAN of Establishment'
                name='pan'
                // rules={[{ required: true, message: 'Enter Pan' }]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='PF of Establishment'
                name='pf'
                // rules={[
                //   { required: true, message: 'Enter PF of establishment' },

                //   {
                //     pattern: /^[A-Z]{5}[0-9]{17}$/,
                //     message: 'Invalid PF Account number ',
                //   },
                // ]}
              >
                <Input
                  placeholder='Ex: BGBNG24718350000010018'
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='ESI of Establishment'
                name='esi'
                // rules={[
                //   { required: true, message: 'Enter Esi Number' },

                //   {
                //     pattern:
                //       '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$',
                //     message: 'Invalid ESIC number',
                //   },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                  placeholder='Ex: 41-00-123456-000-0001'
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\D/g, '');
                    if (value.length > 2) {
                      value = value.slice(0, 2) + '-' + value.slice(2);
                    }
                    if (value.length > 5) {
                      value = value.slice(0, 5) + '-' + value.slice(5);
                    }
                    if (value.length > 12) {
                      value = value.slice(0, 12) + '-' + value.slice(12);
                    }
                    if (value.length > 16) {
                      value = value.slice(0, 16) + '-' + value.slice(16);
                    }

                    formRef.current?.setFieldValue('esi', value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item label='Prof Tax Number' name='profTaxNumber'>
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label='Name Of Occupier'
                name='nameOfOccupier'
                // rules={[{ required: true, message: 'Enter Name Of Occupier' }]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} lg={6}>
              <Form.Item
                label='Name Of Factory Manager'
                name='nameOfFactoryManager'
                // rules={[
                //   { required: true, message: 'Enter Name Of Factory Manager' },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>{' '}
            {/* <Col xs={24} lg={6}>
              <Form.Item
                label='Department/Plant Name'
                name='departmentName'
                // rules={[
                //   { required: true, message: 'Enter Department/Plant Name' },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}></Input>
              </Form.Item>
            </Col>{' '}
            <Col xs={24} lg={6}>
              <Form.Item
                label='Department/Plant Code'
                name='departmentCode'
                // rules={[
                //   { required: true, message: 'Enter Department/Plant Code' },
                // ]}
              >
                <Select
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}>
                  <Option value='Option1'>Option1</Option>
                  <Option value='Option2'>Option2</Option>
                </Select>
              </Form.Item>
            </Col>{' '}
            <Col xs={24} lg={6}>
              <Form.Item
                label='Department/Plant Abbreviation'
                name='departmentAbbreviation'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Enter Department/Plant Abbreviation',
                //   },
                // ]}
              >
                <Input
                  disabled
                  style={{ fontWeight: 'bolder', color: 'darkgray' }}
                />
              </Form.Item>
            </Col>{' '} */}
          </Row>

          <Form.Item style={{ textAlign: 'end' }}>
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
}
