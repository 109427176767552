const Cwfm = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <iframe
        src='http://localhost:3001'
        style={{ minHeight: '98vh', minWidth: '98vw' }}
      />
    </div>
  );
};

export default Cwfm;
