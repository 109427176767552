import React, { useEffect, useRef, useState } from 'react';
import { Switch, Table, Typography, Modal, Row, Col, Button } from 'antd';
import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import axios from '../services/axiosInstance';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import LicenceManagementModal from './licenceManagementModal';
import { callCreateLicence } from '../services/auth';

const LicenceManagement = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const searchInput = useRef(null);

  useEffect(() => {
    getOrgList();
  }, []);

  const getOrgList = async () => {
    try {
      const response = await axios.get('/org?pageSize=100&pageNo=1');
      const res = response.data.orgList;

      setUserData(
        res.map((row) => ({
          key: row.id,
          id: row.id,
          _id: row._id,
          name: row.name,
          productLicenseKey: row.productLicenseKey,
          pf: row.pf,
          esi: row.esi,
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching org list:', error);
    }
  };

  const updateLicence = (features, expiry) => {
    const data = {
      orgId: selectedItemId?.key,
      features: features,
      expiry: expiry,
      status: 'active',
    };

    callCreateLicence(data)
      .then((res) => {
        setSelectedItemId('');
        getOrgList();
        setIsModalVisible(false);
      })
      .catch((error) => {});
  };
  const showModal = (id) => {
    setSelectedItemId(id);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {/* ... (unchanged code for the search input) */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // ... (unchanged code)
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),

      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.id.startsWith(value),
      // width: "30%",
      // sorter: (a, b) => a.id.length - b.id.length,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),

      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
      // width: "30%",
      // sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Product Licence Key',
      dataIndex: 'productLicenseKey',
      key: 'productLicenseKey',
      render: (item) => <span>{item ? item.substring(0, 30) : ''}....</span>,
    },
    {
      title: 'Action',
      // dataIndex: '_id',
      render: (record) => (
        <Typography>
          <Link onClick={() => showModal(record)}>
            <EyeFilled />
          </Link>
        </Typography>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      <h2>Principal Employer Organisation List</h2>
      <Table
        columns={columns}
        dataSource={userData}
        onChange={onChange}
        pagination={true}
      />

      {/* Modal for displaying details */}
      <LicenceManagementModal
        // title='Item Details'
        open={isModalVisible}
        onOk={updateLicence}
        onCancel={handleCancel}
        token={selectedItemId}
        footer={null}></LicenceManagementModal>
    </>
  );
};

export default LicenceManagement;
