import React, { useEffect, useState, useContext } from 'react';
import * as userService from '../services/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthProvider';
import { setLoggedInUserAction } from '../actions/authAction';
import { verifyToken } from '../utils/jwt';

const SamlResponse = () => {
  const [user, setUser] = React.useState({
    isAuthenticated: false,
    userConfig: {},
    tokenInfo: [],
  }); // Access setUser function from AuthContext
  const [queryParams, setQueryParams] = useState({}); // State to store query parameters
  const [redirect, setRedirect] = useState(false);
  const [isInfoLoading, setInfoLoading] = React.useState(true);
  const navigate = useNavigate();
  const { token } = useParams();
  //const history = useHistory();

  // Get the current URL

  const login = async (token, user) => {
    sessionStorage.setItem('token', token);
    await setLoggedInUserAction(user);
    await setUser({
      isAuthenticated: true,
      userConfig: user,
    });

    const roleFromWindow = user.role;

    if (user.role === 'admin') {
      // window.location.href = "http://localhost:4200/home/admin";
      window.location.href = 'https://vyn.wikiworks.in/home/admin';
    } else if (user.role === 'approver') {
      window.location.href = 'https://vyn.wikiworks.in/home/approver/list';
    } else if (user.role === 'ir') {
      window.location.href = 'https://vyn.wikiworks.in/home/ir';
    } else if (user.role === 'shop') {
      window.location.href = 'https://vyn.wikiworks.in/home';
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const splitted = currentUrl.split('?');
    const splittedItem = currentUrl.split('&');

    const tokenCurrent = splitted[2];

    const userCurrent = splitted[1];

    const userItem = {
      firstName: splittedItem[0],
      lastName: splittedItem[1],
      role: splittedItem[4].split('=')[1],
    };

    const urlParams = new URLSearchParams(window.location.search);

    const userItem1 = {
      firstName: urlParams.get('firstName'),
      lastName: urlParams.get('lastName'),
      userId: urlParams.get('userid'),
      emailId: urlParams.get('emailId'),
      role: urlParams.get('role'),
      nav: decodeURIComponent(urlParams.get('nav')),
      orgId: urlParams.get('orgId'),
      locationId: urlParams.get('locationId'),
      level: urlParams.get('level'),
      basePath: decodeURIComponent(urlParams.get('basePath')),
      userType: urlParams.get('userType'),
      supplierId: urlParams.get('supplierId'),
      isPasswordChanged: urlParams.get('isPasswordChanged'),
      _id: urlParams.get('_id'),
      roleName: urlParams.get('roleName'),
      location: urlParams.get('location'),
      permissions: urlParams.get('permissions'),
      token: urlParams.get('token'),
    };

    login(tokenCurrent, userItem);
  }, []);

  return (
    <div>
      <h1></h1>
    </div>
  );
};

export default SamlResponse;
