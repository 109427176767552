import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Popconfirm } from 'antd';
import MyDropdown from '../Components/MyDropdown';
import { getDetails, deleteRolePermission } from '../../services/auth';
import useAuth from '../../hooks/useAuth';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [object, setObject] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [sortedInfo, setSortedInfo] = React.useState({});

  const { userConfig } = useAuth();

  const orgId = userConfig.orgId;

  const handleDelete = async (selectedRoleType) => {
    try {
      // Send a request to delete the item with the specified selectedRoleType
      const response = await deleteRolePermission(selectedRoleType, orgId);

      // Check if the delete operation was successful
      if (
        response.data &&
        response.data.message === 'RolePermission deleted successfully'
      ) {
        const updatedData = data.filter(
          (item) => item.selectedRoleType !== selectedRoleType
        );
        setData(updatedData);
      } else {
      }
    } catch (error) {
      console.error('Error deleting role permission:', error);
    }
  };

  const handleClick = (selectedRoleType) => {
    setVisible(true);
    setObject(selectedRoleType);
  };

  const columns = [
    {
      title: 'Designation',
      dataIndex: 'selectedRole',
      key: 'Designation',
    },
    {
      title: 'Role Type',
      dataIndex: 'selectedRoleType',
      key: 'RoleType',
    },
    // {
    //   title: 'Permissions',
    //   key: 'tags',
    //   dataIndex: 'permissions',
    //   render: (_, { permissions }) => (
    //     <>
    //       {/* {permissions.map((permission) => (
    //         <Tag color="blue" key={permission}>
    //           {permission}
    //         </Tag>
    //       ))} */}
    //     </>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <a onClick={() => handleClick(record.selectedRoleType)}>Edit</a>
          <Popconfirm
            title='Are you sure you want to delete?'
            onConfirm={() => handleDelete(record.selectedRoleType)}
            okText='Yes'
            cancelText='No'
            placement='top'>
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        // Filter data based on specific roles

        const filteredData = response.data;

        setData(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={true} />

      {visible && (
        <MyDropdown
          fromDashBoard={true}
          closeModal={() => setVisible(false)}
          setDataFetchedParent={setDataFetched}
          Trole={object}
        />
      )}
    </>
  );
};

export default Dashboard;
