import { Outlet } from 'react-router-dom';
import AuthProvider from '../contexts/AuthProvider';

export default function GlobalContextLayout() {
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
}
