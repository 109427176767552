//     [2020] - [2024] Wikiworks Innovations Private Limited.
//  All Rights Reserved.

// NOTICE:  All information contained herein is, and remains
// the property of Wikiworks its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Wikiworks
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process and are protected by trade secret or copyright
// law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Wikiworks.
// """

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Form, Space, Table, Tooltip, Divider, Input } from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import DesignationIrFormModal from './DesignationIrFormModal';
import { getEmployeeListRR, searchEmployeeRR } from '../../services/auth';
import { getEmployeeList } from '../../services/auth';
import { setLoader } from '../../actions';
import dayjs from 'dayjs';
import { downloadEmployeeListRR } from '../../services/auth';
import Highlighter from 'react-highlight-words';

const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: '',
  roleName: '',
  location: '',
  userid: '',
  password: '',
  emailId: '',
};

/* Integrate ERP system for fetching reviewers profile based on PSN no.. @ Viresh Bangargi 31July2024 */

export default function IrDesignationForm({ locationsData, onNext, onPrev }) {
  const [Employee, setEmployee] = React.useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [loading, setloading] = useState(true);

  // Search icon
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState();
  const searchInput = useRef(null);
  const [pageNo, setpageNo] = useState(1);
  const [employementType, setemployementType] = useState('Permanent');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    EmployeeList(pageSize, currentPage);
  }, []);

  const { userConfig } = useAuth();

  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );

  const [editIndex, setEditIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);

  const [adminFormOpen, setAdminFormOpen] = React.useState(false);

  const openAdminModal = () => {
    setIsEdit(false);

    setFormInitialValue(DEFAULT_ADMIN_FORM_VALUE);
    setAdminFormOpen(true);
  };

  const addAdmin = (value, isEdit = false) => {
    if (isEdit) {
      setData((currData) => {
        return currData.map((d, index) => {
          if (index === editIndex) {
            //
            return value;
          }

          return d;
        });
      });

      setEditIndex(null);
    } else {
      setData((currData) => [...currData, value]);
    }
    setAdminFormOpen(false);
  };
  const edit = (record) => {
    setEditIndex(record.index);
    setIsEdit(true);

    setFormInitialValue(
      data?.find((d) => d?.userid === record.userid) || DEFAULT_ADMIN_FORM_VALUE
    );
    setAdminFormOpen(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let filteredData;
  if (userConfig.role === 'ir') {
    filteredData = Employee.filter((supplier) => {
      console.log('supplier119', supplier);
      return supplier?.role;
    });
  } else {
    filteredData = Employee;
  }

  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  const downloadExcel = () => {
    const url = `/pass/downloademployeelistRR`;
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadEmployeeListRR(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const structureEmployee = (row) => {
    console.log('row161', row);
    return {
      key: row?._id,
      id: row?.id,
      _id: row?._id,
      role: row?.role,
      firstName:
        row.personalDetails?.firstName + ' ' + row.personalDetails?.lastName,
      aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',
      psNumber: row?.jobDetails?.psNumber,
      departmentCode: row?.jobDetails?.departmentCode,
      status: row?.status
        ? row?.status[0]?.toUpperCase() + row?.status?.slice(1)
        : '',
      createdBy: row?.createdBy,
      EditedBy: row?.EditedBy ? row?.EditedBy : 'NA',
      employementType: row?.employementType,

      locationId: row?.locationId,
      empCode: row?.personalDetails?.empCode,
      approvalStage: row?.approvalStage,

      erdate: dayjs(row?.erdate)?.format('DD-MM-YYYY'), // Format erdate
      medicaldate: dayjs(row?.medicaldate)?.format('DD-MM-YYYY'), // Format medicaldate
      safetydate: dayjs(row?.safetydate)?.format('DD-MM-YYYY'), // Format safetydate
      supervisordate: row?.supervisordate
        ? dayjs(row?.supervisordate)?.format('DD-MM-YYYY')
        : 'NA', // Format supervisordate
    };
  };

  const EmployeeList = useCallback(
    async (pageSize, pageNo, employementType) => {
      setloading(true);
      try {
        const res = await getEmployeeListRR(pageSize, pageNo, employementType);
        setTotalCount(res?.data?.count);
        const employeeData = res?.data?.passList?.map((row) =>
          structureEmployee(row)
        );

        const sortedData = [...employeeData].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEmployee(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    },
    []
  );

  const SearchEmployeeData = useCallback(
    async (search) => {
      setloading(true);
      try {
        const res = await searchEmployeeRR(pageSize, pageNo, search);
        const employeeData = res.data.passList.map((row) =>
          structureEmployee(row)
        );
        const filteredData =
          userConfig.role === 'ir'
            ? employeeData.filter(
                (employee) => employee.employementType === 'Permanent'
              )
            : employeeData;
        setEmployee(filteredData);
        setTotalCount(res?.data?.count);
      } catch (error) {
        console.error(error);
      } finally {
        setloading(false);
      }
    },
    [pageSize, pageNo, userConfig.role]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Enter atleast 5 characters`}
          value={selectedKeys[0]}
          onChange={(e) => {
            if (e.target.value.length >= 5) {
              SearchEmployeeData(e.target.value);
            } else if (
              e.target.value.length === 0 ||
              e.target.value.length === 2
            ) {
              EmployeeList(pageSize, pageNo, employementType);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      width: '20%',
      editable: true,
    },
    // {
    //   title: 'Aadhaar Number',
    //   dataIndex: 'aadharNo',
    //   width: '20%',
    //   editable: true,
    // },
    {
      title: 'PS Number',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),

      filterMode: 'tree',
      filterSearch: true,
      sortOrder: sortedInfo.columnKey === 'psNumber' ? sortedInfo.order : null,
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Department Code',
      dataIndex: 'departmentCode',
      width: '20%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      editable: true,
    },

    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: '20%',

    //   render: (_, record) => {
    //     return (
    //       <Space>
    //         <Typography.Link
    //           disabled={
    //             userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
    //           }
    //           onClick={() => edit(record)}>
    //           <EditFilled />
    //           Edit
    //         </Typography.Link>
    //         {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
    //           <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
    //             <DeleteFilled />
    //             Delete
    //           </Typography.Link>
    //         </Popconfirm>  */}
    //       </Space>
    //     );
    //   },
    // },
  ];

  const Title = () => {
    return (
      <Form.Item>
        <Button
          type='dashed'
          onClick={openAdminModal}
          block
          // disabled={userConfig.roleName}
          icon={<PlusOutlined />}>
          Add Reviewers Role
        </Button>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    //

    onNext(data);
    // navigate('/home/admin');
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* showZero color="#faad14" size="large" */}
        <Button type='primary' onClick={downloadExcel}>
          <Tooltip title='Download Employee List'>
            <DownloadOutlined />
          </Tooltip>
        </Button>
      </div>

      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        {/* <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        title={Title}
        rowKey='userid'
      /> */}
        <Table
          columns={columns}
          dataSource={Employee}
          onChange={onChange}
          title={Title}
          rowKey='userid'
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount, // Replace with the actual total count of your data
            // total: Employee.some((emp) => emp.Role) ? totalCount : 0,
            onChange: async (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
              // Fetch data for the new page and pageSize
              EmployeeList(pageSize, page);
            },
          }}
        />
        <DesignationIrFormModal
          open={adminFormOpen}
          onOk={addAdmin}
          onCancel={() => setAdminFormOpen(false)}
          initialValues={formInitialValue}
          isEdit={isEdit}
          locationsData={locationsData}
        />

        {/* </Form> */}
      </Space>
    </>
  );
}
