import React, { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Select,
  Table,
  Row,
  Col,
  Form,
  Collapse,
  Space,
  Typography,
  Upload,
  notification,
  List,
} from 'antd';
import { Modal, Steps } from 'antd';
import { download } from '../services/auth';
import { EyeFilled, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getContractorOnboard, getCloudInfo } from '../services/auth';

import { epf } from '../services/auth';
import axios from '../services/axiosInstance';
import useAuth from '../hooks/useAuth';
import { encrypt } from '../utils/crypto';
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;
const { Step } = Steps;

function Pfreport() {
  const [modalVisible, setModalVisible] = useState(false);
  const [Employee, setEmployee] = useState([]);
  const [Employee1, setEmployee1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = React.useState({});
  const { userConfig } = useAuth();
  const [form] = Form.useForm();
  const [Request, setRequest] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [isExceptionRecord, setIsExceptionRecord] = useState(false);
  const [selectedDropdown1, setSelectedDropdown1] = useState('PDF');
  const [selectedDropdown2, setSelectedDropdown2] = useState(null);
  const [exceptionRecords, setExceptionRecords] = useState(false);
  const [excepEmployeeRecords, setExcepEmployeeRecords] = useState([]);
  const [noMatchRecords, setNoMatchRecords] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [fileExplorerVisible, setFileExplorerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const getPopupContainer = (triggerNode) => triggerNode.parentNode;

  const roleName = userConfig.roleName;

  const onKronosFileUpload = (file) => {
    form.setFieldsValue({ kronosFile: file });
  };

  const onPortalFileUpload = (file) => {
    form.setFieldsValue({ portalFile: file });
  };
  const { Item } = Form;
  React.useEffect(() => {
    if (selectedDropdown2 !== null) {
      EmployeeData();
    }
  }, [selectedDropdown2]);

  const onFinish = (value) => {
    const { kronosFile, portalFile, supplierId } = value;

    // Split the supplierId string at the comma
    const [supplierIdValue, supplierName] = supplierId.split(',');

    const formData = new FormData();
    formData.append('epfKronos', kronosFile.file);
    formData.append('epfPortal', portalFile.file);
    formData.append('orgId', userConfig.orgId);
    formData.append('supplierId', supplierIdValue); // Use "supplierIdValue" for the supplierId value
    formData.append('supplierName', supplierName); // Use "supplierName" for the supplierName value
    const dateString = portalFile.file.name.split('_')[1];

    if (!dateString) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }

    const date = getDate(dateString.slice(0, 8));
    if (!date) {
      notification.warning({ message: 'Plz upload valid PF_ECR file' });
    }
    formData.append('paymentDate', date);
    epf(formData)
      .then((res) => {
        const transactionId1 = res.data.transactionId;
        setRequest({ transactionId1 });
        notification.success({
          message: 'PF Exception Report successfully generated',
        });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    setModalVisible(true);
  };

  const getDate = (dateString) => {
    // Extract year, month, and day components
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(`${year}-${month}-${day}`).toISOString().split('T')[0];
  };
  function handleDropdown2Change(value) {
    const [supplierIdValue, supplierName] = value.split(',');

    const supplierId = value.split(' ')[1];
    setSelectedSupplier(supplierIdValue);
    setSelectedDropdown2(supplierIdValue);
    // ExceptionReport();
    // if (supplierId !== users.Response1[0].id) {
    //   EmployeeData();
    // }
  }
  const EmployeeData = async () => {
    const querydata = {
      orgId: userConfig.orgId,
      supplierId: selectedDropdown2,
      type: 'PF',
    };

    const decryptPayload = await encrypt(JSON.stringify(querydata));
    const Response = axios
      .get(`report/transactions?payload=${decryptPayload}`)
      .then((response) => {
        setEmployee1(
          response.data.data.map((row) => ({
            transactionId: row.transactionId,
            // timestamp:row.timestamp,
            // timestamp: dayjs(row.timestamp, "YYYY-MM-DD"),
            timestamp: dayjs(row.timestamp).format('DD-MM-YYYY'),
            kronosFileName: row.kronosFileName,
            portalFileName: row.portalFileName,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (Request.transactionId1) {
      const report = axios
        .get(`/report/transactions/${Request.transactionId1}`)
        .then((res) => {
          setReportData(res.data);

          setEmployee(
            res.data.data.report?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              Uannumber: row['UAN Number'],
              Ebwr: row['Emp Basic Pay per Wage Reg'],
              Ebec: row['Emp Basic Pay per ECR Challan'],
              Basicwagesdiff: row['Basic Wages Diff'],
              Eepfwr: row['EE PF per Wage Reg'],
              Eepfc: row['EE PF per ECR Challan'],
              EmployeeDiff: row['Employee PF Diff'],
              Epswr: row['EPS per Wage Reg'],
              Epsec: row['EPS per ECR Challan'],
              Epsdiff: row['EPS Diff'],
              Erpfwr: row['ER PF per Wage Reg'],
              Erpfwrc: row['ER PF per ECR Challan'],
              EmployerDiff: row['Employer PF Diff'],
              dueDate: res?.data?.data?.dueDate,
              isCompliant: res?.data?.data?.isCompliant,
            }))
          );
          const data = res.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((record) => ({
                empName1: record['Emp Name'],
                empCode1: record['Emp Code'],
                Uannumber1: record['UAN Number'],
                Ebwr1: record['Emp Basic Pay per Wage Reg'],
                Ebec1: record['Emp Basic Pay per ECR Challan'],
                Basicwagesdiff1: record['Basic Wages Diff'],
                Eepfwr1: record['EE PF per Wage Reg'],
                Eepfc1: record['EE PF per ECR Challan'],
                EmployeeDiff1: record['Employee PF Diff'],
                Epswr1: record['EPS per Wage Reg'],
                Epsec1: record['EPS per ECR Challan'],
                Epsdiff1: record['EPS Diff'],
                Erpfwr1: record['ER PF per Wage Reg'],
                Erpfwrc1: record['ER PF per ECR Challan'],
                EmployerDiff1: record['Employer PF Diff'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
              }))
          );

          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((record) => ({
                empName2: record['Emp Name'],
                empCode2: record['Emp Code'],
                Uannumber2: record['UAN Number'],
                dueDate: res?.data?.data?.dueDate,
                isCompliant: res?.data?.data?.isCompliant,
                // Ebwr1: record["Emp Basic Pay per Wage Reg"],
                // Ebec1: record["Emp Basic Pay per ECR Challan"],
                // Basicwagesdiff1: record["Basic Wages Diff"],
                // Eepfwr1: record["EE PF per Wage Reg"],
                // Eepfc1: record["EE PF per ECR Challan"],
                // EmployeeDiff1: record["Employee PF Diff"],
                // Epswr1: record["EPS per Wage Reg"],
                // Epsec1: record["EPS per ECR Challan"],
                // Epsdiff1: record["EPS Diff"],
                // Erpfwr1: record["ER PF per Wage Reg"],
                // Erpfwrc1: record["ER PF per ECR Challan"],
                // EmployerDiff1: record["Employer PF Diff"],
              }))
          );

          setTransactionId(res.data.transactionId);
          setModalVisible(true);
        })
        .catch((err) => {});
    }
  }, [Request.transactionId1]);

  React.useEffect(() => {
    EmployeeList();
    OrganizationInfo();
    CloudInfo();

    // ExceptionReport();
  }, []);

  const FileExplorer = ({ files, onFileSelect, onClose }) => {
    const handleFileChange = (file) => {
      setSelectedFile(file);
    };

    const handleUpload = () => {
      onFileSelect(selectedFile);
      onClose();
    };
    return (
      <Modal
        title='Cloud File Explorer'
        visible={true}
        onCancel={onClose}
        footer={[
          <Button key='back' onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={!selectedFile}
            onClick={handleUpload}>
            Upload
          </Button>,
        ]}>
        {/* Display files from prop using Ant Design List */}
        <List
          header={<div>Cloud Files</div>}
          bordered
          dataSource={files}
          renderItem={(file) => (
            <List.Item>
              <a href={file.link} target='_blank' rel='noopener noreferrer'>
                {file.name}
              </a>
            </List.Item>
          )}
        />
        {/* <Upload
          type="file"
          beforeUpload={() => false}
          onChange={(info) => handleFileChange(info.file)}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload> */}
      </Modal>
    );
  };

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  const PfClick = () => {
    window.open('https://unifiedportal-emp.epfindia.gov.in', '_blank');
  };

  const handleModalCancel = () => {
    // Hide the modal
    setModalVisible(false);
  };
  //Download Excel/Pdf
  function handleDropdown1Change(value) {
    setSelectedDropdown1(value);
  }

  // async function ExceptionReport() {
  //   const Response = axios.get(
  //     `/report/transactions?type=PF&orgId=${userConfig.orgId}&supplierId=${selectedDropdown2}&pageNo=1&pageSize=5`
  //   );
  // }

  const downloadFile = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'all',
      transactionId: Request.transactionId1 || transactionId,
      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const downloadFile1 = async (evt) => {
    evt.preventDefault();
    const url = `/report/download/${selectedDropdown1}`;
    const data = {
      reportType: 'exception',
      // transactionId: Request.transactionId1,
      transactionId: Request.transactionId1 || transactionId,

      selectedDropdown2,
      supplierId: selectedSupplier,
    };

    let responseType, fileExtension, contentType;
    if (selectedDropdown1 === 'PDF') {
      responseType = 'arraybuffer';
      fileExtension = 'pdf';
      contentType = 'application/pdf';
    } else if (selectedDropdown1 === 'Excel') {
      responseType = 'blob';
      fileExtension = 'xlsx';
      contentType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    axios
      .post(url, data, { responseType })
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function handleView(id) {
    setTransactionId(id);
    setModalVisible(true);
    setVisible(true);
    setLoading(true);
    axios
      .get(`/report/transactions/${id}`)
      .then((response) => {
        setReportData(response.data);

        setEmployee(
          response.data.data.report?.map((row) => ({
            empName: row['Emp Name'],
            empCode: row['Emp Code'],
            Uannumber: row['UAN Number'],
            Ebwr: row['Emp Basic Pay per Wage Reg'],
            Ebec: row['Emp Basic Pay per ECR Challan'],
            Basicwagesdiff: row['Basic Wages Diff'],
            Eepfwr: row['EE PF per Wage Reg'],
            Eepfc: row['EE PF per ECR Challan'],
            EmployeeDiff: row['Employee PF Diff'],
            Epswr: row['EPS per Wage Reg'],
            Epsec: row['EPS per ECR Challan'],
            Epsdiff: row['EPS Diff'],
            Erpfwr: row['ER PF per Wage Reg'],
            Erpfwrc: row['ER PF per ECR Challan'],
            EmployerDiff: row['Employer PF Diff'],
          }))
        );
        const data = response.data;
        setExceptionRecords(data.data.exceptionCount > 0);

        setExcepEmployeeRecords(
          data.data.report
            ?.filter((record) => record.isExceptionRecord)
            .map((record) => ({
              empName1: record['Emp Name'],
              empCode1: record['Emp Code'],
              Uannumber1: record['UAN Number'],
              Ebwr1: record['Emp Basic Pay per Wage Reg'],
              Ebec1: record['Emp Basic Pay per ECR Challan'],
              Basicwagesdiff1: record['Basic Wages Diff'],
              Eepfwr1: record['EE PF per Wage Reg'],
              Eepfc1: record['EE PF per ECR Challan'],
              EmployeeDiff1: record['Employee PF Diff'],
              Epswr1: record['EPS per Wage Reg'],
              Epsec1: record['EPS per ECR Challan'],
              Epsdiff1: record['EPS Diff'],
              Erpfwr1: record['ER PF per Wage Reg'],
              Erpfwrc1: record['ER PF per ECR Challan'],
              EmployerDiff1: record['Employer PF Diff'],
            }))
        );
        setNoMatchRecords(
          data.data.report
            ?.filter((record) => record.isNoMatch)
            .map((record) => ({
              empName2: record['Emp Name'],
              empCode2: record['Emp Code'],
              Uannumber2: record['UAN Number'],
              // Ebwr1: record["Emp Basic Pay per Wage Reg"],
              // Ebec1: record["Emp Basic Pay per ECR Challan"],
              // Basicwagesdiff1: record["Basic Wages Diff"],
              // Eepfwr1: record["EE PF per Wage Reg"],
              // Eepfc1: record["EE PF per ECR Challan"],
              // EmployeeDiff1: record["Employee PF Diff"],
              // Epswr1: record["EPS per Wage Reg"],
              // Epsec1: record["EPS per ECR Challan"],
              // Epsdiff1: record["EPS Diff"],
              // Erpfwr1: record["ER PF per Wage Reg"],
              // Erpfwrc1: record["ER PF per ECR Challan"],
              // EmployerDiff1: record["Employer PF Diff"],
            }))
        );

        // setData(response.data);
        setLoading(false);
        // setLoading(false);
        // EmployeeData();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  const handleDownload = (download) => {
    // download.preventDefault();
    axios
      .post(
        `/report/download/PDF`,
        {
          reportType: 'exception',
          transactionId: download,
          supplierId: selectedSupplier,
        },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      });
  };

  const columns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName',
      key: 'empName',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode',
      key: 'empCode',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber',
      key: 'Uannumber',
    },
    {
      title: 'Emp Basic Pay Per Wage Reg',
      dataIndex: 'Ebwr',
      key: 'Ebwr',
    },
    {
      title: 'Emp Basic Pay Per ECR Challan',
      dataIndex: 'Ebec',
      key: 'Ebec',
    },
    {
      title: 'Basic Wages Diff',
      dataIndex: 'Basicwagesdiff',
      key: 'Basicwagesdiff',
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'Eepfwr',
      key: 'Eepfwr',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'Eepfc',
      key: 'Eepfc',
    },
    {
      title: 'Employee PF Diff',
      dataIndex: 'EmployeeDiff',
      key: 'EmployeeDiff',
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'Epswr',
      key: 'Epswr',
    },
    {
      title: 'EPS per ECR Challan',
      dataIndex: 'Epsec',
      key: 'Epsec',
    },
    {
      title: 'EPS Diff',
      dataIndex: 'Epsdiff',
      key: 'Epsdiff',
    },
    {
      title: 'ER PF per Wage Reg',
      dataIndex: 'Erpfwr',
      key: 'Erpfwr',
    },
    {
      title: 'ER PF per ECR Challan',
      dataIndex: 'Erpfwrc',
      key: 'Erpfwrc',
    },
    {
      title: 'Employer PF Diff',
      dataIndex: 'EmployerDiff',
      key: 'EmployerDiff',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  const Exceptionscolumns = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName1',
      key: 'empName1',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode1',
      key: 'empCode1',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber1',
      key: 'Uannumber1',
    },
    {
      title: 'Emp Basic Pay Per Wage Reg',
      dataIndex: 'Ebwr1',
      key: 'Ebwr1',
    },
    {
      title: 'Emp Basic Pay Per ECR Challan',
      dataIndex: 'Ebec1',
      key: 'Ebec1',
    },
    {
      title: 'Basic Wages Diff',
      dataIndex: 'Basicwagesdiff1',
      key: 'Basicwagesdiff1',
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'Eepfwr1',
      key: 'Eepfwr1',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'Eepfc1',
      key: 'Eepfc1',
    },
    {
      title: 'Employee PF Diff',
      dataIndex: 'EmployeeDiff1',
      key: 'EmployeeDiff1',
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'Epswr1',
      key: 'Epswr1',
    },
    {
      title: 'EPS per ECR Challan',
      dataIndex: 'Epsec1',
      key: 'Epsec1',
    },
    {
      title: 'EPS Diff',
      dataIndex: 'Epsdiff1',
      key: 'Epsdiff1',
    },
    {
      title: 'ER PF per Wage Reg',
      dataIndex: 'Erpfwr1',
      key: 'Erpfwr1',
    },
    {
      title: 'ER PF per ECR Challan',
      dataIndex: 'Erpfwrc1',
      key: 'Erpfwrc1',
    },
    {
      title: 'Employer PF Diff',
      dataIndex: 'EmployerDiff1',
      key: 'EmployerDiff1',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (item, record) => {
        return <span>{item ? dayjs(item).format('YYYY-DD-MM') : ''}</span>;
      },
    },
    {
      title: 'Compliant',
      dataIndex: 'isCompliant',
      key: 'isCompliant',
      render: (item, record) => {
        return <span>{item ? 'compliant' : 'Not compliant'} </span>;
      },
    },
  ];
  const nomatchRecord = [
    {
      title: 'Workmen Name',
      dataIndex: 'empName2',
      key: 'empName2',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'empCode2',
      key: 'empCode2',
    },
    {
      title: 'UAN Number',
      dataIndex: 'Uannumber2',
      key: 'Uannumber2',
    },
  ];
  const column = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      editable: true,
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'Wage Register',
      dataIndex: 'kronosFileName',
      key: 'kronosFileName',
    },
    {
      title: 'Portal File',
      dataIndex: 'portalFileName',
      key: 'portalFileName',
    },
    {
      title: 'Actions',
      dataIndex: 'transactionId',
      render: (_, record) => (
        <Space>
          <Typography onClick={() => handleDownload(record.transactionId)}>
            <DownloadOutlined />
          </Typography>
          <Typography onClick={() => handleView(record.transactionId)}>
            <EyeFilled />
          </Typography>
        </Space>
      ),
    },
  ];

  const [principalEmployername, setprincipalEmployername] = useState(null);
  const [files, setFiles] = useState([]);

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const name = res.data.org.name;
      setprincipalEmployername(res.data.org.name);
    });
  };

  const CloudInfo = async () => {
    getCloudInfo().then((res) => {
      const data = res.data;
      setFiles(data);
    });
  };

  const openGoogleCloudStorageBucket = async () => {
    // Wait for the file upload to finish asynchronously
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Replace 'your_bucket_name' with the actual name of your Google Cloud Storage bucket
    const bucketName = process.env.BUCKET_NAME;

    // Open the bucket in the Google Cloud Storage Browser
    window.open(
      `https://console.cloud.google.com/storage/browser/${bucketName}`,
      '_blank'
    );
    // window.location.href = `https://console.cloud.google.com/storage/browser/${bucketName}`;
  };

  const openFileExplorer = () => {
    setFileExplorerVisible(true);
  };

  const closeFileExplorer = () => {
    setFileExplorerVisible(false);
  };

  const handleFileSelect = (file) => {
    // Handle the selected file (e.g., update state, perform actions)
  };

  const sampleFiles = [
    { id: 1, name: 'File 1.txt' },
    { id: 2, name: 'File 2.jpg' },
    // Add more files as needed
  ];

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Title level={4} style={{ color: 'green' }}>
          {principalEmployername}
        </Title>

        <Collapse style={{ fontWeight: 'bolder' }}>
          <Panel
            disabled={
              userConfig.roleName && !roleName?.permissions?.Compliance?.create
            }
            header='Validate PF'
            key='2'>
            <Row>
              {/* <Col span={6}>
                <Form.Item
                  label='Contractor Name'
                  name='supplierId'
                  rules={[
                    {
                      required: true,
                      message: "Select Contractor",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "200px" }}
                    onSelect={handleDropdown2Change}
                  >
                    {users.Response1?.map((option) => (
                      <Option
                        key={option.id}
                        label={option.name}
                        value={`${option._id}`}
                      >
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col span={6}>
                <Form.Item
                  label='Contractor Name'
                  name='supplierId'
                  rules={[
                    {
                      required: true,
                      message: 'Select Contractor',
                    },
                  ]}>
                  <Select
                    style={{ width: '200px' }}
                    onSelect={(value, option) =>
                      handleDropdown2Change(value, option.label)
                    }>
                    {users.Response1?.map((option) => (
                      <Option
                        key={option.id}
                        label={option.name}
                        value={`${option._id},${option.name}`}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label='PF Portal Link'>
                  <Button onClick={PfClick}>Go to PF Portal</Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label='Upload Wage Register'
                  name='kronosFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Upload
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    beforeUpload={() => false}
                    // fileList={kronosFile ? [kronosFile] : []}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status === 'done') {
                        onKronosFileUpload(info.file);
                      }
                    }}>
                    <Button icon={<UploadOutlined />}>
                      Upload Wage Register
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label='Cloud Upload'
                  name='kronosFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                    Upload Wage Register
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label='Upload PF-ECR file'
                  name='portalFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Upload
                    type='file'
                    accept='text/plain'
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status === 'done') {
                        onPortalFileUpload(info.file);
                      }
                    }}>
                    <Button icon={<UploadOutlined />}>
                      Upload PF-ECR file
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label=' Cloud Upload PF-ECR file'
                  name='portalFile'
                  rules={[{ required: true, message: 'Upload Document' }]}>
                  <Button onClick={openFileExplorer} icon={<UploadOutlined />}>
                    Upload PF-ECR file
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                // onClick={handleCompareButtonClick}
                type='primary'
                style={{ marginRight: '100px' }}
                htmlType='submit'>
                Validate Data
              </Button>
            </Row>
          </Panel>
        </Collapse>

        {fileExplorerVisible && (
          <FileExplorer
            files={files}
            onFileSelect={handleFileSelect}
            onClose={closeFileExplorer}
          />
        )}

        <Modal
          visible={modalVisible}
          onCancel={handleModalCancel}
          centered
          width='80%'
          footer={null}>
          <Steps current={currentStep}>
            <Step
              title={`All Employees ${reportData?.data?.allCount}`}
              onClick={() => setCurrentStep(0)}
            />
            <Step
              title={`Mismatched Data ${reportData?.data?.exceptionCount}`}
              onClick={() => setCurrentStep(1)}
            />
            <Step
              title={`No Match Found ${reportData?.data?.noMatchCount}`}
              onClick={() => setCurrentStep(2)}
            />
          </Steps>
          <Divider></Divider>
          {currentStep === 0 && (
            <div>
              <div>
                <Table
                  columns={columns}
                  dataSource={Employee}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: 1500 }}
                />
                <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
                  <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                    <Select
                      getPopupContainer={getPopupContainer}
                      style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        // width: "100px",
                        // height: "50px",
                      }}
                      onChange={handleDropdown1Change}
                      placeholder='PDF'>
                      <Option value='PDF'>PDF</Option>
                      <Option value='Excel'>Excel</Option>
                    </Select>
                    <Button
                      style={{ float: 'right', marginLeft: '10px' }}
                      type='primary'
                      onClick={(evt) => downloadFile(evt)}>
                      Download
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {currentStep === 1 && exceptionRecords && (
            <div>
              <Table
                dataSource={excepEmployeeRecords}
                columns={Exceptionscolumns}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 1500 }}
              />

              <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
                <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                  <Select
                    getPopupContainer={getPopupContainer}
                    style={{
                      fontWeight: 'bolder',
                      color: 'black',
                      // width: "100px",
                      // height: "50px",
                    }}
                    onChange={handleDropdown1Change}
                    placeholder='PDF'>
                    <Option value='PDF'>PDF</Option>
                    <Option value='Excel'>Excel</Option>
                  </Select>
                  <Button
                    style={{ float: 'right', marginLeft: '10px' }}
                    type='primary'
                    onClick={(evt) => downloadFile1(evt)}>
                    Download
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <Table
                dataSource={noMatchRecords}
                columns={nomatchRecord}
                pagination={{ pageSize: 5 }}
                // scroll={{ x: 1500 }}
              />
            </div>
          )}
        </Modal>
      </Form>
      <Divider></Divider>

      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        <Table
          bordered
          dataSource={Employee1}
          columns={column}
          // title={Title}
          rowKey='userid'
        />
      </Space>
    </>
  );
}

export default Pfreport;
