import React, { useState, useEffect } from 'react';

const DocumentPreview = ({ url, fileType }) => {
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    if (url) {
      if (fileType?.startsWith('image/')) {
        // Image preview
        setFileContent(
          <img
            alt='example'
            style={{ width: '100%', height: '400px' }}
            src={url}
          />
        );
      } else {
        // Document preview
        (async () => {
          setFileContent(
            <iframe
              src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
                url
              )}`}
              title={''}
              style={{ width: '100%', height: '400px', border: 'none' }}
            />
          );
        })();
      }
    }
  }, [url, fileType]);

  return <>{fileContent}</>;
};

export default DocumentPreview;
