import React, { useState } from 'react';
import { Table, Space, Typography, Modal } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import RegularModalContent from './RegularModalContent';

const Regular = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  function handleView(record) {
    setSelectedReport(record);
    setModalVisible(true);
  }
  const dataSource = [
    {
      key: '1',
      siNo: '1',
      name: 'Age Wise Workmen Report',
    },
    {
      key: '2',
      siNo: '2',

      name: 'Average Age And Experience Report',
    },
    {
      key: '3',
      siNo: '3',
      name: 'Cadre Wise Workman Report',
    },
    {
      key: '4',
      siNo: '4',

      name: 'Area/PC Wise Workmen Report',
    },
    {
      key: '5',
      siNo: '5',
      name: 'State Wise Workman Report',
    },
    {
      key: '6',
      siNo: '6',

      name: 'District Wise Workman Report',
    },
    {
      key: '7',
      siNo: '7',
      name: 'Department Wise Workman Report',
    },
    {
      key: '8',
      siNo: '8',

      name: 'Experience Wise Workman Report',
    },
    {
      key: '9',
      siNo: '9',
      name: 'Blood Group Wise Workman Report',
    },
    {
      key: '10',
      siNo: '10',

      name: 'Average Leave Report',
    },
    {
      key: '11',
      siNo: '11',
      name: 'Average Point Report',
    },
    {
      key: '12',
      siNo: '12',
      name: 'Disciplinary Action Report',
    },
    {
      key: '13',
      siNo: '13',
      name: 'Long Service & Retirement Report',
    },
  ];

  const columns = [
    {
      title: 'SI.No',
      dataIndex: 'siNo',
      key: 'siNo',
    },
    {
      title: 'Name Of Reports',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'key',
      render: (key) => (
        <Space>
          <Typography onClick={() => handleView(dataSource.find(item => item.key === key))}>
            <EyeFilled />
          </Typography>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} />
      <Modal
        title={selectedReport ? selectedReport.name : ''}
        visible={modalVisible}
        width='50%'
        onCancel={() => setModalVisible(false)}
        footer={null}>
        <RegularModalContent selectedReport={selectedReport} />
      </Modal>
    </div>
  );
};

export default Regular;

