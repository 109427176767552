import React, { useEffect } from 'react';
import { notification, Steps, Typography } from 'antd';
import OrganisationAdminForm from '../components/Contractor/OrganisationAdminForm';
import OrganisationInfoForm from '../components/Contractor/OrganisationInfoForm';
import OrganisationLocationForm from '../components/Contractor/OrganisationLocationForm';
import OrganizationSupervisorForm from '../components/Contractor/OrganizationSupervisorForm';
import OrganizationDesignationForm from '../components/Contractor/OrganizationDesignationForm';
import { getContractorOnboard, getDetails } from '../services/auth';
import { contractorOnboard } from '../services/auth';
import ContractorList from './ContractorList';
import { Approver } from '../services/auth';
import useAuth from '../hooks/useAuth';
import { Email } from '../services/auth';
import dayjs from 'dayjs';

const { Title } = Typography;

const ORG_INFO_DEFAULT = {
  name: '',
  id: '',
  aadharCheck: false,
  secureCheck: false,
  pf: '',
  esi: '',
  logoFile: [],
};

export default function ContractorOnboard() {
  const { user, userConfig } = useAuth();

  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [orgInfoData, setOrgInfoData] = React.useState(ORG_INFO_DEFAULT);
  const [orgAdminData, setOrgAdminData] = React.useState();
  const [orgDesignationData, setOrgDesignationData] = React.useState();

  const [orgSupervisorData, setOrgSupervisorData] = React.useState();

  const [locationsData, setLocationsData] = React.useState({ locations: [] });
  const [Tab, setTab] = React.useState('Principal Employer Details');
  //
  //
  const orgId = userConfig.orgId;
  const pageItems = [
    {
      key: 'orgInfo',
      title: 'Principal Employer Org Details',
    },
    {
      key: 'location',
      title: 'Location(s)',
    },
    {
      key: 'admin',
      title: 'Admin',
    },
    {
      key: 'designation',
      title: 'Designation',
    },
    {
      key: 'supervisor',
      title: 'Supervisor',
    },
  ];
  useEffect(() => {
    OrganizationInfo();
  }, []);

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const orgsup = res.data.org.orgUsers;

      const orgsup1 = orgsup.shift();

      const orgData = res.data.org;
      const orgDataLocation = res.data.org.locations;
      //

      // Approver
      // Approver().then((res) => {
      //   const Approver = res.data.approver;
      //   //
      // });

      let logoFile = [];
      if (orgData.logoFile) {
        logoFile.push({
          name: orgData.logoFile,
          thumbUrl: orgData.logoFileData,
        });
      }

      setOrgInfoData({
        name: orgData?.name,
        id: orgData?.id,
        // aadharCheck: orgData.aadharCheck,
        // secureCheck: orgData.secureCheck,
        licenceNumber: orgData?.licenceNumber,

        pf: orgData?.pf,
        esi: orgData?.esi,
        pan: orgData?.pan,
        profTaxNumber: orgData?.profTaxNumber,
        nameOfOccupier: orgData?.nameOfOccupier,
        nameOfFactoryManager: orgData?.nameOfFactoryManager,
        departmentName: orgData?.departmentName,
        departmentCode: orgData?.departmentCode,
        departmentAbbreviation: orgData?.departmentAbbreviation,
        validity: orgData?.validity ? dayjs(new Date(orgData?.validity)) : '',

        logoFile: logoFile,
      });
      //Sachin Code
      orgDataLocation.forEach((location, index) => {
        location.centralIRDocs = location?.documents?.centralIR;
        location.regularIRDocs = location?.documents?.regularIR;
        location.contractAgencyDocs = location?.documents?.contractAgency;
      });
      //------------
      setLocationsData({ locations: orgDataLocation }); // changes made by viresh for removing first Location

      // setLocationsData({ locations: orgDataLocation.slice(1) });

      setOrgAdminData(orgsup.map((d) => ({ ...d, id: d._id })));
      setOrgDesignationData(orgsup.map((d) => ({ ...d, id: d._id })));

      setOrgSupervisorData(orgsup.map((d) => ({ ...d, id: d._id })));

      // setLocationsData({Approver:Approver})
    });
  };

  const sendEmail = (emailData) => {
    return new Promise((resolve, reject) => {
      // Simulating sending an email (replace this with your actual email sending code)
      setTimeout(() => {
        // Simulate a 429 error
        const randomError = Math.random() < 0.5; // Simulate random 429 error
        if (randomError) {
          reject({ status: 429, message: 'Too Many Requests' });
        } else {
          resolve({ status: 200, message: 'Email sent successfully' });
        }
      }, 1000); // Simulate email sending delay
    });
  };
  const retrySendEmail = (emailData, retryCount = 0) => {
    const maxRetries = 3; // Maximum number of retries
    const baseDelay = 1000; // Base delay in milliseconds
    const maxDelay = 5000; // Maximum delay in milliseconds

    return new Promise((resolve, reject) => {
      sendEmail(emailData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.status === 429 && retryCount < maxRetries) {
            const delay = Math.min(
              baseDelay * Math.pow(2, retryCount),
              maxDelay
            );

            setTimeout(() => {
              retrySendEmail(emailData, retryCount + 1)
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            }, delay);
          } else {
            reject(error);
          }
        });
    });
  };

  const sendEmails = (data) => {
    const emailPromises = [];
    data?.orgUsers?.forEach((feature, index) => {
      // Add email sending logic for orgUsers here
      // (same as your existing logic)
      const delay = index * 1000; // Adjust the delay as needed
      emailPromises.push(
        new Promise((resolve) => {
          setTimeout(() => {
            // Add email sending logic here
            if (
              feature.role === 'supervisor' &&
              feature.password !== undefined
            ) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_SUPERVISOR',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: userConfig.level,
                  userId: feature.userid,
                  password: feature.password,
                  locationId: null,
                  correctRecipientEmail: feature.emailId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
            if (feature.role === 'admin' && feature.password !== undefined) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_ADMIN',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: userConfig.level,
                  userId: feature.userid,
                  password: feature.password,
                  locationId: null,
                  correctRecipientEmail: feature.emailId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
            if (
              feature.role === 'approver' &&
              feature.level === 1 &&
              feature.password !== undefined
            ) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_APPROVER1',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: feature.level,
                  userId: feature.userid,
                  password: feature.password,
                  // locationId: deg.id,
                  locationId: feature.locationId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
            // Add more conditions for other roles if needed
            if (
              feature.role === 'approver' &&
              feature.level === 2 &&
              feature.password !== undefined
            ) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_APPROVER2',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: feature.level,
                  userId: feature.userid,
                  password: feature.password,
                  // locationId: deg.id,
                  locationId: feature.locationId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
            if (
              feature.role === 'approver' &&
              feature.level === 3 &&
              feature.password !== undefined
            ) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_APPROVER3',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: feature.level,
                  userId: feature.userid,
                  password: feature.password,
                  // locationId: deg.id,
                  locationId: feature.locationId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
            if (
              feature.role === 'ir' &&
              feature.level === 'IR-Regular' &&
              feature.password !== undefined
            ) {
              resolve(
                Email({
                  workmenName: feature.firstName + ' ' + feature.lastName,
                  templateId: 'CREATE_IR',
                  // VYNApplication: 'https://demo.wikiworks.in/login',
                  VYNApplication: process.env.REACT_APP_URL,
                  orgId: userConfig.orgId,
                  role: feature.role,
                  level: feature.level,
                  userId: feature.userid,
                  password: feature.password,
                  // locationId: deg.id,
                  locationId: feature.locationId,
                })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                  })
                  .catch((err) => {
                    notification.error({
                      message: err.response.data.message,
                    });
                  })
              );
            }
          }, delay);
        })
      );
      const emailPromise = retrySendEmail(feature); // Assuming you have a function for sending individual emails
      emailPromises.push(emailPromise);
    });

    // Process locations after orgUsers
    const locationEmailPromises = [];
    data?.locations?.forEach((deg) => {
      deg.users.forEach((designation) => {
        // Add email sending logic for locations here
        // (same as your existing logic)
        if (
          designation.role === 'approver' &&
          designation.level === 1 &&
          designation.password !== undefined
        ) {
          locationEmailPromises.push(
            Email({
              workmenName: designation.firstName + ' ' + designation.lastName,
              templateId: 'CREATE_APPROVER1',
              // VYNApplication: 'https://demo.wikiworks.in/login',
              VYNApplication: process.env.REACT_APP_URL,
              orgId: userConfig.orgId,
              role: designation.role,
              level: designation.level,
              userId: designation.userid,
              password: designation.password,
              locationId: deg.id,
            })
              .then((res) => {
                notification.success({ message: res.data.message });
              })
              .catch((err) => {
                notification.error({
                  message: err.response.data.message,
                });
              })
          );
        }
        if (
          designation.role === 'approver' &&
          designation.level === 2 &&
          designation.password !== undefined
        ) {
          locationEmailPromises.push(
            Email({
              workmenName: designation.firstName + ' ' + designation.lastName,
              templateId: 'CREATE_APPROVER2',
              // VYNApplication: 'https://demo.wikiworks.in/login',
              VYNApplication: process.env.REACT_APP_URL,
              orgId: userConfig.orgId,
              role: designation.role,
              level: designation.level,
              userId: designation.userid,
              password: designation.password,
              locationId: deg.id,
            })
              .then((res) => {
                notification.success({ message: res.data.message });
              })
              .catch((err) => {
                notification.error({
                  message: err.response.data.message,
                });
              })
          );
        }
        if (
          designation.role === 'approver' &&
          designation.level === 3 &&
          designation.password !== undefined
        ) {
          locationEmailPromises.push(
            Email({
              workmenName: designation.firstName + ' ' + designation.lastName,
              templateId: 'CREATE_APPROVER3',
              // VYNApplication: 'https://demo.wikiworks.in/login',
              VYNApplication: process.env.REACT_APP_URL,
              orgId: userConfig.orgId,
              role: designation.role,
              level: designation.level,
              userId: designation.userid,
              password: designation.password,
              locationId: deg.id,
            })
              .then((res) => {
                notification.success({ message: res.data.message });
              })
              .catch((err) => {
                notification.error({
                  message: err.response.data.message,
                });
              })
          );
        }
        if (
          designation.role === 'ir' &&
          designation.level === 'IR-Regular' &&
          designation.password !== undefined
        ) {
          locationEmailPromises.push(
            Email({
              workmenName: designation.firstName + ' ' + designation.lastName,
              templateId: 'CREATE_IR',
              // VYNApplication: 'https://demo.wikiworks.in/login',
              VYNApplication: process.env.REACT_APP_URL,
              orgId: userConfig.orgId,
              role: designation.role,
              level: 'IR-Regular',
              userId: designation.userid,
              password: designation.password,
              locationId: deg.id,
            })
              .then((res) => {
                notification.success({ message: res.data.message });
              })
              .catch((err) => {
                notification.error({
                  message: err.response.data.message,
                });
              })
          );
        }
        // Add more conditions for other roles if needed
        const emailPromise = retrySendEmail(designation); // Assuming you have a function for sending individual emails
        locationEmailPromises.push(emailPromise);
      });
    });

    // Combine orgUser and location email promises
    const allEmailPromises = emailPromises.concat(locationEmailPromises);

    return Promise.all(allEmailPromises);
  };

  const submit = (_locationsData) => {
    const data = {
      ...orgInfoData,
      ...locationsData,
      // orgUsers: [...(orgDesignationData || []), ...(_locationsData || [])],
      orgUsers: [
        ...(orgDesignationData || []),
        ...(orgAdminData || []),
        ...(_locationsData || []),
      ],
    };

    if (orgInfoData.logoFile) {
      const fileList = orgInfoData.logoFile || [];
      if (fileList.length > 0) {
        data['logoFile'] = fileList[0].name;
        data['logoFileData'] = fileList[0].thumbUrl;
      } else {
        delete data['logoFile'];
      }
    }

    contractorOnboard(data)
      .then((res) => {
        notification.success({ message: res.data.message });
        return sendEmails(data); // Call the email sending function
      })
      .then(() => {})
      .catch((err) => {
        // notification.error({ message: err?.response?.data?.message });
      });
  };

  const next = (values) => {
    if (currentPageNo === 0) {
      //
      setTab('Locations Onboard');

      setOrgInfoData((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setLocationsData(values);
      setTab('Admin Onboard');

      // notification.error({ message: "Add atleast one Location" });

      // submit the data
    }

    if (currentPageNo === 2) {
      setOrgAdminData(values);
      setTab('Designation Onboard');

      // if (values.length === 0) {
      // notification.error({ message: 'Add atleast one contractor' });
      //   return;
      // }

      // submit(values);

      // return;
    }

    if (currentPageNo === 3) {
      setOrgDesignationData(values);
      setTab('Supervisor Onboard');

      // if (values.length === 0) {
      // notification.error({ message: 'Add atleast one contractor' });
      //   return;
      // }

      // submit(values);

      // return;
    }
    if (currentPageNo === 4) {
      setOrgSupervisorData(values);

      // if (values.length === 0) {
      //   notification.error({ message: 'Add atleast one Supervisor' });
      //   return;
      // }

      submit(values);

      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setTab('Principal Employer Details');

      setLocationsData(values);
    }

    if (currentPageNo === 2) {
      setTab('Location ');

      setOrgAdminData(values);
    }
    if (currentPageNo === 3) {
      setTab('Admin Onboard ');

      setOrgDesignationData(values);
    }
    if (currentPageNo === 4) {
      setTab('Designation Onboard');

      setOrgSupervisorData(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  

  return (
    <>
      <h2>{Tab}</h2>

      <Steps current={currentPageNo} items={pageItems} />
      {currentPageNo === 0 && (
        <OrganisationInfoForm initialValues={orgInfoData} onNext={next} />
      )}
      {currentPageNo === 1 && (
        <OrganisationLocationForm
          initialValues={locationsData}
          setLocationsData={setLocationsData}
          onNext={next}
          onPrev={prev}
          orgInfoData={orgInfoData}
          orgAdminData={orgAdminData}
        />
      )}
      {currentPageNo === 2 && (
        <OrganisationAdminForm
          initialValues={orgAdminData}
          locationsData={locationsData}
          onNext={next}
          onPrev={prev}
        />
      )}
      {currentPageNo === 3 && (
        <OrganizationDesignationForm
          initialValues={orgDesignationData}
          locationsData={locationsData}
          onNext={next}
          onPrev={prev}
        />
      )}

      {currentPageNo === 4 && (
        <OrganizationSupervisorForm
          initialValues={orgSupervisorData}
          locationsData={locationsData}
          onNext={next}
          onPrev={prev}
        />
      )}
    </>
  );
}
