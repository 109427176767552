import React, { useState, useEffect } from 'react';
import {
  Modal,
  Row,
  Input,
  Select,
  Col,
  Form,
  Typography,
  Button,
  notification,
  DatePicker,
  message,
} from 'antd';
import * as SupervisorServices from '../../services/supervisor';
import * as SupplierServices from '../../services/supplier';
import { useNavigate } from 'react-router-dom';
import {
  getWorkmenLimit,
  replaceWorkmen,
  getWorkmenList,
  getContractorOnboard,
  getsupervisor,
} from '../../services/auth';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
const { Option } = Select;
const { Title } = Typography;

export const ReplaceEmployee = ({
  open,
  onCancel,
  initialValues,
  contractorsList,
  contractorName,
  contractorCode,
}) => {
  const [supervisorList, setSupervisorList] = useState({
    current: [],
    replace: [],
  });

  const [supervisors, setSupervisors] = useState([]);

  console.log('contractorName45', contractorName);

  // const createdByValues = contractorsList.map((item) => item.createdBy);
  // const uniqueCreatedByValues = [...new Set(createdByValues)];

  //

  const [replaceContractors, setReplaceContractors] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState('');
  const [newContractorList, setNewContractorList] = useState([]);
  const [newContractorListId, setNewContractorListId] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [labourList, setLabourList] = useState([]);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [terminationDate, setTerminationDate] = useState(null);
  const [rehireDate, setRehireDate] = useState(null);
  // const [workmenId,setWorkmenId] = useState("");

  // setWorkmenId(initialValues);
  // //

  const [selectedSupervisor, setSelectedSupervisor] = useState({
    current: '',
    replace: '',
  });
  const { userConfig } = useAuth();
  const orgId = userConfig?.orgId;
  const navigate = useNavigate();

  useEffect(() => {
    supervisorInfo();
  }, []);

  const supervisorInfo = async () => {
    getsupervisor(orgId).then((res) => {
      //
      const uniqueSupervisorList = res.data?.supervisorList;
      //

      if (Array.isArray(uniqueSupervisorList)) {
        const uniqueSupervisorSupplierId = uniqueSupervisorList
          ?.map((supervisor) => {
            if (supervisor.role === 'supervisor')
              return {
                Supervisor: supervisor.userid,
                contractorId: supervisor.supplierId,
              };
          })
          .filter(Boolean);
        //
        setSupervisors(uniqueSupervisorSupplierId);
      }
    });
  };

  const passList = async () => {
    getWorkmenList(orgId).then((res) => {
      const uniqueWorkmenList = res?.data?.data?.workers;

      // Check if uniqueWorkmenList is an array
      if (Array.isArray(uniqueWorkmenList)) {
        // Assuming 'id' is a property that makes each object unique
        const uniqueWorkmenListIds = Array.from(
          new Set(uniqueWorkmenList?.map((workman) => workman.ContractorCode))
        );

        // Creating a new array with unique objects based on 'id'
        const uniqueWorkmenObjects = uniqueWorkmenListIds.map(
          (ContractorCode) =>
            uniqueWorkmenList.find(
              (workman) => workman.ContractorCode === ContractorCode
            )
        );

        setLabourList(uniqueWorkmenObjects);
      } else {
        console.error('Unexpected data structure. Expected an array.');
      }
    });
  };

  const formRef = React.useRef();
  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };
  const getSuperVisorList = (contractorId, status) => {
    setSelectedSupervisor({ ...selectedSupervisor, replace: '' });
    formRef.current?.setFieldValue('supervisorName', '');
    SupervisorServices.getSuperVisorListForContractor(contractorId).then(
      (res) => {
        setSupervisorList({
          ...supervisorList,
          [status]: res?.data?.supervisorList || [],
        });
      }
    );
  };
  React.useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);

    const replaceContractorsList = contractorsList.filter(
      (contractor) => contractor.id !== initialValues.id
    );
    setReplaceContractors(replaceContractorsList);
    getSuperVisorList(initialValues.id, 'current');
  }, [initialValues]);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        const newArr = res.data.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item.id,
              name: item.name,
            };
          }
        });

        setSeperatedVariables(newArr);

        // const contractorIds = res.data.map((item) => {
        //   return item.id;
        // });
        const contractorNames = newArr?.map((item) => {
          return item.name;
        });

        setNewContractorList(contractorNames);
        // setNewContractorListId(contractorIds);
      })
      .catch((error) => {
        // setLoader(false);
      });
  };

  useEffect(() => {
    workmenLimit();
    passList();
  }, []);

  const handleContractorChange = (e) => {
    setSelectedContractor(e);
    getSuperVisorList(e, 'replace');
  };

  const onFinish = () => {
    // Get the values of the form fields
    const formValues = formRef.current.getFieldsValue();

    // Extract the values you need from the formValues object

    // Get the values of the form fields

    // Extract the values you need from the formValues object
    const {
      newContractorName,
      newContractorId,
      supervisorName,
      dateOfTermination,
      dateOfRehire,
    } = formValues;

    // You can now use these values as part of your payload
    let payload = {
      _id: initialValues,
      contractorName: newContractorName,
      contractorCode: newContractorId,
      createdBy: supervisorName,
      dateOfTermination: dateOfTermination,
      dateOfRehire: dateOfRehire,
    };

    const replaceWorkmenContractor = async () => {
      try {
        const res = await replaceWorkmen(payload);

        // Assuming res.data.message is the property containing the message
        const messageFromServer = res.data.message;
        //

        // Display the message using Ant Design message component
        message.success(messageFromServer);
        navigate('/home/approver/list');
      } catch (error) {
        // Handle errors appropriately
        console.error('Error replacing workmen:', error);
        message.error('Error replacing workmen. Please try again.');
      }
    };
    replaceWorkmenContractor();
  };

  // const onFinish = () => {
  //   // if (
  //   //   !initialValues.id ||
  //   //   !selectedSupervisor.current ||
  //   //   !selectedContractor ||
  //   //   !selectedSupervisor.replace
  //   // ) {
  //   //   return 0;
  //   // }
  //   // let payload = {
  //   //   contractorId: initialValues.id,
  //   //   supervisorId: selectedSupervisor.current,
  //   //   replaceContractorId: selectedContractor,
  //   //   replaceSupervisorId: selectedSupervisor.replace,
  //   // };
  //   // SupplierServices.replaceSupplier(payload).then((res) => {
  //   //   notification.success({ message: "Contractor replaced Succesfully" });
  //   //   onCancel();
  //   // });
  // };

  // const footer = () => {
  //   return [
  //     <Button key='back' onClick={onCancel}>
  //       cancel
  //     </Button>,
  //     <Button key='submit' type='primary' onClick={replaceContractor}>
  //       Replace
  //     </Button>,
  //   ];
  // };

  //
  //

  const handleCreatedByChange = (value) => {
    setSelectedCreatedBy(value);
  };

  const handleContractorNameChange = (value) => {
    const selectedContractor = separatedVariables.find(
      (item) => item?.name === value
    );
    //
    let newId = [selectedContractor.id];
    if (selectedContractor) {
      // Update the array by appending the new contractor ID
      setNewContractorListId([selectedContractor.id]);
      // You can add additional logic here if needed
    }

    const labourRecord = supervisors?.find((labour) => {
      console.log(
        'Comparing with labour.contractorName:',
        labour.contractorName
      );
      return labour.contractorId === newId[0];
    });

    if (labourRecord) {
      setSelectedCreatedBy([labourRecord.Supervisor]);
    } else {
      setSelectedCreatedBy([]);
      alert(
        'No supervisor found. Please add a supervisor to replace the workmen.'
      );
    }
  };
  //

  const handleTerminationDateChange = (date) => {
    setTerminationDate(date);
    setRehireDate(null);
  };

  const handleRehireDateChange = (date) => {
    if (terminationDate && date.isBefore(terminationDate)) {
      message.error('Date of Rehire must be greater than Date of Termination');
    } else {
      setRehireDate(date);
    }
  };

  // Disable dates before the termination date
  const disabledDate = (current) => {
    // Check if dateOfTermination is available
    if (terminationDate) {
      // Implement your logic for disabling dates based on dateOfTermination
      // Example: Disable dates before dateOfTermination
      return current && current.isBefore(terminationDate.add(1, 'day'), 'day');
    }

    // Handle the case where dateOfTermination is null
    return false; // Or return true/false based on your requirements
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={_onCancel}
        title='Transfer Workman'
        // footer={footer()}
        // onChange={handleContractorChange}
        footer={null}>
        <Form
          layout='vertical'
          initialValues={initialValues}
          ref={formRef}
          onFinish={onFinish}>
          {/* <h3>Current Contractor</h3> */}

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label=' Current Contractor Name'
                name={contractorName}
                // rules={[{ required: true, message: "Select Name " }]}
              >
                <Input placeholder={contractorName} disabled></Input>
              </Form.Item>
              <Form.Item
                label=' Current Contractor Id'
                name={contractorCode}
                // rules={[{ required: true, message: "Select Name " }]}
              >
                <Input placeholder={contractorCode} disabled></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='New Contractor Name'
                name='newContractorName'
                rules={[
                  { required: true, message: 'Select New Contractor Name' },
                ]}>
                <Select
                  // defaultValue={selectedSupervisor.current}
                  onChange={(value) => handleContractorNameChange(value)}>
                  {newContractorList.map((contractorName) => {
                    return (
                      <Option value={contractorName}>{contractorName}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label='New Contractor Id'
                name='newContractorId'
                rules={[
                  { required: true, message: 'Select New Contractor ID' },
                ]}>
                <Select
                // defaultValue={selectedContractorId}
                // Remove onChange if not needed for separate handling
                >
                  {Array.isArray(newContractorListId)
                    ? newContractorListId.map((contractorId) => (
                        <Option key={contractorId} value={contractorId}>
                          {contractorId}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} offset={12}>
              <Form.Item
                label='New Supervisor Name'
                name='supervisorName'
                rules={[{ required: true, message: 'Select Supervisor Name' }]}>
                <Select
                  value={selectedCreatedBy}
                  // onChange={handleCreatedByChange}
                  style={{ width: '200px' }}>
                  {selectedCreatedBy?.map((createdByValue, index) => (
                    <Option key={index} value={createdByValue}>
                      {createdByValue}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <h3>Replace Contractor</h3> */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label='Date Of termination'
                name='dateOfTermination'
                rules={[
                  { required: true, message: 'Select Termination Date ' },
                ]}>
                <DatePicker
                  onChange={handleTerminationDateChange}
                  style={{ width: '100%' }}
                  format='DD-MM-YYYY'></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Date Of Rehire'
                name='dateOfRehire'
                rules={[{ required: true, message: 'Select Rehire Date' }]}>
                <DatePicker
                  // onChange={handleRehireDateChange}
                  disabledDate={disabledDate}
                  style={{ width: '100%' }}
                  format='DD-MM-YYYY'></DatePicker>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item style={{ marginLeft: '10px' }}>
                  <Button key='back' onClick={onCancel}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginLeft: '10px' }}>
                  <Button key='submit' type='primary' htmlType='submit'>
                    Replace
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ReplaceEmployee;
