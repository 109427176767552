import React from "react";
import ResetPassword from "../../components/ResetPassword/resetPassword";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPassword />
    </>
  );
};

export default ResetPasswordPage;
