import React from 'react';
import AgeWiseWorkmanReport from './AgeWiseWorkmanReport';
import AverageAgeAndExperienceReport from './AverageAgeAndExperienceReport';
import CadreWiseWorkmanReport from './CadreWiseWorkmanReport';
import StateWiseWorkmanReport from './StateWiseWorkmanReport';
import DistrictWiseWorkmanReport from './DistrictWiseWorkmanReport';
import DepartmentWiseWorkmanReport from './DepartmentWiseWorkmanReport';
import ExperienceWiseWorkmanReport from './ExperienceWiseWorkmanReport';
import BloodGroupWiseWorkmanReport from './BloodGroupWiseWorkmanReport';
import AreaWiseWorkmenReport from './AreaWiseWorkmenReport';
import AverageLeaveReport from './AverageLeaveReport';
import AveragePointReport from './AveragePointReport';
import DisciplinaryActionReport from './DisciplinaryActionReport';
import LongServiceRetirementReport from './LongServiceRetirementReport';

const RegularModalContent = ({ selectedReport }) => {
  if (!selectedReport) return null;
  const reportKey = selectedReport.key;

  switch (reportKey) {
    case '1':
      return <AgeWiseWorkmanReport />;
    case '2':
      return <AverageAgeAndExperienceReport />;
    case '3':
      return <CadreWiseWorkmanReport />;
    case '4':
      return <AreaWiseWorkmenReport />;
    case '5':
      return <StateWiseWorkmanReport />;
    case '6':
      return <DistrictWiseWorkmanReport />;
    case '7':
      return <DepartmentWiseWorkmanReport />;
    case '8':
      return <ExperienceWiseWorkmanReport />;
    case '9':
      return <BloodGroupWiseWorkmanReport />;
    case '10':
      return <AverageLeaveReport />;
    case '11':
      return <AveragePointReport />;
    case '12':
      return <DisciplinaryActionReport />;
    case '13':
      return <LongServiceRetirementReport />;
    default:
      return null;
  }
};

export default RegularModalContent;
