import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Table,
  Button,
  Select,
  Popconfirm,
  Typography,
  Divider,
  notification,
  Modal,
} from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import DocumentFormTable from './DocumentFormTable';
import { State } from '../../JSON/data';
import { getDetails, getExcelData, psnNumber } from '../../services/auth';

const { Option } = Select;
const { Title } = Typography;

export default function OrganisationLocationPanel({
  field,
  index,
  orgInfoData,
  initialValues,
  sendDataToParent,
  approversList,
  panelIdloc,
  LocationsData,
  formRef,
  tempId,
  exceptions,
  setExceptions,
  checkedList,
  setCheckedList,
  disabled,
}) {
  // const formRef = React.useRef();
  useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
  }, [initialValues]);
  //

  //

  //const currentValues = formRef.current?.getFieldsValue();
  //
  //

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [rolesDefault, setRolesDefault] = React.useState([
    'IR-Contract',
    'Medical Officer',
    'Safety Officer',
    'Shop Head',
    'Shop Incharge',
    'Shop Coordinator',
    'Shop Supervisor',
    'Engineer In Charge',
    'IR-Regular',
  ]);
  //const [checkedList, setCheckedList] = React.useState([]);
  const [selectedApprovers, setSelectedApprovers] = React.useState([]);

  const [visible, setVisible] = React.useState(false);
  const [masterDataShop, setMasterDataShop] = React.useState([]);
  const [workerType, setWorkerType] = React.useState([]);
  const [checkedListReal, setCheckedListReal] = React.useState([]);
  const { Option } = Select;
  const [masterData, setMasterData] = React.useState([]);
  const [getExcelCalled, setGetExcelCalled] = React.useState(false);

  const [panelId, setPanelId] = React.useState('');
  const checkboxesRef = useRef({});

  const handleCheckboxChange = (area, checked) => {
    //
    // setExceptions(prevExceptions => ({
    //   ...prevExceptions,
    //   [selectedApprovers]: checked
    //     ? [...(prevExceptions[selectedApprovers] || []), area]
    //     : (prevExceptions[selectedApprovers] || []).filter(item => item !== area)
    // }));
    setExceptions((prevExceptions) => ({
      ...prevExceptions,
      [tempId]: {
        ...prevExceptions[tempId],
        [selectedApprovers]: checked
          ? [...(prevExceptions[tempId]?.[selectedApprovers] || []), area]
          : (prevExceptions[tempId]?.[selectedApprovers] || []).filter(
              (item) => item !== area
            ),
      },
    }));
  };

  //

  sendDataToParent(exceptions);
  approversList(checkedList);
  // panelIdloc(panelId)

  //

  //

  const handleApproverChange = (value) => {
    // Get the exceptions for the previously selected approver
    const prevExceptions = exceptions[selectedApprovers] || [];

    // Deselect the checkboxes for the previously selected approver
    prevExceptions.forEach((area) => {
      checkboxesRef.current[area].checked = false;
    });

    // Set the selected approver and update exceptions
    setSelectedApprovers(value);
    // setExceptions({
    //   ...exceptions,
    //   [selectedApprovers]: prevExceptions,
    // });
    setExceptions((prevExceptions) => ({
      ...prevExceptions,
      [tempId]: {
        ...prevExceptions[tempId],
        [selectedApprovers]: prevExceptions[tempId]?.[selectedApprovers] || [],
      },
    }));
  };

  //original
  const onChangeApproval = (value, checked) => {
    setCheckedList((prevCheckedList) => {
      if (checked) {
        if (prevCheckedList.hasOwnProperty(tempId)) {
          return {
            ...prevCheckedList,
            [tempId]: [...prevCheckedList[tempId], value],
          };
        } else {
          return {
            ...prevCheckedList,
            [tempId]: [value],
          };
        }
      } else {
        return {
          ...prevCheckedList,
          [tempId]: prevCheckedList[tempId].filter((item) => item !== value),
        };
      }
    });
  };

  //   const onChangeApproval = (value, checked) => {
  //     setCheckedList(prevCheckedList => {
  //         // Filter out any existing tempId entries
  //         const filteredList = prevCheckedList.filter(item => !Array.isArray(item) || !item.some(obj => 'tempId' in obj));

  //         // Add the new tempId entry at the beginning of the array
  //         const updatedList = [[{ tempId }], ...filteredList];

  //         if (checked) {
  //             // If checkbox is checked, add its value to the checkedList array
  //             return [...updatedList, value];
  //         } else {
  //             // If checkbox is unchecked, remove its value from the checkedList array
  //             return updatedList.filter(item => item !== value);
  //         }
  //     });
  // };

  //   const onChangeApproval = (value, checked) => {
  //     setCheckedList(prevCheckedList => {
  //         if (checked) {
  //             // If checkbox is checked, add its value to the checkedList array
  //             return {
  //                 ...prevCheckedList,
  //                 [tempId]: [...(prevCheckedList[tempId] || []), { value }]
  //             };
  //         } else {
  //             // If checkbox is unchecked, remove its value from the checkedList array
  //             const updatedList = { ...prevCheckedList };
  //             if (updatedList[tempId]) {
  //                 updatedList[tempId] = updatedList[tempId].filter(item => item.value !== value);
  //             }
  //             return updatedList;
  //         }
  //     });
  // };
  //
  const [empData, setEmpData] = useState(null); // State to hold employee data

  const { userConfig } = useAuth();
  //
  const orgId = userConfig.orgId;
  const states = State;
  const [form] = Form.useForm();

  const roles = [
    { value: 'approver', label: 'Approver' },
    { value: 'shop', label: 'Shop' },
    { value: 'ir', label: 'IR' },
  ];

  // Define a function to capture the panelId
  const capturePanelId = (panelId) => {
    setPanelId(panelId);
    //
    // Perform any desired logic here with the captured panelId
  };

  //
  // Outside the component
  const handleRoleChange = (index, selectedRole) => {
    const levels = [
      { value: 1, label: 'IR-Contract' },
      // { value: 2, label: 'Medical Officer' },
      // { value: 3, label: 'Safety Officer' },
      { value: 2, label: 'Safety Officer' },
      { value: 3, label: 'Medical Officer' },
    ];

    const shopOptions = [
      { value: 'head', label: 'Head' },
      { value: 'incharge', label: 'Incharge' },
      { value: 'coordinator', label: 'Coordinator' },
      { value: 'supervisor', label: 'Supervisor' },
    ];

    const IrLevels = [{ value: 'IR-Regular', label: 'IR-Regular' }];

    // Check the selected role and set the variable accordingly
    if (selectedRole === 'approver') {
      setSelectedOptions(levels);
    } else if (selectedRole === 'shop') {
      setSelectedOptions(shopOptions);
    } else if (selectedRole === 'ir') {
      setSelectedOptions(IrLevels);
    } else {
      // Handle other roles or set a default value if needed
      setSelectedOptions([]);
    }

    //

    // You can use the selectedOptions variable for further logic or set it to the component state
  };

  React.useEffect(() => {
    //
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (
              item.selectedRole === 'Approver' ||
              item.selectedRole === 'Shop' ||
              item.selectedRole === 'IR'
            ) {
              // If it is "Approver," return the modified item or just the property you need
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        //

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  // useEffect(() => {
  //   form.setFieldsValue({
  //     firstName: empData?.personalDetails.firstName,
  //     userid: form.getFieldValue('psNumber'),
  //   });
  // });

  const handleShowModal = (panelId) => {
    //
    //
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  /** @type {import("antd/es/table").ColumnsType} */
  // const columns = [
  //   {
  //     title: 'PS Number *',
  //     dataIndex: 'psNumber',
  //     width: '12%',
  //     render: (_, record, i) => {
  //       const handleInputChange = async (value) => {
  //         form.setFieldsValue({
  //           firstName: 'Viresh',
  //           userid: 'Bangargi',
  //         });
  //         psnNumber(value)
  //           .then((res) => {
  //
  //             setEmpData('Viresh');
  //             form.setFieldValue('firstName', 'Viresh');

  //             // formRef.current?.setFieldsValue([
  //             //   'firstName',
  //             //   field.name,
  //             //   'lastName',
  //             // ]);
  //             // formRef.setFieldsValue({
  //             //   firstName: empData?.personalDetails.firstName,
  //             //   userid: form.getFieldValue('psNumber'),
  //             // });

  //             notification.success({ message: res.data.message });
  //           })
  //           .catch((err) => {
  //             notification.error({ message: err.response.data.message });
  //           });
  //       };

  //       return (
  //         <Form.Item
  //           name={[record.name, 'psNumber']}
  //           rules={[{ required: true, message: 'Enter PS Number' }]}>
  //           <Input onChange={(e) => handleInputChange(e.target.value)} />
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'First Name *',
  //     dataIndex: 'firstName',
  //     key: 0,
  //     width: '12%',
  //     render: (_, record, i) => {
  //       //
  //       return (
  //         <Form.Item
  //           name={[record.name, 'firstName']}
  //           rules={[{ required: true, message: 'Enter First Name' }]}>
  //           <Input />
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Last Name *',
  //     dataIndex: 'lastName',
  //     width: '12%',
  //     key: 1,
  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[record.name, 'lastName']}
  //           rules={[{ required: true, message: 'Enter Last Name' }]}>
  //           <Input />
  //         </Form.Item>
  //       );
  //     },
  //   },

  //   {
  //     title: 'Role',
  //     dataIndex: 'role',
  //     width: '10%',
  //     key: 2,
  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[i, 'role']}
  //           rules={[{ required: true, message: 'Select Role' }]}>
  //           <Select
  //             onChange={(value) => {
  //               form.setFieldsValue({
  //                 [i]: { level: undefined, roleName: undefined },
  //               });
  //               handleRoleChange(i, value);
  //             }}>
  //             {roles?.map((role) => (
  //               <Option key={role.value} value={role.value}>
  //                 {role.label}
  //               </Option>
  //             ))}
  //           </Select>
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: '  Levels *',
  //     dataIndex: 'level',
  //     width: '12%',
  //     key: 3,
  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[i, 'level']}
  //           rules={[{ required: true, message: 'Select Level' }]}>
  //           <Select>
  //             {selectedOptions?.map((level) => (
  //               <Option key={level.value} value={level.value}>
  //                 {level.label}
  //               </Option>
  //             ))}
  //           </Select>
  //         </Form.Item>
  //       );
  //     },
  //   },

  //   {
  //     title: 'Role Name *',
  //     dataIndex: 'roleName',
  //     width: '12%',
  //     key: 4,
  //     render: (_, record, i) => {
  //       //
  //       return (
  //         <Form.Item
  //           name={[record.name, 'roleName']}
  //           //rules={[{ message: 'Enter Role Name' }]}
  //         >
  //           <Select>
  //             {permissions?.map((level, index) => {
  //               //
  //               return (
  //                 <Option key={index} value={level._id}>
  //                   {level.selectedRoleType}
  //                 </Option>
  //               );
  //             })}
  //           </Select>
  //           {/* <Select>
  //             {rolesDefault?.map((level, index) => (
  //               <Option key={index} value={level}>
  //                 {level}
  //               </Option>
  //             ))}
  //           </Select> */}
  //           {/* <Input/> */}
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'User ID *',
  //     dataIndex: 'userid',
  //     width: '13%',
  //     key: 5,
  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[record.name, 'userid']}
  //           rules={[
  //             { required: true, message: 'Enter User ID' },
  //             {
  //               pattern: /^[A-Za-z0-9]+$/,
  //               message: 'No Special Characters Allowed',
  //             },
  //           ]}
  //           getValueFromEvent={(e) => e.target.value.toUpperCase()}>
  //           <Input autoComplete='off' />
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Password *',
  //     dataIndex: 'password',
  //     width: '13%',

  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[record.name, 'password']}
  //           rules={[
  //             // { required: true, message: "Enter Password" },
  //             {
  //               pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
  //               message:
  //                 'Must contain minimum eight characters, atleast one number and one special character',
  //             },
  //           ]}>
  //           <Input.Password maxLength={20} autoComplete='new-password' />
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Email *',
  //     dataIndex: 'emailId',
  //     width: '13%',
  //     key: 6,

  //     render: (_, record, i) => {
  //       return (
  //         <Form.Item
  //           name={[record.name, 'emailId']}
  //           rules={[
  //             // { required: true, message: "Enter Email" },
  //             { type: 'email', message: 'Enter valid Email' },
  //             //  {
  //             //    validator: (_, value) =>
  //             //      orgEmailValidator(value, userConfig.orgId),
  //             //  },
  //           ]}>
  //           <Input />
  //         </Form.Item>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: 'actions',
  //     render: (_, record, i) => {
  //       return (
  //         <Popconfirm title='Delete' onConfirm={() => record.remove(i)}>
  //           <Form.Item>
  //             <Typography.Link>
  //               <DeleteFilled />
  //               Delete
  //             </Typography.Link>
  //           </Form.Item>
  //         </Popconfirm>
  //       );
  //     },
  //   },
  // ];

  const renderTitle = () => {
    return <Typography.Title level={5}>Approvers</Typography.Title>;
  };

  const renderFooter = (handleAdd) => {
    return (
      <Button
        type='dashed'
        htmlType='button'
        onClick={() => handleAdd()}
        block
        icon={<PlusOutlined />}
        disabled={disabled}

        // rules={[
        //   {
        //     validator: async (_, value) => {
        //       if (value.length === 0) {
        //         notification.error({ message: "Add atleast one Approver" });
        //         throw Error();
        //       }
        //     },
        //   },
        // ]}
      >
        Add Designations
      </Button>
    );
  };

  React.useEffect(() => {
    workerTypeInput();
    if (!Array.isArray(masterData) || masterData.length === 0) {
      getExcel();
      //setGetExcelCalled(true);
    }
  }, [masterData]);

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        //
        setMasterData(res.data.data.data);
      })
      .catch((error) => {});
  };

  function workerTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Worker_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const workerTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.WORKER_TYPE;
    });
    setWorkerType(workerTypeInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  //
  const getCheckedOrNot = (area) => {
    if (
      exceptions.hasOwnProperty(tempId) &&
      exceptions[tempId].hasOwnProperty(selectedApprovers)
    ) {
      return exceptions[tempId][selectedApprovers]?.includes(area) || false;
    }
    return false;
  };

  const getChecked = (area) => {
    if (checkedList.hasOwnProperty(tempId)) {
      return checkedList[tempId].includes(area) || false;
    }
    return false;
  };

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            {...field}
            label='Loc Name'
            name={[field.name, 'name']}
            // name="name"
            rules={[
              { required: true, message: 'Enter  Name' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              {
                pattern: /^[A-Za-z\s-]*$/,
                message: 'Only Alphabetic Allowed',
              },
            ]}>
            <Input id='locationName' disabled={disabled} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            {...field}
            label='Location ID'
            name={[field.name, 'id']}
            rules={[
              { required: true, message: 'Enter Location ID' },
              {
                min: 3,
                message: 'Minimum length must be 3 characters',
              },
              {
                max: 90,
                message: 'Maximum length allowed is 90 characters',
              },
              // {
              //   pattern: /^[a-zA-Z0-9]+$/,
              //   message: 'Enter Alpha Numeric Only',
              // },
            ]}>
            <Input addonBefore={`${orgInfoData.id}_ `} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Form.Item {...field} label='Address' name={[field.name, 'address']}>
            {/* <Input></Input> */}
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item {...field} label='City' name={[field.name, 'city']}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6} id='state'>
          <Form.Item
            {...field}
            label='State'
            name={[field.name, 'state']}
            // rules={[{ required: true, message: 'Enter State' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className='ant-select-selector'
              placeholder='Select State'
              disabled={disabled}>
              {states.map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item {...field} label='Zip Code' name={[field.name, 'zipCode']}>
            {/* <Input></Input> */}
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <Title level={5}>Approval Stages For Contract Workmen</Title>
      <Row gutter={16}>
        <Col className='gutter-row' span={6}>
          <Form.Item name={[field.name, ' IR-Contract']}>
            <Checkbox
              size='large'
              name='checkbox1'
              //onChange={(e) => onChangeApproval(0, e.target.checked)}
              onChange={(e) => {
                onChangeApproval(0, e.target.checked);
                //capturePanelId(field.name); // Call the capturePanelId function with the panelId
              }}
              style={{ paddingTop: '40px' }}
              // disabled={isDisabled}
              disabled={disabled}
              checked={getChecked(0)}>
              IR-Contract
            </Checkbox>
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item name={[field.name, 'Medical officer']}>
            <Checkbox
              size='large'
              name='checkbox2'
              onChange={(e) => onChangeApproval(1, e.target.checked)}
              style={{ paddingTop: '40px' }}
              // disabled={isDisabled}
              disabled={disabled}
              checked={getChecked(1)}>
              Medical Officer
            </Checkbox>
          </Form.Item>
        </Col>
        <Col className='gutter-row' span={6}>
          <Form.Item name={[field.name, 'Safety officer']}>
            <Checkbox
              size='large'
              name='checkbox3'
              onChange={(e) => onChangeApproval(2, e.target.checked)}
              style={{ paddingTop: '40px' }}
              //disabled={isDisabled}
              disabled={disabled}
              checked={getChecked(2)}>
              Safety Officer
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Button
        // type="submit"
        onClick={() => handleShowModal(field.name)}
        disabled={checkedList?.length < 2 || disabled}>
        Exception
      </Button>

      <Modal
        title='Exceptions'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width='60%'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '30%' }}>
            <h3>Approvers</h3>
            <Form.Item label='Approvers'>
              <Select onChange={handleApproverChange}>
                <Option value='Medical'>Medical Officer</Option>
                <Option value='Safety'>Safety Officer</Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{ width: '70%' }}>
            <h3>Worker Type</h3>
            <div>
              <p style={{ color: 'red' }}>
                Selected Designations will skip this approval stage
              </p>
            </div>
            <Form.Item label='Values'>
              {workerType?.map((area, index) => (
                <Checkbox
                  key={index}
                  value={area}
                  checked={getCheckedOrNot(area)}
                  ref={(el) => (checkboxesRef.current[area] = el)}
                  onChange={(e) => handleCheckboxChange(area, e.target.checked)}
                  disabled={!selectedApprovers}>
                  {area}
                </Checkbox>
              ))}
            </Form.Item>
          </div>
        </div>
      </Modal>
      {/* <Form.List name={[field.name, 'users']}>
        {(fields, actions) => {
          // 
          return (
            <Table
              // title={renderTitle}
              // columns={columns}
              //   dataSource={fields.map((field) => (

              //     {

              //     key: field.key,
              //      ...field,
              //     remove: actions.remove,
              //   }))
              // }
              dataSource={fields.map((field) => {
                
                
                
                
                // Log other properties as needed
                return {
                  key: field.key,
                  ...field,
                  remove: actions.remove,
                };
              })}
              scroll={{ x: 1300 }}
              // footer={() => renderFooter(actions.add)}
            />
          );
        }}
      </Form.List> */}
      <Divider />
      {/* <DocumentFormTable field={field} disabled={disabled} /> */}
      {/* Sachin Code */}
      <DocumentFormTable field={field} disabled={disabled} type={'centralIR'} />
      <DocumentFormTable field={field} disabled={disabled} type={'regularIR'} />
      <DocumentFormTable
        field={field}
        disabled={disabled}
        type={'contractAgency'}
      />
    </>
  );
}
